<template>
  <b-form-radio-group style="height:100%"
    :id="def.guid"  :chain="def.chain"
    class="radio-list"
    v-model="def.val"
   >
    <div class="h-100 w-100 d-flex">
      <b-form-radio :value="key" v-for="({key,lbl,lblHead}) in def.vals" :key="key">
        <strong v-if="lblHead">{{lblHead}}</strong>{{lbl}}
      </b-form-radio>
    </div>

  </b-form-radio-group>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  computed: {
    vals(){
      return this.def.vals;
    }
  },
  mixins: [fieldmix],
  name: 'fieldtype_radio',
  watch: {
    vals(vals){
      if (!vals.find(({key}) => this.def.val === key)){
        this.def.val = null;
      }
    }
  }
};
</script>

<style lang="scss">
  div.radio-list{
    div{
      white-space: break-spaces;
    }
  }
</style>
