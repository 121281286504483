import {clone} from '@/lib/util';
import {LocationDef, BuildingTypeDef, EntityDef} from '@/lib/fields/field-typedefs';

import {scope, eligibility} from '@/lib/fields/scope-data';
import {customer} from '@/lib/fields/customer-data';
import {policy} from '@/lib/fields/policy-data';
import {windstorm} from './windstorm-data';
import {locationFields, outdoorPropTypeList, miscOutdoorProp} from '@/lib/fields/location-data';
import {buildingData, buildingFormFields, buildingFields, pseudoBuildingFields} from '@/lib/fields/building-data';
import {propertyCoverages} from '@/lib/fields/property-coverages-data';
import {baseline} from '@/lib/fields/baseline-data';
import {underwriting} from '@/lib/fields/underwriting-data';
import {bindQuote} from '@/lib/fields/bind-quote-data';
import {quote} from '@/lib/fields/quote-data';

let allFields = {
  scope, eligibility, customer,
  policy, locationFields, outdoorPropTypeList, miscOutdoorProp,
  buildingData, buildingFormFields, buildingFields, pseudoBuildingFields,
  propertyCoverages, baseline, underwriting, bindQuote, quote,
  windstorm, sandbox: []
};

let initGroup = (list, componentName, selectionProxy) => {

  let parent = EntityDef.create({key: componentName, chain: componentName, selectionProxy});

  list.forEach((field, i) => {
    field.i = i;
    field.key = field.key ?? field.chain.split('.').pop();
    parent.addChild(field);
  });
  return parent;
};

let selectionProxy = page => page === 'buildingFields' ? 'buildingData' : undefined;
let quoteEntries = [
  'buildingFields', 'scope', 'eligibility', 'customer', 'policy',
  'baseline', 'underwriting', 'bindQuote', 'propertyCoverages',
  'quote', 'windstorm', 'sandbox'
].map(key => [key, initGroup(clone(allFields[key]), key, selectionProxy(key))]);
let locations = EntityDef.create({key: 'locations', chain: 'locations'});

let quoteFields = Object.fromEntries([['locations', locations], ...quoteEntries]);

const addBuilding = (lNum, bNum, name, fields) => {
  let ind = n => Math.max(0, Number(n) - 1);
  let def = buildingDefs(ind(lNum), ind(bNum), name);
  fields.forEach(f => {
    let found = def.children.find(d => d.key === f.key);
    if (found){
      found.val = f.val;
    }
  });
  return def;
};

const buildingDefs = (li, bi, name) => {
  let key = `loc-${li + 1}_bldg-${bi + 1}`;

  let bld = BuildingTypeDef.create({
    parent: locations.children.find(l => l.li === li),
    name: name || `Building Type ${bi + 1}`,
    bi, li, key,
    type: 'data', selected: false
  });
  clone(allFields.buildingFields).forEach(f => bld.addChild(f));
  return bld;
};


export default {
  allFields,
  initGroup,
  addLocation: (li, args) => {

    let location = LocationDef.add(li, args);
    return quoteFields.locations.addChild(location);
  },
  addBuilding: (lNum, bNum, name, fields) => {
    let bld = addBuilding(lNum, bNum, name, fields);

    let parent = quoteFields.locations.children.find(({dataTree: {locationId}}) =>
      locationId === bld.dataTree.locationId);
    if (parent) {
      parent.addBuilding(bld);
    }
    return bld;
  },
  buildingDefs,
  quoteFields
};
