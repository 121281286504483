import {isType} from '@/lib/mytype';

const xtypes = require('../json/xsd.json');
const CDXF = require('../json/cdxf.json');

class XsdEnum{
  constructor({name, codes = [], enums = [], vals = [], base = {}}) {
    this.name = name;
    this._codes = codes;
    this._enums = enums;
    this._values = vals;
    this._base = base;
    return this;
  }
  static create(args){
    if (args.CODE){
      args.codes = args.CODE;
      args.enums = args.ENUM;
      args.vals = args.VALUE;
    }
    return new XsdEnum(args);
  }
  static fromJsonFile(file){
    if (isType.string(file)){
      file = JSON.parse(file);
    }
    Object.keys(file.types).forEach(key => {
      file.types[key] = XsdEnum.create({name: key, ...file.types[key]});
    });
    return file.types;
  }
  get base(){return this._base;}
  get codes(){ return this._codes; }
  get findCode(){
    return v => {
      let index = this.values.findIndex(val => val === v);
      if (index > -1){
        return this.codes[index];
      }
      index   = this.enum.findIndex(e => e === v);
      if (index > -1){
        return this.codes[index];
      }
      index = Object.values(this.base)
        .flatMap(v => isType.object(v) ? Object.values(v) : v)
        .findIndex(bv => bv === v);
      if (index > -1){
        return this.base;
      }
      return null;
    };
  }
  get search(){
    return v => {
      let srch = a => a.findIndex(item => {
        if (!item){
          return false;
        }
        let str, s;
        try {
          str = item.toString().toLowerCase();
          s = v.toLowerCase();
          return str.includes(s);
        }catch(ex){
          return false;
        }

      });
      let lists = [
        [this.name],
        this.values,
        this.enum, this.codes,
        Object.values(this.base).flatMap(v => isType.object(v) ? Object.values(v) : v)
      ];
      return !!lists.find(items => srch(items) > -1);
    };
  }
  get values(){ return this._values;}
  get enum(){ return this._enums; }
  get keyValObject(){ return Object.fromEntries(this.values.map((v, i) => [this.enum[i], v])); }
  get json(){
    let {name, base, codes, values, _enums} = this;
    return {name, base, codes, enums: _enums, vals: values};
  }
  get toString(){
    return JSON.stringify(this.json);
  }
}

let types = {};
let misMatches = {};
let empty = {};
xtypes.forEach(t => {
  let inst = t;//XsdEnum.create(t);
  let enumCt = inst && inst.enums && inst.enums.length;
  if (enumCt === false || !inst || !inst.vals || !inst.codes || inst.vals.length !== enumCt || inst.codes.length !== enumCt) {
    misMatches[t.name] = inst;
  } else if (!enumCt) {
    empty[t.name] = inst;
  }else{
    types[t.name] = inst;
  }
});
export const typeJson = {
  CDXF,
  types,
  misMatches,
  empty
};

export const allTypes = xtypes.map(x => XsdEnum.create(x));

export const findType = q => Object.values(allTypes)
  .filter(t => t.search(q.toLowerCase()))
  .map(t => t.json);
