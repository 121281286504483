<template>
<ul v-if="list.length">
  <li v-for="([lbl,content]) in list" :key="lbl">
    <strong>{{titleCased(lbl)}}: </strong>
    <span v-if="isLeaf(content)">{{isType.nullOrUndef(content) ? '(empty)' : content}}</span>
    <json-list v-else :obj="branch(content, lbl)"/>
  </li>
</ul>
</template>

<script>
import {isType} from '@/lib/mytype';

export default {
  computed: {

    isLeaf(){
      return content => !content || this.isType.primitive(content);
    },
    branch(){
      return (obj, lbl) => {
        if (Array.isArray(obj) && obj.length && obj.every(o => isType.object(o))){
          obj = Object.fromEntries(
            obj.map((o, i) => ([`${lbl} ${i + 1}`, o]))
          );
        }
        return obj;
      };
    },
    list(){
      return this.obj ? Object.entries(this.obj) : [];
    }
  },
  name: 'json-list',
  props: ['obj']
};
</script>

<style scoped>
ul{
  padding-left:20px
}
</style>
