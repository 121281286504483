export const lioTheme = {
  navy: '#183340',
  blue: '#9ed2cc',
  info: '#9ed2cc',
  coral: '#d16a60',
  lightGray: '#f4f4f1',
  gray: '#c6c6c6',
  danger: '#dc3545',
  warning: '#ffc107',
  success: '#28a745'
};
