<template>
  <svg viewBox="0 0 97.68 95.42" style="fill:#D16A60" :style="{height: px(size), width: px(size)}">
    <g>
      <path class="st0" d="M71.31,47.54c13.13,0,23.77-10.64,23.77-23.77c0-2.11-0.37-4.13-0.88-6.08L79.51,32.38
        c-2.66,2.66-6.95,2.66-9.6,0l-7.2-7.2c-2.66-2.66-2.66-6.95,0-9.6L77.4,0.89C75.44,0.37,73.43,0,71.31,0
        C58.19,0,47.54,10.64,47.54,23.77c0,2.96,0.6,5.76,1.6,8.37L3.98,71.89c-5.3,5.3-5.3,13.9,0,19.21s13.9,5.3,19.21,0l39.75-45.16
        C65.56,46.94,68.35,47.54,71.31,47.54z M13.58,88.29c-3.75,0-6.79-3.04-6.79-6.79s3.04-6.79,6.79-6.79s6.79,3.04,6.79,6.79
        S17.33,88.29,13.58,88.29z" />
      <path class="st0" d="M10.31,25.56c1.19,1.79,3.71,2.03,5.23,0.52l1.66-1.66l14.25,14.26l7.67-6.74L24.41,17.2l1.67-1.67
        c1.51-1.51,1.26-4.04-0.52-5.23L10.95,0.57C9.6-0.33,7.8-0.15,6.66,0.99L0.99,6.66C-0.15,7.8-0.33,9.6,0.57,10.95L10.31,25.56z" />
      <path class="st0" d="M73.34,54.46c-0.68,0.05-1.33,0.2-2.02,0.2c-2.04,0-4.08-0.22-6.13-0.66L50.8,70.34l23.09,23.09
        c2.66,2.66,6.95,2.66,9.6,0l9.6-9.6c2.66-2.66,2.66-6.95,0-9.6L73.34,54.46z" />
    </g>
  </svg>
</template>

<script>
export default {
  props: ['size'],
  name: 'maintenanceicon'
};
</script>
