<template>
<div class="tri-check" :class="{nullval:val===null}" @click="wrapClicked(val)">
  <b-checkbox
    :disabled="disabled" :size="size||'md'" v-model="val"
    :style="{pointerEvents:eventsOnly?'none':'all'}"><slot></slot>
  </b-checkbox>
</div>
</template>

<script>
export default {
  data: () => {
    return {
      val: null
    };
  },
  name: 'tri-check',
  props: ['size', 'value', 'eventsOnly', 'disabled'],
  methods: {
    wrapClicked(val){
      if (this.eventsOnly){
        this.$emit('input', val === null || val === false);
      }
    },
    bind(){
      this.val = this.value;
    }
  },
  mounted(){
    this.bind();
  },
  watch: {
    value(val){
      this.bind();
    },
    val(val){
      if (!this.eventsOnly) {
        this.$emit('input', val);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

div.tri-check.nullval{
  div.custom-control.custom-checkbox{
    label.custom-control-label{
      &:before{
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}
</style>
