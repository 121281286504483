var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"miscOutdoor","size":"xl","header-class":{'show-close-x': false},"title":"Miscellaneous Outdoor Property","no-close-on-backdrop":true,"no-close-on-esc":true},on:{"hide":function($event){return _vm.cancel(true)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 modal-buttons"},[_c('b-button',{class:{disabled:_vm.isSave},attrs:{"disabled":_vm.isSave,"variant":"info","size":"lg"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),(!_vm.error.message)?_c('b-button',{class:{disabled:!_vm.validFormState || _vm.isSave},attrs:{"disabled":!_vm.validFormState || _vm.isSave,"variant":"primary","size":"lg"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.location)?_c('div',[(_vm.isSave)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-25"}),_c('div',{staticClass:"flex-fill"},[_c('progress-bar',{staticClass:"m-3"},[_vm._v("Saving")])],1),_c('div',{staticClass:"w-25"})]):(_vm.error.message)?_c('b-alert',{attrs:{"show":true,"variant":"danger"}},[_vm._v(_vm._s(_vm.error.message))]):_c('div',{staticClass:"pl-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Outdoor Property Types")]),_c('div',{staticClass:"d-flex"},_vm._l((_vm.chunkArray(_vm.location.outdoorPropList, 3)),function(chunk,ci){return _c('div',{key:("opt" + ci)},_vm._l((chunk),function(def){
var _obj;
return _c('div',{key:def.lbl,staticClass:"outdoor-type-row",class:( _obj = {}, _obj['pr-3'] = ci!==2, _obj['pl-3'] = ci, _obj )},[_c('field',{attrs:{"def":def}},[_c('span',[_vm._v(_vm._s(def.lbl))])]),(def.hasTag('descriptionRequired') && def.val)?_c('div',{staticStyle:{"margin":"-16px 0 0 24px"}},[_c('field',{attrs:{"def":_vm.location.child('describeOther')}})],1):_vm._e()],1)}),0)}),0),(_vm.showCoverages)?_c('h3',{staticClass:"m-0 mt-4"},[_vm._v("Coverage Options")]):_vm._e(),(_vm.showCoverages)?_c('div',{staticClass:"d-flex"},[_c('div',[_c('field-column',{attrs:{"defs":[
              _vm.location.child('valuationType'),
              _vm.location.child('causeOfLoss'),
              _vm.location.child('inflationGuard')
            ]}})],1),_c('div',{staticClass:" pl-4 "},[_c('field-column',{attrs:{"defs":[
              _vm.location.child('agreedValue'),
              _vm.location.child('perilsDeduct'),
              _vm.location.child('outdoorBlanketTotal')]}})],1)]):_vm._e()])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }