const { CLIENT_ID, REDIRECT_URL, ISSUER, SERVER_API_URL } = process.env;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URL,
    scopes: ['openid', 'profile', 'email'],
    pkce: true
  },
  API_URL: SERVER_API_URL,
  resourceServer: {
    messagesUrl: SERVER_API_URL + '/api/messages'
  }
};
