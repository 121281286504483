<template>
  <div class="policy-detail">
    <h1 class="text-center m-2 mb-4" v-if="!hideTitle">POLICY #{{itemVal('quote.policyNumber') }}</h1>
    <div class="d-flex">
      <div class="flex-fill"></div>
      <div class="quote-panel mr-3 pt-5" style="width:400px;height:400px">
        <label>Account Name:</label>
        <span>{{v('customer.accountName')}}</span>
        <label>Account #</label>
        <span>{{v('quote.customerId')}}</span>
        <label style="height:44px">Mailing Address</label>
        <span style="height:44px">

         {{ policyAddress }}</span>
        <label>Email</label>
        <span>{{v('customer.email')}}</span>
      </div>
      <div class="quote-panel view-policy ml-3" style="width:400px;height:400px">
        <h3 class="text-center mb-4 mt-4">Policy Documents</h3>

        <div v-if="policyDocumentsError" class="mt-5 p-3 text-center">The portal encountered an error while retrieving policy documents.</div>
        <progress-bar v-else-if="!policyDocuments.length" class="m-3">Loading...</progress-bar>
        <div v-for="({title,searchBy,downloadAs},i) in documentGroups" :key="`grp${i}`" v-else>
          <div v-if="documentsExist(searchBy)" class="mt-2"><strong>{{ title }}</strong></div>
          <div class="d-flex" v-for="(d,di) in documentByType(searchBy,downloadAs)" :key="`${i,di}`">
            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="file-pdf" class="svg-inline&#45;&#45;fa fa-file-pdf fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><g class="fa-group"><path class="fa-secondary" fill="currentColor" d="M86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zm93.8-218.9c-2.9 0-3 30.9 2 46.9 5.6-10 6.4-46.9-2-46.9zm80.2 142.1c37.1 15.8 42.8 9 42.8 9 4.1-2.7-2.5-11.9-42.8-9zm-79.9-48c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM272 128a16 16 0 0 1-16-16V0H24A23.94 23.94 0 0 0 0 23.88V488a23.94 23.94 0 0 0 23.88 24H360a23.94 23.94 0 0 0 24-23.88V128zm21.9 254.4c-16.9 0-42.3-7.7-64-19.5-24.9 4.1-53.2 14.7-79 23.2-25.4 43.8-43.2 61.8-61.1 61.8-5.5 0-15.9-3.1-21.5-10-19.1-23.5 27.4-54.1 54.5-68 .1 0 .1-.1.2-.1 12.1-21.2 29.2-58.2 40.8-85.8-8.5-32.9-13.1-58.7-8.1-77 5.4-19.7 43.1-22.6 47.8 6.8 5.4 17.6-1.7 45.7-6.2 64.2 9.4 24.8 22.7 41.6 42.7 53.8 19.3-2.5 59.7-6.4 73.6 7.2 11.5 11.4 9.5 43.4-19.7 43.4z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M377 105L279.1 7a24 24 0 0 0-17-7H256v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM240 331.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM86.1 428.1c5.8-15.7 28.2-33.9 34.9-40.2-21.7 34.8-34.9 41-34.9 40.2zm93.8-218.9c8.4 0 7.6 36.9 2 46.9-5-16-4.9-46.9-2-46.9zM151.8 366c11.1-19.4 20.7-42.5 28.4-62.7 9.6 17.4 21.8 31.2 34.5 40.8-23.9 4.7-44.6 14.9-62.9 21.9zm151.1-5.7s-5.7 6.8-42.8-9c40.3-2.9 46.9 6.3 42.8 9z"></path></g></svg>
            <a @click="downloadFile(d)" class="text-primary">{{d.displayName}}</a>
          </div>
        </div>
      </div>
      <div class="flex-fill"></div>
    </div>
    <div class="text-center mt-5">
      <router-link to="/">
        <b-button size="lg" variant="info">MAIN MENU</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {UploadedFileDef} from '@/lib/fields/field-typedefs';
import {quotemix} from '@/lib/quotemix';
import ProgressBar from '@/components/util/progress-bar';
import {mapMutations} from 'vuex';
import {portalEvents} from '@/lib/mix';
import {sortByKey} from '@/lib/util';
import {isType} from '@/lib/mytype';

export default {
  data: () => {
    return {
      documentGroups: [{
        title: 'Current Policy',
        searchBy: ['named insured.pdf', 'named insured new business.pdf', 'agent new business.pdf', 'named insured reinstatement.pdf'],
        downloadAs: 'Policy Document'
      }, {
        title: 'Endorsements',
        searchBy: ['endorsement named insured.pdf'],
        downloadAs: 'Endorsement'

      }, {
        title: 'Cancelation',
        searchBy: ['named insured cancellation.pdf'],
        downloadAs: 'Policy Cancelation'

      }],
      policyDocumentsError: null,
      policyDocuments: []
    };
  },
  components: {ProgressBar},
  computed: {
    policyAddress(){
      return this.itemFromChain('customer.address')?.address ||
          this.itemFromChain('locations.location-1')?.address || '...';
    },
    documentByType(){
      return (substrings, displayName) => {
        let docs = this.policyDocuments
            .filter(d => isType.string(d.fileName))
            .filter(d => substrings.find(s => d.fileName.toLowerCase() === s )).map(d => {
          d.created = Number(d.created);
          return d;
        });
        if(Array.isArray(docs)) {
          docs = sortByKey(docs, 'created');
          const hasDupes = docs.filter(d => d.fileName.toLowerCase().includes('new business')).length > 1;
          if(hasDupes) docs.splice(docs.indexOf(docs.find(d => d.fileName.toLowerCase().includes('agent'))), 1);
          return docs.map((d, i) => {
            d.displayName = i ? `${displayName} (${i}).pdf` : `${displayName}.pdf`;
            return d;
          });
        }else{
          console.warn({docs});
        }
        return [];
      };
    },
    documentsExist(){
      return (search) => this.documentByType(search)?.length > 0;
    },
    v(){
      return chain => this.itemVal(chain) || '...';
    }
  },
  methods: {
    ...mapMutations('getQuote', ['resetSteps']),
    downloadFile(fileDef){
      let params =  {
        quoteId: this.$route.params.policyId,
        documentId: fileDef.documentId
      };
      let opts = {toast: `Downloading ${fileDef.displayName}`};
      this.oneShield('getDocument', params, opts).then(({response: {fields}}) => {
        let data = fields[0].val[0];
        data.fileName = fileDef.displayName;
        let def = UploadedFileDef.create(data);
        def.download();
      });
    }
  },
  beforeDestroy() {
    this.resetSteps();
  },
  name: 'view-policy',
  props: ['hide-title', 'missingPolicy'],
  mixins: [quotemix],
  mounted() {
    this.clickStreamEvent(portalEvents.PolicyOpen, null, this.$route.params.policyId);
    this.oneShield('getDocumentList', {quoteId: this.$route.params.policyId}).then(result => {
      if (result.response.error){
        this.policyDocumentsError = true;
      }else {
        this.policyDocuments.splice(0, this.policyDocuments.length, ...result.response.fields[0].val);
        if (this.policyDocuments.length === 0){
          this.policyDocumentsError = true;
        }
        console.log(`[complete] documentList: ${this.policyDocuments.map(d => d.fileName)}`, this.policyDocuments);

      }
    });
  }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";
.policy-detail h1{
  position:relative;
  z-index: 0;
}
.view-policy {
  svg {
    height: 22px;
    width: 22px;

    .fa-primary {
      fill: #ccc;
    }

    .fa-secondary {
      fill: #e3e3e3;
    }
  }
}
.quote-panel.view-policy.ml-3 {
  overflow-y: scroll;
}
div.get-quote {
  div.head-bg{top:-150px !important;}
  .policy-detail {
    position: relative;
    z-index: 2;

    .quote-panel {
      background: white;

      label {
        font-weight: 900;
        width: 144px;
        padding-left: 20px;
        display: inline-block;
        margin-bottom: 15px;
        vertical-align: text-top;
      }

      span {
        word-break: break-word;
        vertical-align: text-top;
        display: inline-block;
        width: 150px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
