import { render, staticRenderFns } from "./simple-modal.vue?vue&type=template&id=7e89c448&"
import script from "./simple-modal.vue?vue&type=script&lang=js&"
export * from "./simple-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports