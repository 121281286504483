<template>
  <b-modal id="underwriterStatusOptions" v-if="underwriterModalData.quote" :title="`Quote ${underwriterModalData.quote.quoteNum}`"
    v-model="underwriterModalData.show" header-class="show-close-x" size="lg">
    <div class="d-flex" v-if="underwriterModalData.saving">
      <div class="w-25"></div>
      <div class="w-50"><progress-bar>SAVING</progress-bar></div>
      <div class="w-25"></div>
    </div>
    <div class="d-flex" style="justify-content: center" v-else-if="underwriterModalData.error">
      <b-alert variant="danger" show class="mt-2">
        The portal encountered an error while updating the Underwriting Status.
      </b-alert>
    </div>
    <div v-else>
      <b-dropdown block class="mb-2" menu-class="w-100" :text="getOptionUWStatus()">
        <b-dropdown-item v-for="option in uwStatusOptions" :key="option.id" @click="handleOnStatusSelected(option)">
          {{getOptionSelected(option)}}
        </b-dropdown-item>
      </b-dropdown>
      <b-form-group label="Details" v-if="getIdsFromLioAndBrokerOther.includes(underwriterModalData.statusSelected.id)">
        <b-textarea size="md" rows="3" v-model="underwriterModalData.statusSelected.description" />
      </b-form-group>
    </div>
    <template #modal-footer>
      <footer style="display: flex; justify-content: space-evenly">
        <b-button size="md" variant="info" :disabled=underwriterModalData.saving @click="handleOnCancel">
          CANCEL
        </b-button>
        <b-button size="md" variant="primary" @click="handleOnSave" :disabled="disableButton()">
          SAVE
        </b-button>
      </footer>
    </template>
  </b-modal>
</template>

<script>
import ProgressBar from '@/components/util/progress-bar';
import { mapMutations } from 'vuex';

export default {
  components: { ProgressBar },
  name: 'underwriting-status-options',
  props: ['id', 'rowData', 'uwStatusOptions', 'clearedOption'],
  data: () => {
    return {
      brokerOtherStatusId: 'ckz7fdquo0006pofgco7sftm5',
      lioOtherStatusId: 'ckz7ffbyv000088fg2wcd2nku',
      underwriterModalData: {
        show: false,
        saving: false,
        error: false,
        quote: null,
        statusSelected: {
          id: null,
          type: null,
          subType: null,
          description: null
        }
      }
    };
  },
  watch: {
    rowData: {
      handler(data) {
        this.setInitialState(data);
      }
    }
  },
  methods: {
    ...mapMutations('getQuote', ['addQuotes']),
    async handleOnSave() {
      if(this.underwriterModalData.error) {
        this.handleOnClose();
      } else {
        const {quote, statusSelected} = this.underwriterModalData;
        if (statusSelected.id === this.clearedOption.id) {
          if (this.$parent.uwStatusExists(quote.quoteId)) {
            return await this.triggerStatusDelete(quote, statusSelected);
          }
          this.updateItemList(quote, statusSelected);
          this.handleOnClose();
        } else {
          await this.triggerStatusUpsert(quote, statusSelected);
        }
      }
    },
    updateItemList(item, statusSelected) {
      if(this.getIdsFromLioAndBrokerOther.includes(statusSelected.id)) {
        item.uwStatusDescription = statusSelected.description;
      }
      else {
        item.uwStatusDescription = null;
      }
      if (statusSelected.id === this.clearedOption.id) {
        item.uwStatusId = undefined;
        item.uwStatusId = undefined;
        item.uwStatusType = undefined;
        item.uwStatusSubType = undefined;
        item.underwritingStatus = undefined;
      } else {
        item.uwStatusId = statusSelected.id;
        item.uwStatusType = statusSelected.type;
        item.uwStatusSubType = statusSelected.subType;
        item.underwritingStatus = this.$parent.getUWStatus([item?.uwStatusType, item?.uwStatusSubType, item?.uwStatusDescription]);
      }
    },
    async triggerStatusDelete(item, statusSelected) {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        saving: true
      };
      const deleteData = {
        quoteId: item.quoteId
      };
      try {
        this.lioApi('deleteUnderwritingStatus', null, deleteData)
          .then(result => {
            if(result?.response?.error) {
              this.handleOnError();
            } else {
              this.addQuotes({list: [item]});
              this.updateItemList(item, statusSelected);
              this.$emit('deleteItemUWStatusList', item.quoteId);
            }
          })
          .finally(() => {
            if(!this.underwriterModalData.error) {
              this.handleOnClose();
            }
          });
      } catch (e) {
        console.error(e);
      }
    },
    async triggerStatusUpsert(item, statusSelected) {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        saving: true
      };
      const postData = {
        quoteId: item.quoteId,
        quoteNumber: item.quoteNum,
        uwStatusId: statusSelected.id,
        uwStatusDescription: statusSelected.description,
        uwEmail: item.underwriter,
        producerName: item.producer,
        producerEmail: item.producerEmail,
        agencyId: item.agency
      };
      try {
        const exists = this.$parent.uwStatusExists(item.quoteId);
        const method = exists ? 'updateUnderwritingStatus' : 'insertUnderwritingStatus';
        this.lioApi(method, postData.quoteId, postData)
          .then(result => {
            if(result?.response?.error) {
              this.handleOnError();
            } else {
              this.addQuotes({list: [item]});
              this.updateItemList(item, statusSelected);
              if (!exists) {
                this.$emit('addItemUWStatusList', postData);
              }
            }
          })
          .finally(() => {
            if(!this.underwriterModalData.error) {
              this.handleOnClose();
            }
          });
      } catch(e) {
        console.error(e);
      }
    },
    handleOnClose() {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        show: false,
        saving: false
      };
    },
    handleOnError() {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        saving: false,
        error: true
      };
    },
    getOptionUWStatus() {
      const { statusSelected } = this.underwriterModalData;
      if(statusSelected.id) {
        return this.$parent.getUWStatus([statusSelected.type, statusSelected.subType], ' - ');
      } else {
        return 'Select';
      }
    },
    getOptionSelected(option) {
      return this.$parent.getUWStatus([option.type, option.subType], ' - ');
    },
    setInitialState(data) {
      this.underwriterModalData = {
        quote: data.item,
        show: true,
        saving: false,
        error: false,
        statusSelected: {
          id: data.item.uwStatusId,
          type: data.item.uwStatusType,
          subType: data.item.uwStatusSubType,
          description: data.item.uwStatusDescription
        }
      };
    },
    handleOnStatusSelected(option) {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        statusSelected: {
          ...this.underwriterModalData.statusSelected,
          id: option.id,
          type: option.type,
          subType: option.subType
        }
      };
    },
    handleOnCancel() {
      this.underwriterModalData = {
        ...this.underwriterModalData,
        show: false
      };
    },
    disableButton() {
      const { quote, statusSelected, saving } = this.underwriterModalData;
      return !statusSelected.id ||
          saving ||
          (statusSelected.id === quote.uwStatusId &&
              statusSelected.description === quote.uwStatusDescription
          ) ||
          (this.getIdsFromLioAndBrokerOther.includes(statusSelected.id) &&
              !statusSelected.description);
    }
  },
  computed: {
    getIdsFromLioAndBrokerOther() {
      return [this.lioOtherStatusId, this.brokerOtherStatusId];
    }
  }
};
</script>

<style scoped />
