const fraud_states = {
    1: {
        'key': 1,
        'value': 'Alabama',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution, fines, or confinement in prison, or any combination thereof. A lack of the fraud statement does not constitute a defense in any prosecution for insurance fraud'
    },
    4: {
        'key': 4,
        'value': 'Arkansas',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
    },
    5: {
        'key': 5,
        'value': 'California',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'For your protection California law requires the following appear on this form: Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.'
    },
    6: {
        'key': 6,
        'value': 'Colorado',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable for insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.'
    },
    8: {
        'key': 8,
        'value': 'Delaware',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.'
    },
    9: {
        'key': 9,
        'value': 'Florida',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony of the third degree.'
    },
    17: {
        'key': 17,
        'value': 'Kentucky',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.'
    },
    18: {
        'key': 18,
        'value': 'Louisiana',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
    },
    19: {
        'key': 19,
        'value': 'Maine',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or denial of insurance benefits.'
    },
    20: {
        'key': 20,
        'value': 'Maryland',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
    },
    30: {
        'key': 30,
        'value': 'New Jersey',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.'
    },
    31: {
        'key': 31,
        'value': 'New Mexico',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.'
    },
    32: {
        'key': 32,
        'value': 'New York',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation. <strong>Auto:</strong> Any person who knowingly makes or knowingly assists, abets, solicits or conspires with another to make a false report of the theft, destruction, damage or conversion of any motor vehicle to a law enforcement agency, the department of motor vehicles or an insurance company, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the value of the subject motor vehicle or stated claim for each violation. <strong>Fire:</strong> Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime. The proposed insured affirms that the foregoing information is true and agrees that these applications shall constitute a part of any policy issued whether attached or not and that any willful concealment or misrepresentation of a material fact or circumstances shall be grounds to rescind the insurance policy.'
    },
    35: {
        'key': 35,
        'value': 'Ohio',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.'
    },
    36: {
        'key': 36,
        'value': 'Oklahoma',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.'
    },
    37: {
        'key': 37,
        'value': 'Oregon',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': '<strong>Fire:</strong> This entire policy shall be void if, whether before or after a loss, the insured has willfully concealed or misrepresented any material fact or circumstance concerning this insurance or the subject thereof, or the interest of the insured therein, or in case of any fraud or false swearing by the insured relating thereto.'
    },
    38: {
        'key': 38,
        'value': 'Pennsylvania',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties. <strong>Auto:</strong>  Any person who knowingly and with intent to injure or defraud any insurer files an application or claim containing any false, incomplete or misleading information shall, upon conviction, be subject to imprisonment for up to seven years and the payment of a fine of up to $15,000.'
    },
    40: {
        'key': 40,
        'value': 'Rhode Island',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
    },
    43: {
        'key': 43,
        'value': 'Tennessee',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.'
    },
    47: {
        'key': 47,
        'value': 'Virginia',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.'
    },
    48: {
        'key': 48,
        'value': 'Washington',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.'
    },
    50: {
        'key': 50,
        'value': 'West Virginia',
        'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
        'message': 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.'
    }
};

const default_fraud_state = {
    'key': 'default',
    'value': 'Default State',
    'title': 'I DECLARE THAT THE STATEMENTS MADE IN THIS APPLICATION ARE COMPLETE AND TRUE',
    'message': 'Any person who, with the intent to defraud or knowing that he or she is facilitating a fraud against an insurer, submits an application, or files a claim containing a false or deceptive statement may be guilty of insurance fraud and subject to fines and/or imprisonment.'
};

const getFraudStateBy = key => {
    if (fraud_states[key])
        return fraud_states[key];

    return default_fraud_state;
};

export default getFraudStateBy;