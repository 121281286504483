<template>
<div>
  <div class="d-flex">
    <div class="flex-fill"></div>
    <div class="quote-panel mr-2 ml-2" style="width:100%;max-width:1080px">

      <div class="d-flex">
        <div class="flex-fill"></div>
        <div class="mr-4"> Do you have any of the below exposures?</div>
        <b-form-radio-group
            ref="eligRadio"
            :disabled="questions.length < 2"
            v-model="exposure"
            :options="options"/>
        <div class="flex-fill"></div>
      </div>
      <div class="d-flex" v-if="questions.length">
        <ul v-for="(chunk,ci) in chunkArray(questions, Math.ceil(questions.length / 3))" :key="`qchnk${ci}`" class="flex-fill">
          <li v-for="(q,i) in chunk" :key="`q${i}`">{{q}}</li>
        </ul>

      </div>
      <div v-else-if="finishProg" class="text-center m-5">No questions returned</div>
      <div class="d-flex mt-5 mb-5" v-else>
        <div class="flex-fill"></div>
        <progress-bar class="w-50 pb-5 mb-4" variant="primary" :delay="222" :finished="finishProg">
          <strong>Retrieving Eligibility Questions...</strong>
        </progress-bar>
        <div class="flex-fill"></div>
      </div>

    </div>
    <div class="flex-fill"></div>
  </div>
  <div class="text-center mt-5" style="position: relative">
    <b-button size="lg" class="quote-nav-button btn-secondary mr-4" @click="$parent.inMemComponent = 'scope'" variant="info">Back</b-button>
    <b-button size="lg" class="quote-nav-button" :disabled="!canAdvance" variant="primary" @click="$parent.inMemComponent = 'locations'">Continue</b-button>
    <div class="exit-link">
      <a @click="$parent.exit">Exit to the main menu</a>
    </div>
  </div>
</div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {simpleModal} from '@/lib/modal-service';
import ProgressBar from '@/components/util/progress-bar';
import fields from '@/lib/fields/fields';
import eventbus from '@/lib/eventbus';
import {portalEvents} from '@/lib/mix';
import {eligibilityQuestionsByProductCode} from '@/lib/fields/field-constants';

export default {
  components: {ProgressBar},
  data: () => {
    return {
      exposure: undefined,
      options: [
        {text: 'Yes', value: true},
        {text: 'No', value: false}
      ],
      fieldsPage: 'eligibility',
      finishProg: false
    };
  },
  computed: {
    canAdvance(){
      return this.exposure === false;
    },
    questions(){
      return eligibilityQuestionsByProductCode[this.itemVal('scope.product')];
    }
  },
  mixins: [quotemix],
  name: 'quote-eligibility',
  created(){
    eventbus.$on('enterKey', () => {
      if (this.isMounted) {
        this.$parent.inMemComponent = 'locations';
      }

    });
  },
  mounted() {
    this.initStep({page: 'eligibility', fields: fields.eligibility});
    this.exposure = undefined;
    let {jurisdiction, product} = this.quoteData.scope;
    let focusEl = this.$refs.eligRadio.$children[0].$el.children[0];
    this.oneShield('eligibilityQuestionList', {jurisdiction, product})
      .then((result) => {

        this.finishProg = true;
        let {fields} = result.response;
        if (Array.isArray(fields)) {
          fields.filter(f => f.type === 'list').forEach(list => {
            let {chain, val} = list;
            this.updateField({chain, val});
            this.updateField({chain, val: true, prop: 'valid'});
            focusEl.autofocus = true;
            this.delayFn(() => focusEl.focus(), 1);
          });
        }
      });

    focusEl.tabIndex = 0;
    focusEl.autofocus = true;
    focusEl.focus();
  },
  beforeDestroy() {
    this.setAny({eligible: {exposed: this.exposure}});
  },
  watch: {
    exposure(ex) {
      let f = this.itemFromChain('eligibility.hasExposures');

      let content = {
        hasExposures: ex,
        product: this.itemVal('scope.product'),
        jurisdiction: this.quoteData.scope,
        exposures: this.questions
      };
      if (ex === undefined) {
        return;
      }
      if (ex) {
        let title = '';
        let msg = 'Are you sure you have these exposures?';
        let btnLabels = {ok: 'YES', cancel: 'NO, GO BACK'};
        simpleModal(title, msg, btnLabels).then(ok => {
          if (!ok){
            this.exposure = undefined;
          }
          else{
            this.clickStreamEvent(portalEvents.QuoteEligibility, undefined, undefined, content);
            msg = 'Unfortunately these exposures fall outside our guidelines.';
            let btnLabels = {ok: 'RETURN TO MAIN MENU', cancel: false};
            simpleModal(title, msg, btnLabels).then(() => {
              this.$router.push({path: '/'});
            });
          }
        });
      } else {
        this.clickStreamEvent(portalEvents.QuoteEligibility, undefined, undefined, content);
        let {jurisdiction} = this.quoteData.scope;
        let questions = [{key: '16056', lbl: 'Do any of the following apply?'}];
        this.oneShield('evaluateCustomerEligibility', {jurisdiction, questions}).then(({response}) => {
          let subId = response.fields.find(f => f.key === 'submissionId')?.val;
          let chain = `quote.submission`;
          this.updateField({chain, val: subId});
        });

        this.updateField({chain: f.chain, val: false});
        this.updateField({chain: f.chain, prop: 'valid', val: true});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/get-quote";

.quote-panel {

  margin:0 auto;
  ul {
    margin: 24px 36px 24px 48px;

    li {
      margin-bottom: 24px;
    }
  }
}
</style>
