import { maxOSInt } from '../util';

const typeDefs = require('../../json/types-list.json');

const ignoredDeductibleAmounts = [500, 3000, 15000, 20000, 100000];
const excludedDeductiblesForBroker = [1000, 2500, 75000];
const excludedDeductiblesForHoaOpo = [1000, 75000];

export const perilsDeductibles = typeDefs.perilsDeduct.filter(deductible => !ignoredDeductibleAmounts.includes(deductible.id));
export const brokerDeductibles = perilsDeductibles.filter(val => !excludedDeductiblesForBroker.includes(val.id));
export const hoaOpoDeductibles = perilsDeductibles.filter(val => !excludedDeductiblesForHoaOpo.includes(val.id));

export const stateIdByCode = Object.fromEntries(typeDefs.stateList.map(s => [s.code, s.id.toString()]));

export const roofTypes = {
  1: 'Metal Sheathing',
  2: 'Concrete or Clay Tiles',
  3: 'Wood Shakes',
  4: 'Normal Shingle (55 mph)',
  5: 'High Speed Rated Shingle (110 mph)'
};

export const windHailDeductible = [
  { id: '1', value: 'None' },
  { id: '2', value: '1%' },
  { id: '3', value: '2%' },
  { id: '4', value: '5%' }
];

export const windHailDeductibleIdsByValue = Object.fromEntries(windHailDeductible.map(w => ([w.value, w.id])));

export const outdoorPropType = {
  1001: 'Boat Storage and Moorage',
  1002: 'Bridges, roadways, walkways, patios, paved surfaces',
  1003: 'Bulkheads, docks, piers, retaining walls, wharves',
  1004: 'Cabana',
  1005: 'Gate House',
  1006: 'Irrigation System',
  1007: 'Lights/Poles',
  1008: 'Mailboxes',
  1009: 'Pool House',
  1010: 'Signs/Monuments',
  1011: 'Tot lot/Playground Equipment',
  1012: 'Other (excluding trees and shrubs)'
};

export const bldgClassTypes = {
  10656: {lbl: 'Boat Storage & Moorage', icon: 'fas fa-anchor', sortKey: 10, code: '0550'},
  10856: {lbl: 'Clubhouse with cooking', icon: 'fas fa-cocktail', sortKey: 20, code: '0755'},
  10956: {lbl: 'Clubhouse without cooking', icon: 'fas fa-cocktail', sortKey: 21, code: '0756'},
  10156: {lbl: 'Condominiums – with mercantile occupancies – up to 10 units', icon: 'fas fa-building', sortKey: 30, code: '0341'},
  10256: {lbl: 'Condominiums – with mercantile occupancies – 11 to 30 units', icon: 'fas fa-building', sortKey: 31, code: '0342'},
  10056: {lbl: 'Condominiums – with mercantile occupancies – over 30 units', icon: 'fas fa-building', sortKey: 32, code: '0343'},
  10456: {lbl: 'Condominiums – without mercantile occupancies – up to 10 units', icon: 'fas fa-building', sortKey: 33, code: '0331'},
  10556: {lbl: 'Condominiums – without mercantile occupancies – 11 to 30 units', icon: 'fas fa-building', sortKey: 34, code: '0332'},
  10356: {lbl: 'Condominiums – without mercantile occupancies – over 30 units', icon: 'fas fa-building', sortKey: 35, code: '0333'},
  10756: {lbl: 'Office', icon: 'fas fa-city', sortKey: 40, code: '0702'},
  11056: {lbl: 'Parking', icon: 'fas fa-parking', sortKey: 50, code: '0931'},
  11156: {lbl: 'Warehouse/Storage', icon: 'fas fa-warehouse', sortKey: 60, code: '1220'}
};

const OSProductCodes = {
  COA: 2648,
  HOA: 2948
};

export const defaultLiabilitiesByProgramType = {
  [OSProductCodes.COA]: [62003],
  [OSProductCodes.HOA]: [68500],
  [`AZ${OSProductCodes.COA}`]: [62003],
  [`AZ${OSProductCodes.HOA}`]: [41670]
};

export const eligibilityQuestionsByProductCode = {
  [OSProductCodes.COA]: [
    'Condotels',
    'Commercial Condominiums',
    'More than 50% of Units are Rented To Others',
    'Wooden decks that permit grilling',
    'Aluminium wiring/Zinsco/Stab-Lok/Federal Pacific',
    'Polybutylene piping',
    'Wood shake roofing/siding (CA only)',
    'Stab-Lok breakers',
    'Bridges over $100,000 in property value',
    'Glass buildings',
    'Historical buildings',
    'Buildings built on piers',
    'High-Rises Greater than 12 Stories',
    'Ice skating/fishing',
    'Jet/snow/water Skiing',
    'Skate Park',
    'Hunting and shooting',
    'Lake/Pond/River (over 100 acres)',
    'On Premises Golf Course not Insured Elsewhere',
    'Pools with Diving Boards/Slides/Splashpads',
    'Equestrian Exposures',
    'Association is not in compliance with all federal, state, and local life safety requirements',
    'Affordable housing',
    'Railings - horizontal and/or greater than 6" wide'
  ],
  [OSProductCodes.HOA]: [
    'Aluminium wiring/Zincs/Stab-Lok/Federal Pacific',
    'Polybutylene piping',
    'Wood shake roofing/siding (CA only)',
    'Stab-Lok breakers',
    'Bridges over $100,000 in property value',
    'Glass buildings',
    'Historical buildings',
    'Buildings built on piers',
    'Ice skating/fishing',
    'Jet/snow/water skiing',
    'Skate Park',
    'Hunting and shooting',
    'Lake/Pond/River (over 100 acres)',
    'On Premises Golf Course not Insured Elsewhere',
    'Pools with Diving Boards/Slides/Splashpads',
    'Equestrian Exposures',
    'Affordable housing',
    'Railings - horizontal and/or greater than 6" wide'
  ]
};

export const incumbentCarriers = ['AIG', 'American Family Insurance', 'Arden', 'Auto-Owners Insurance', 'CAU',
  'Cincinnati Insurance', 'CNA', 'Deans & Homer', 'Dongbu Insurance', 'Erie Insurance', 'Farmers Insurance', 'GNY',
  'Grange Insurance', 'Great American', 'Hanover', 'Hippo', 'HoneyComb', 'Ian H. Graham Insurance', 'Main Street America',
  'McGowan Program Administrators', 'Med James Specialty', 'Mercury Insurance', 'MiddleOak', 'Millers Mutual', 'Nationwide',
  'Philadelphia Insurance', 'State Auto', 'State Farm', 'The Hartford', 'Tower Hill', 'Travelers', 'UCA General',
  'Other', 'No Prior Coverage', 'Unknown'
];


export const liabilityVals = [
  { key: '10105', lbl: 'Boat Storage & Moorage' },
  { key: '40072', lbl: 'Beaches' },
  { key: '41668', lbl: 'Clubhouse' },
  { key: '45524', lbl: 'Lakes or Reservoirs'},
  { key: '46622', lbl: 'Parking' },
  { key: '46671', lbl: 'Parks or Playgrounds' },
  { key: '48727', lbl: 'Streets, Roads, Highways or Bridges' },
  { key: '48925', lbl: 'Swimming pools' },
  { key: '49451', lbl: 'Vacant Land – Other than Not-For-Profit' },
  { key: '49452', lbl: 'Vacant Land – Not-For-Profit only'  },
  { key: '61218', lbl: 'Building/Premises – LRO'  },
  { key: '61225', lbl: 'Building/Premises – Office'  },
  { key: '62000', lbl: 'Condominiums – commercial – bank or mercantile, manufacturing or office'  },
  { key: '62001', lbl: 'Condominiums – commercial shopping centers' },
  { key: '62002', lbl: 'Condominiums – commercial warehouses – manufacturing or private' },
  { key: '62003', lbl: 'Condominiums – Residential' },
  {
    key: '68500', lbl: 'Townhouse or Homeowner Associations',
    brokerLock: true,
    test: {
      $and: [
        { admin: false},
        {
          $or: [
            {chain: 'sibling::liabilityClasses', has: '68500'},
            { chain: 'sibling::liabilityClasses', excludes: '41670'}
          ]
        }
      ]
    }
  },
  {
    key: '41670', lbl: 'Townhouse or Homeowner Associations',
    brokerLock: true,
    test: {
      $and: [
        { admin: false},
        { chain: 'sibling::liabilityClasses', has: '41670'}
      ]
    }
  },
  { key: '68500', lbl: 'Townhouse or Homeowner Associations (68500)', test: {admin: true}},
  { key: '41670', lbl: 'Townhouse or Homeowner Associations (41670)', test: {admin: true}},
  { key: '68707', lbl: 'Warehouses' }
];


export const liabilityClasses = Object.fromEntries(
  liabilityVals.filter(item => !item.test || (!item.test.admin))//take first two townhome items to build list
    .map(({key, lbl}) => [key, lbl])
);// e.g., {68707: 'Warehouses', 68500:'Townhouse...'}

export const liabilityDetails = {
  10105: [
    { key: 'sales', lbl: 'Gross Sales', type: 'currency'}
  ],

  40072: [
    {key: 'beachCount', lbl: 'Number of Beaches', type: 'int', range: [0, maxOSInt]}
    // {key: 'lifeguards', lbl: 'Are Lifeguards Present', type: 'yn'},
    // {key: 'marked', lbl: 'Is swimming area marked?', type: 'yn'},
    // {key: 'rules', lbl: 'Are rules posted in swimming area', type: 'yn'}
  ],
  41668: [{key: 'clubSqft', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  45524: [{key: 'waterCt', lbl: 'Number of Lakes, Ponds, Rivers', type: 'int', range: [0, maxOSInt]}],
  46622: [{key: 'parkSqft', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  46671: [
    {key: 'parkCt', lbl: 'Number of Parks (Including Dog Parks)', type: 'int', range: [0, maxOSInt]}
    // {key: 'playCt', lbl: 'Number of Playgrounds', type: 'int', range: [0, maxOSInt]},
    // {key: 'surfaceType', lbl: 'Type of surface below playground equipment',
    //   type: 'multi',
    //   vals: [ // TODO: typeDef PlaygroundSurface
    //     {key: 1, lbl: 'Sandpit'},
    //     {key: 2, lbl: 'Wood Chips'},
    //     {key: 3, lbl: 'Other'}]
    // },
    // {
    //   key: 'surfaceTypeDesc', lbl: 'Describe Other', type: 'text',
    //   when: {chain: 'exposures.surfaceType', eq: 3}
    // },
    // {key: 'inpections', lbl: 'Are regular inspections made on the equipment?', type: 'yn'}
  ],
  48727: [{key: 'miles', lbl: 'Miles', type: 'int', range: [0, maxOSInt]}],
  48925: [
    {key: 'poolCt', lbl: 'Number of Swimming Pools', type: 'int', range: [0, maxOSInt]},
    // {key: 'poolType', lbl: 'Pool Type(s)', type: 'multi', vals: [
    //     {key: 1, lbl: 'Indoor'}, {key: 2, lbl: 'Outdoor'} // TODO: typeDef PoolType
    //   ]},
    // TODO: This _should not_ be here, but is currently per OneShield
    {key: 'poolsCompliant', lbl: 'Does pool area comply with all federal, state and local laws?', type: 'yn'}
    // {key: 'poolDescAddl', lbl: 'Describe additional recreational equipment', type: 'desc'}
  ],
  49451: [{key: 'vacantLandOtherThanNfp', lbl: 'Acres', type: 'int', range: [0, maxOSInt]}],
  49452: [{key: 'vacantLandNfpOnly', lbl: 'Acres', type: 'int', range: [0, maxOSInt]}],
  61225: [{key: 'bldgPremisesSqft', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  61218: [
    {key: 'bldgPremisesSqftLessors', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]},
    {key: 'bldgPremisesOtherExposure', lbl: 'Exposure Other Than Retail', type: 'yn'},
    {
      key: 'bldgPremisesRetailExposure', lbl: 'Describe Non-Retail Exposure', type: 'text',
      extraCondition: {chain: 'sibling::bldgPremisesOtherExposure', eq: true}
    }
  ],
  62000: [{key: 'condoCommercialBankOrMercantileSqFoot', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  62001: [{key: 'condoCommercialShoppingSqFoot', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  62002: [{key: 'condoCommercialWarehousesSqFoot', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}],
  62003: [
    {key: 'unitCt', lbl: 'Number of Units', type: 'int', range: [0, maxOSInt]}
  ],
  68500: [
    {key: 'unitCtHOA', lbl: 'Number of Units', type: 'int', range: [0, maxOSInt] }
  ],
  68707: [{key: 'wareHouseSqft', lbl: 'Square Footage', type: 'int', range: [0, maxOSInt]}]
};

export const underwritingLiabilities = [
  //40072
  {key: 'lifeguards', lbl: 'Are Lifeguards Present', type: 'yn', lc: 40072, title: liabilityClasses[40072] },
  {key: 'marked', lbl: 'Is swimming area marked?', type: 'yn', lc: 40072},
  {key: 'rules', lbl: 'Are rules posted in swimming area', type: 'yn', lc: 40072},
  //10105
  { key: 'hasBoatSlips', type: 'yn', lbl: 'Do you have Boat Docks & Slips?', lc: 10105, title: 'Boat Docks & Slips' },
  { key: 'boatSlipsNumber', type: 'int', lbl: 'Number of Boat Docks & Slips', lc: 10105, active: { $conditional: true, chain: 'underwriting.hasBoatSlips', eq: true} },
  { key: 'boatSlipsCovered', type: 'yn', lbl: 'Are Docks Covered', lc: 10105, active: { $conditional: true, chain: 'underwriting.hasBoatSlips', eq: true} },
  { key: 'boatSlipsInspected', type: 'yn', lbl: 'Are docks inspected annually?', lc: 10105, active: { $conditional: true, chain: 'underwriting.hasBoatSlips', eq: true} },
  { key: 'boatSlipsNonSlipSurface', type: 'yn', lbl: 'Are docks covered with a nonslip surface?', lc: 10105, active: { $conditional: true, chain: 'underwriting.hasBoatSlips', eq: true} },
  { key: 'boatSlipsRulesPosted', type: 'yn', lbl: 'Are rules posted?', lc: 10105, active: { $conditional: true, chain: 'underwriting.hasBoatSlips', eq: true} },

  //46671
  {key: 'surfaceType', lbl: 'Type of surface below playground equipment',
    type: 'select', lc: 46671, title: liabilityClasses[46671],
    vals: [ // TODO: typeDef PlaygroundSurface
      {key: '1', lbl: 'Sandpit'},
      {key: '2', lbl: 'Wood Chips'},
      {key: '3', lbl: 'Other'}]
  },
  {
    key: 'surfaceTypeDesc', lbl: 'Describe Other Surface Type', type: 'text', lc: 46671, active: { $conditional: true, chain: 'underwriting.surfaceType', 'eq': '3'}
  },
  { key: 'playgroundAgeOfEquipment', lbl: 'Age of equipment (# of years)',  type: 'int', lc: 46671},
  {key: 'inspections', lbl: 'Are regular inspections made on the equipment?', type: 'yn', lc: 46671},
  //48925
  {key: 'poolType', lbl: 'Pool Type(s)', type: 'select', vals: [
    {key: 1, lbl: 'Indoor'}, {key: 2, lbl: 'Outdoor'}, {key: 3, lbl: 'Indoor and Outdoor'}
  ], lc: 48925, title: liabilityClasses[48925] },
  {key: 'poolDescAddl', lbl: 'Describe additional recreational equipment', type: 'text', lc: 48925}
  /*,
  //todo: attachment type underwriting things.
  //62003
  {key: 'info', lbl: 'Additional Insured-Condominium Unit Owners', type: 'att', optional: true, lc: 62003, title: liabilityClasses[62003] },
  //68500
  {key: 'infoHOA', lbl: 'Additional Insured - Unit-Owners of Townhome or Homeowner Association', type: 'att', optional: true, lc: 68500, title: liabilityClasses[68500] }*/
];

export const stopGapVals = [{ key: 500000, lbl: '$500,000'}, { key: 1000000, lbl: '$1,000,000'}];

export const statusSearch = [
  'Bound', 'Referred To Underwriting', 'Lapsed', 'Rejected',
  '%ancel%', 'Confirmed', 'Declined', 'Processed',
  'Rated', '%Bind', '%omplet%', 'Marked%'
];

export let stateMap = {
  'Alabama': 'AL', 'Alaska': 'AK', 'American Samoa': 'AS', 'Arizona': 'AZ',
  'Arkansas': 'AR', 'California': 'CA', 'Colorado': 'CO', 'Connecticut': 'CT',
  'Delaware': 'DE', 'District of Columbia': 'DC', 'States of Micronesia': 'FM',
  'Florida': 'FL', 'Georgia': 'GA', 'Guam': 'GU', 'Hawaii': 'HI', 'Idaho': 'ID',
  'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA', 'Kansas': 'KS', 'Kentucky': 'KY',
  'Louisiana': 'LA', 'Maine': 'ME', 'Marshall Islands': 'MH', 'Maryland': 'MD',
  'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN', 'Mississippi': 'MS',
  'Missouri': 'MO', 'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV', 'New Hampshire':
  'NH', 'New Jersey': 'NJ', 'New Mexico': 'NM', 'New York': 'NY', 'North Carolina': 'NC',
  'North Dakota': 'ND', 'Northern Mariana Islands': 'MP', 'Ohio': 'OH', 'Oklahoma': 'OK',
  'Oregan': 'OR', 'Palau': 'PW', 'Pennsylvania': 'PA', 'Puerto Rico': 'PR',
  'Rhode Island': 'RI', 'South Carolina': 'SC', 'South Dakota': 'SD', 'Tennessee': 'TN',
  'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT', 'Virgin Islands': 'VI', 'Virginia': 'VA',
  'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
};

const toUpper = c => c.toUpperCase();

export const wvMineCounties = ['Barbour', 'Boone', 'Braxton', 'Brooke', 'Clay', 'Doddridge', 'Fayette', 'Gilmer', 'Grant',
  'Greenbrier', 'Hancock', 'Harrison', 'Kanawha', 'Lewis', 'Lincoln', 'Logan', 'Marion', 'Marshall', 'Mason',
  'McDowell', 'Mercer', 'Mineral', 'Mingo', 'Monongalia', 'Nicholas', 'Ohio', 'Pocahontas', 'Preston', 'Putnam',
  'Raleigh', 'Randolph', 'Summers', 'Taylor', 'Tucker', 'Tyler', 'Upshur', 'Wayne', 'Webster', 'Wetzel', 'Wyoming'
].map(toUpper);
export const kyMineCounties = ['Bell', 'Boyd', 'Breathitt', 'Butler', 'Carter', 'Christian', 'Clay', 'Daviess',
  'Edmondson', 'Elliott', 'Floyd', 'Greenup', 'Hancock', 'Harlan', 'Henderson', 'Hopkins', 'Jackson', 'Johnsen',
  'Knott', 'Knox', 'Laurel', 'Lawrence', 'Lee', 'Leslie', 'Letcher', 'Martin', 'McCreary', 'McLean', 'Morgan',
  'Muhlenberg', 'Ohio', 'Owsley', 'Perry', 'Union', 'Webster', 'Whitley', 'Wolfe'
].map(toUpper);
export const ilMineCounties = ['Bond', 'Bureau', 'Christian', 'Clinton', 'Douglas', 'Franklin', 'Fulton', 'Gallatin',
  'Grundy', 'Jackson', 'Jefferson', 'Knox', 'LaSalle', 'Logan', 'McDonough', 'Macoupin', 'Madison', 'Marion',
  'Marshall', 'Menard', 'Mercer', 'Montgomery', 'Peoria', 'Perry', 'Putnam', 'Randolph', 'Rock Island',
  'St. Clair', 'Saline', 'Sangamon', 'Tazewell', 'Vermilion', 'Washington', 'Williamson'
].map(toUpper);

export const quoteCacheVersion = 2.01;
export const policyCacheVersion = 0.4;
