import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {defaultMix} from '@/lib/mix';
import JsonViewer from 'vue-json-viewer';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import vuetify from './plugins/vuetify';
import './polyfills';

let envName = process.env.DEFAULT_ENV;
let appVersion = process.env.APP_VERSION;

if (envName !== 'local') {
  datadogLogs.init({
    clientToken: 'pub7a9d4eb4cd5aee8fa55b3bcafe2f2ab2',
    forwardErrorsToLogs: false,
    sampleRate: 100,
    service: 'lio-portal',
    env: envName,
    version: appVersion
  });
  datadogRum.init({
    applicationId: 'a4317444-3835-488d-baed-db25bf4f4166',
    clientToken: 'pubae45ab98729cbabeb3f9f616cd377bc3',
    site: 'datadoghq.com',
    service: 'lio-portal',
    env: envName,
    version: appVersion,
    sampleRate: 100,
    trackInteractions: true
  });
  console.log(`**${envName.toUpperCase()} - v${appVersion}**`);
  if (envName === 'prod'){
    datadogRum.startSessionReplayRecording();
  }
}

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(JsonViewer);
Vue.config.devtools = true;
Vue.mixin(defaultMix);

if (self === top) {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}
