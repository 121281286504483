<template>
  <div class="geocoder flex-fill pt-2" :class="{validState:state===true,invalidState:state===false,readOnly}">

    <div v-if="def.readOnly">{{ def.address }}</div>
    <div v-else>
      <div><strong>Street Line 1</strong></div>
      <b-input-group v-if="manualInput || readOnly">
<!--        <b-input
            autocomplete="nope"
            v-model="def.val.street1" placeholder="Street Line 1"
            :autofocus="!readOnly" :readonly="readOnly"
            :class="{readOnly}"
        />-->
        <fieldtype_text :def="childDef('street1', {autocomplete:'nope',placeholder:'Street Line 1', autofocus:!readOnly})"/>
        <b-input-group-append>
          <b-button @click="resetAll" style="pointer-events: all">
            <i class="fas fa-times-circle"></i>
          </b-button>

        </b-input-group-append>
      </b-input-group>
      <v-autocomplete v-else
          ref="vaddress" :auto-select-first="true"
          :autofocus="autofocus" type="search" autocomplete="nope"
          v-model="addressId" @input="chooseAddressItem" @focus="focusState(true)" @blur="focusState(false)"
          @keypress="lookup" :items="menuItems" :hide-no-data="true" @update:search-input="lookup"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <div class="linked-centered" v-if="item.unverified">
              <v-list-item-title v-text="item.optional ? `+ Manually enter an address` : `We can't find a matching address`"/>
              <v-list-item-subtitle v-if="!item.optional" v-text="`+ Manually enter an address`"/>
            </div>

            <v-list-item-title v-text="`${item.text}, ${item.city}, ${item.state} ${item.zipcode}`" v-else-if="item.city"/>
            <v-list-item-title v-text="item.text" v-else/>

          </v-list-item-content>

        </template>
      </v-autocomplete>
      <div v-if="def.street2 !== false" class="mt-2">
        <strong tabindex="0" ref="sl2">Street Line 2</strong>
        <fieldtype_text :def="childDef('street2', {autocomplete: 'nope'})"/>
      </div>

      <div class="d-flex mt-2">
        <div class="w-50 pr-2">
          <strong>City</strong>
          <fieldtype_text :def="childDef('city', {autocomplete: 'nope'})"/>
        </div>
        <div class="w-25 pr-2">
          <div><strong>State</strong></div>
          <fieldtype_select :def="childDef('state')" v-if="def.anyJurisdiction"/>
          <div class="p-2" v-else-if="stateCode">{{ findState(def.val.stateCode || stateCode).code }}</div>
        </div>
        <div class="w-25">
          <strong>Zip</strong>
          <fieldtype_text :def="childDef('zipcode', {autocomplete: 'nope'})"/>
        </div>


      </div>
      <div class="small text-right mt-2" v-if="def.deleteAction || manualInput" style="pointer-events: all">
        <a class="text-primary" v-if="manualInput" @click="manualInput=false;resetAll()">Suggest a location</a>
        <span v-if="def.deleteAction && manualInput"> | </span>
        <a v-if="def.deleteAction" @click="def.deleteAction" class="text-primary">Delete</a>
      </div>
    </div>

    <address-suggestion v-if="suggestionApiStatus"
        :addresses="addresses" :status="suggestionApiStatus"
        :title="def.itemKey" @chooseAddress="chooseAddressItem"/>
  </div>
</template>

<script>
import {isType} from '@/lib/mytype';
import {mapGetters, mapMutations} from 'vuex';
import Field1 from '@/components/fields/field';
import Fieldtype_text from '@/components/fields/fieldtype_text';
import Fieldtype_select from '@/components/fields/fieldtype_select';
import {quotemix} from '@/lib/quotemix';
import AddressSuggestion from '@/components/modal/address-suggestion';
import Debugger from '@/components/util/debugger';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Fieldtype_select, Fieldtype_text, Field1, AddressSuggestion
  },
  data: () => {
    return {
      searchText: '',
      userText: null,
      addressId: '',
      hilite: null,
      suggestions: [],
      top: '20px',
      suggestionApiStatus: null,
      addresses: [],
      apiCorrection: null,
      manualInput: false,
      readOnly: false
    };
  },
  name: 'fieldtype_address',
  mixins: [quotemix],
  computed: {
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain', 'findState']),
    addressString(){
      let {street1, city, zipcode} = this.def.val;
      return street1 + city + zipcode;
    },
    autofocus() {
      let street = this.def.val.street1;
      return street ? false :
        isType.nullOrUndef(this.def.autoFocus) ? true : this.def.autoFocus;
    },
    menuItems() {
      let {def, suggestions, userText, searchText} = this;

      if (this.unverifiedItem){
        return [this.unverifiedItem];
      }
      let hasSuggestions = Array.isArray(suggestions) && suggestions.length;
      let items = [];
      let uniq = {};
      if (hasSuggestions){
        items = suggestions.map((s, i) => {
          let street = s.street_line || s.street1;
          let uniqText = `${street}${s.zipcode}`;
          if (uniq[uniqText]){
            return null;
          }
          uniq[uniqText] = true;
          return {
            text: `${street}`,
            value: `${i}-${street}`,
            street1: street,
            ...s
          };
        }).filter(item => item);
        if (items.length !== Object.keys(uniq).length){
          console.warn('smartyDups', {items, uniq});
        }
      }
      else if (def.address){
        items = [{
          text: def.val.street1,
          value: `-1-${def.val.street1}`,
          ...def.addressObj
        }];
      }

      let manualItem = {
        text: `${searchText} `,
        value: `${items.length}-${searchText}`,
        unverified: true,
        optional: true
      };

      items.push(manualItem);

      return items;
    },
    unverifiedItem(){
      let {searchText, suggestions} = this;

      if (this.apiCorrection){
        return this.apiCorrection;
      }
      if (!searchText.length || !Array.isArray(suggestions)){
        return null;
      }
      else {
        let matchingItem = suggestions.find(s => s.unverified || s.verified || s.street1.toLowerCase().includes(searchText.toLowerCase()));
        if (matchingItem) {
          return null;
        }
      }
      let {city, state, zipcode} = this.def.val;
      state = this.findState(state)?.code ?? '';
      let value = `${suggestions.length}-${searchText}`;
      return {
        text: searchText,
        street1: searchText,
        value, //this.addressId,
        unverified: true,
        city, state, zipcode
      };
    },
    stateCode() {
      let stateId = this.itemVal('scope.jurisdiction');
      return this.findState(stateId)?.code;
    },
    exactMatch() {
      if (this.selectedObject) {

        let {unverified, street_line, city, state, zipcode} = this.selectedObject;
        if (unverified){
          return false;
        }
        let addressInput = this.def.val;
        return (
            street_line === addressInput.street1 &&
            city === addressInput.city &&
            `${state}` === `${addressInput.state}` &&
            zipcode === addressInput.zipcode
        );
      }
      return false;
    },
    needsVerification() {
      if (this.readOnly){
        return false;
      }
      let {readyToValidate} = this.def.focusedState;
      return this.def.requireExact &&
          readyToValidate &&
          !this.def.val.verified &&
          this.def.isValid &&
          !this.exactMatch;
    },
    selectedObject() {
      let items = this.menuItems;
      let selected = items.find(address => address.value === this.addressId);

      return selected || items.find(item => item.unverified || item.verified);
    },
    childDef(){
      return (key, args) => {
        let {readOnly, def} = this;
        let child = def.child(key);
        if (child) {
          if (args){
            Object.entries(args).forEach(([key, val]) => {
              child[key] = val;
            });
          }
          child.readOnly = readOnly;
          return child;
        }
        return null;
      };
    }
  },
  methods: {
    ...mapMutations('getQuote', ['setChained', 'updateField']),
    resetAll(){
      if (this.def.anyJurisdiction) {
        this.def.val = null;
      }else{
        this.def.val = {street1: null, city: null, zipcode: null};
      }
      this.addressId = null;
      this.readOnly = false;
      this.suggestionApiStatus = null;
    },
    correctAddress(address) {
      if (isType.object(address)) {

        address.stateCode = address.state;
        address.verified = true;
        address.text = address.street1;
        this.apiCorrection = {};
        Object.entries(address).forEach(([key, val]) => {

          this.apiCorrection[key] = val;
        });

        this.apiCorrection.value = '0-replacement';
        this.suggestions = [this.apiCorrection];

        this.def.val = this.apiCorrection;
        this.addressId = '0-replacement';
        this.delayFn(() => this.chooseAddressItem(this.addressId));
      }
      this.suggestionApiStatus = null;
    },
    focusState(focused) {

      let street1 = this.def.child('street1');
      street1.blurred = !focused;
      street1.touched = true;
      this.$emit(focused ? 'focus' : 'blur');
      if (!focused && !this.selectedObject){
        this.selectUnverifiedItem();
      }
    },
    selectUnverifiedItem(replacement){
      let unverified = this.menuItems.find(item => item.unverified);

      if(unverified){

        this.delayFn(() => {
          let vaddr =  this.$refs.vaddress;
          this.chooseAddressItem(unverified.value);
          vaddr.setValue(unverified.value);
        });
      }

    },
    chooseAddressItem(val) {

      let addressData = this.menuItems.find(({value}) => value === val);
      if (!addressData) {
        return;
      }
      else if (addressData.unverified){
        this.manualInput = true;
      }
      if (addressData.state) {
        addressData.stateCode = addressData.state;
        addressData.state = this.findState(addressData.state).key;
      }

      this.$emit('input', addressData);
      this.$emit('focus');
      if (this.def) {
        if (!addressData.unverified) {
          this.def.child('verified').val = true;
        }
        let {chain} = this.def;

        [...this.def.addressKeys, 'stateCode'].forEach(k => {
          this.updateField({chain: `${chain}.${k}`, val: addressData[k]});
        });

        this.delayFn(() => this.addressId = addressData.value, 1);
      }
      if (this.chain) {
        this.setChained({chain: this.chain, val: addressData.value});
      }
      if (this.exactMatch && !this.def.anyJurisdiction) {
        this.readOnly = true;
        this.verifyAddress();
      }

    },
    lookup(e) {
      if (!e?.srcElement) {
        return;
      }

      this.userText = e?.srcElement.value;
      this.delayFn(() => {
        let s;
        try {
          s = encodeURIComponent(e?.srcElement.value);
        } catch (ex) {
          console.log({ex, e});
        }
        if (!s || s.length < 3) {
          return;
        }
        let state = this.stateCode;
        if (!state) {
          state = this.itemVal('customer.address.stateCode') || '';
        }
        const host = `https://us-autocomplete-pro.api.smartystreets.com/`;
        const key = this.local ? `17117971461292786` : '17117971461292786';// `17117972998345582`;
        const license = `license=us-autocomplete-pro-cloud`;//,us-rooftop-geocoding-cloud`;
        const filter = this.def.anyJurisdiction || !state ? '' : `&include_only_states=${state}`;
        const url = `${host}lookup?key=${key}&search=${s}&prefer_geolocation=none${filter}&${license}`;
        const options = {};
        fetch(url, options)
            .then(response => response.json())
            .then(r => {
              let uniq = [];
              const filterDups = sug => uniq.filter(l => l.street1 === sug.street_line && l.city === sug.city).length === 0;

              if (this.def.uniqWithin && Array.isArray(this.def.uniqWithin)) {
                uniq = this.def.uniqWithin.map(({street1, city}) => ({street1, city}));
              }
              let newSuggestions = r.suggestions?.filter(filterDups) ?? [];
              let suggestions = newSuggestions.length ? newSuggestions : [];
              this.suggestions = suggestions.map(s => {
                s.street1 = s.street_line;
                return s;
              });
              //console.log({suggestions});
            });
      });
    },
    verifyAddress() {
      this.suggestionApiStatus = null;
      let unverifiedAddress = this.def.val;

      let {street1, city, stateCode, zipcode} = unverifiedAddress;
      let state = this.findState(stateCode)?.code;

      let address = {street1, city, state, zipcode};
      this.lioApi('addressSuggestions', null, address).then(({response}) => {
        try {
          let {status, address} = response;
          let {readOnly} = this;
          console.log({verifyAddressResponse: response, status, address, readOnly});
          if (this.readOnly){
            if(address.length && status === 'match-exact') {
              this.correctAddress(address[0]);
              return;
            }
          }if (Array.isArray(address)){
            let uniq = {};
            address.forEach(addr => {
              uniq[`${addr.street1}${addr.city}${addr.zipcode}`] = addr;
            });
            this.addresses = Object.values(uniq);
          }else {
            this.addresses = address;
          }
          this.suggestionApiStatus = status;


        }catch(ex){
          this.suggestionApiStatus = 'no-results';
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    }

  },
  mounted() {
    try {
      this.delayFn(() => {
        let {street1, city, stateCode, zipcode} = this.def.val;
        if (!this.def.anyJurisdiction && !stateCode){
          stateCode = this.stateCode;
          this.def.treeVals = {stateCode};
        }
        if (street1 && !this.suggestions.length){
          this.userText = this.address;

          this.suggestions = [{
            city,
            state: stateCode,
            street1,
            street_line: street1,
            zipcode
          }];
          this.delayFn(() => this.chooseAddressItem(`0-${street1}`));
        }
      }, 10);
    } catch (ex) {
      console.warn(ex);
      this.address = '';
    }
  },
  props: ['value', 'chain', 'def', 'state', 'uniqWithin'],
  watch: {
    userText(t, prev) {

      if (t) {
        this.delayFn(() => {
          this.searchText = this.$refs.vaddress.lazySearch;
          this.def.child('street1').val = this.searchText;
          this.apiCorrection = null;
          this.def.child('verified').val = false;
          this.delayFn(() => {
            if (this.unverifiedItem){
              this.addressId = this.unverifiedItem.value;
            }
          });
        }, 100);
      }
    },
    addressId(addressId) {
      let {searchText, menuItems} = this;
      if (!addressId?.includes(searchText)) {
        let placeholder = menuItems.find(item => item.unverified)?.value;
        if (placeholder?.includes(searchText)) {
          this.addressId = placeholder;
        }
      }
    },
    exactMatch(exact, prev){
      if (prev && !exact){
        this.def.child('verified').val = false;
      }
    },
    addressString(){
      this.def.child('verified').val = false;
    },
    needsVerification(needs){
      if (needs){
        this.verifyAddress();
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

div.menuable__content__active {
  div.v-list {
    .v-list-item.v-list-item--disabled {
      display: none !important;
    }
  }
  div.linked-centered{
     * {
       color: $primary;
       text-align: center;
       cursor:pointer;
     }
   }
}

.geocoder {
  &.readOnly{
    pointer-events: none;
    input, .dropdown-toggle{
      background-color: #eee;
      color:#999;
      span{
        color:#999;
      }
    }
  }
  position: relative;

  strong {
    font-size: 13px;
  }
  /*.form-control.readOnly{
    background-color: white;
  }*/
  &.validState {
    .v-input__slot {
      .v-select__slot {
        border-color: $success !important;

        .v-input__icon.v-input__icon--append i {
          color: $success;
        }
      }
    }
  }

  &.invalidState {
    .v-input__slot {
      .v-select__slot {
        border-color: $danger !important;

        .v-input__icon.v-input__icon--append i {
          color: $danger;
        }
      }
    }
  }

  .suggestions {
    position: absolute;
  }

  .v-input {
    padding-top: 0;

    .v-input__control {
      //border: solid 1px $gray;

      .v-input__slot {
        &:before, &:after {
          border: 0;
        }
      }

      .v-select__slot {
        padding: 3px 0 3px 8px;
        border: solid 1px $gray;
        border-radius: 4px;

      }
    }

    &.v-input--is-focused {
      .v-select__slot {
        box-shadow: 0 0 0 0.2rem rgba(209, 106, 96, 0.25);
        border-color: rgba(209, 106, 96, 0.25);
      }
    }
  }
}

body {
  //91
}
</style>
