<template>

  <div class="lio-progress">
    <div class="text-center mb-2">
      <slot></slot>
    </div>
    <div class="progress-anim" :style="{animation: `colorAnimation linear 1.5s infinite`}"></div>

  </div>
</template>

<script>
export default {
  name: 'progress-bar'
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

div.lio-progress {

  .progress-anim {
    border:solid 1px $gray;
    width: 100%;
    background-image: linear-gradient(90deg,
        rgba(158,210,204, 0),
        rgba(158,210,204, 0),
        rgba(158,210,204,.33),
        rgba(158,210,204, 1),
        rgba(158,210,204,.33),
        rgba(158,210,204, 0),
        rgba(158,210,204, 0),
        rgba(158,210,204, 0),
        rgba(158,210,204, 0),
        rgba(158,210,204,.33),
        rgba(158,210,204, 1),
        rgba(158,210,204,.33),
        rgba(158,210,204, 0),
        rgba(158,210,204, 0)
    );
    ;
    height: 12px;
    border-radius: 4px;
    background-size:200% 200%;
    background-position: 50%;
    background-color: #b5e3de !important;
  }
}

@keyframes colorAnimation {
  0% {
    background-position: 50%;
  }

  50% {
    background-position: 0%;
  }

  100% {
    background-position: -50%;
  }
}

</style>
