<template>
  <div>
    <building-selection />
    <div v-if="selection.length">
      <div class="accordion" role="tablist" v-if="activeGroup" :class="{invis}">
        <b-card style="overflow:visible"
            v-for="(title,group) in groups"
            :key="group" header-bg-variant="light"
            no-body class="mb-0" bg-variant="light">
          <b-card-header
              header-tag="header"
              class="p-1" role="tab" header-bg-variant="light"
              :style="{'border-bottom-color':lioTheme.lightGray}">
            <b-button block
                @click="activeGroup = group"
                :variant="['construction','coverages','details'].includes(group) ? 'primary' : 'info'"
                    class="text-left d-flex" :class="{isActive: activeGroup === group}">
              <strong style="color:inherit" class="flex-fill">
                <b-icon-info-circle
                    v-if="!['construction','coverages','details'].includes(group)"
                    variant="secondary"
                    v-b-tooltip.top="'Reminder: Items in this section are applied at the building level so make sure to select the relevant location and buildings in the left navigation.'"
                />
                {{ title }}
                </strong>
              <b-icon-check :scale="1.4" v-if="validgroup(group) && reqCount(group)"
                  variant="secondary" class="text--white"/>
              <span v-else-if="reqCount(group)">
                <b-badge :style="{color:lioTheme.coral,textShadow:'0 0.5px .5px black',fontWeight:300}">{{validCount(group)}}/{{reqCount(group)}}</b-badge>
              </span>
            </b-button>
          </b-card-header>
          <b-collapse :id="group" :visible="activeGroup===group" accordion="building-fields" role="tabpanel" style="overflow: visible;">
            <b-card-body style="overflow: visible;min-height: 250px" class="pt-4">
              <div class="d-flex mr-2" style="margin:-15px 0 -5px">
                <div class="w-50">
                  <field-column :defs="groupDefs(group, 0, groupInd[group])" field-w="50%" label-w="50%"/>
                </div>
                <div class="w-50">
                  <field-column :defs="groupDefs(group, 1, groupInd[group])" field-w="50%" label-w="50%"/>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {mapGetters} from 'vuex';
import {blanketMix, buildingsMix} from '@/lib/mix';
import BuildingSelection from '@/components/quote-steps/shared/building-selection';
import {sortByKey} from '@/lib/util';
import deepEqual from 'deep-equal';
import eventbus from '@/lib/eventbus';
import {isType} from '@/lib/mytype';
import { decompressBuildings } from '@/lib/hydration-io';
import FieldColumn from '@/components/fields/field-column';

export default {
  data: () => {
    return {
      invis: false,
      locationMode: false,
      newSelection: false,
      activeGroup: 'construction',
      originalValues: null,
      groupInd: {
        construction: 5,
        addlCov: 4,
        utilSvc: 4,
        details: 3
      },
      touched: []
    };
  },
  name: 'buildings',
  components: {FieldColumn, BuildingSelection},
  computed: {
    renderedFields() {
      let group = this.activeGroup;
      return this.storeFields('buildingFields').filter(f => f.group === group);
    },
    ...mapGetters('getQuote', ['buildings', 'locations', 'hasChanges', 'selectedChildField']),
    blanketChanges(){
      const deletedBuildings = decompressBuildings(this.buildings, [], this.locations).filter(b => b._delete);
      let fields = this.hasChanges('buildingFields')
        .filter(([chain]) => ['buildingLimit'].includes(chain.split('.').pop()));
      return this.hasAnyBlanketData && (fields.length || deletedBuildings.length);
    },
    groups(){
      let visible = {
        construction: 'Building Construction Detail (required)',
        coverages: 'Building Coverages (required)'
      };
      let yearItem = this.itemFromChain('buildingFields.yearBuilt');
      let year = yearItem.val;
      if (this.isType.snum(year) && Number(year) < (new Date().getFullYear() - 15) ){
        visible.details = 'Building History and Details';
      }
      return {
        ...visible,
        addlCov: 'Additional Building Coverages - Page 1 (optional)',
        utilSvc: 'Additional Building Coverages - Page 2 (optional)'
      };
    },
    totalValid(){
      return ['construction', 'coverages', 'details'].reduce((agg, p) => this.validCount(p) + agg, 0);
    },
    validgroup(){
      return groupName => this.validCount(groupName) >= this.reqCount(groupName);
    },
    reqCount(){
      return groupName =>
        this.buildings.filter(building => building.selected)
          .flatMap(b => b.children.filter(cf => cf.group === groupName && cf.isRequired)).length;
    },
    validCount(){
      return groupName =>
        this.buildings.filter(building => building.selected)
          .flatMap(b => b.children
            .filter(cf => cf.group === groupName && cf.isRequired && cf.isValid)
          ).length;
    },

    groupDefs() {
      return (p, col, mid) => {
        let defs = sortByKey(this.storeFields('buildingFields'), 'i')
            .filter(f => f.group === p);
        if (col === undefined) {
          return defs;
        }
        if (!mid) {
          mid = Math.ceil(defs.length / 2);
        }
        let tag = `col${col}`;
        if (defs.filter(d => d.hasTag(tag)).length > 1){

          return defs.filter(d => d.hasTag(tag));
        }

        return col === 0 ? defs.slice(0, mid) : defs.slice(mid);
      };
    },
    apiParams(){
      let entries = this.buildings.flatMap(b => {
        return b.children
          .filter(c => c.key === 'name' || (c.group === this.activeGroup && !isType.nullOrUndef(c.val)))
          .map(c => [c.chain, c.val]);
      });
      return Object.fromEntries(entries);
    },
    dirty(){
      return this.buildings.filter(b => b && b.dataTree._delete).length ||
        !deepEqual(this.originalVals, this.apiParams);
    }
  },
  methods: {
    async save(){
      if (!this.dirty){
        return Promise.resolve(false);
      }
      if (this.blanketChanges){
        return this.saveBlankets(true).then(result => {
          console.log({updatedBlankets: result});
          return this.saveBuildingsByGroup(this.touched);
        });
      }
      else {
        return this.saveBuildingsByGroup(this.touched);
      }
    },
    cleanSlate(){
      this.originalVals = this.clone(this.apiParams);
      this.touched.splice(0, this.touched.length, this.activeGroup);
    }
  },
  mixins: [quotemix, buildingsMix, blanketMix],
  watch: {
    activeGroup(ap){
      this.setAny({buildingFilter: ap});
      this.touched.push(ap);
    }
  },
  mounted() {
    this.cleanSlate();

    eventbus.$on('quoteRehydrate', () => {
      this.cleanSlate();
    });
    this.setAny({buildingFilter: 'construction'});
    this.delayFn(() => {
      this.activeGroup = 'construction';
    }, 123);

  }
};
</script>

<style lang="scss">
.card {
  &-header {
    .btn {
      color: black;
      strong {
        color: black;
      }
    }
  }
}
.text--white {
  fill:white;
}

</style>
