<template>
<div  class="">
  <b-toast
      :toast-class="{shown, notShown:!shown, invis}"
      id="progressToast" variant="info"
    solid :visible="true" no-auto-hide no-fade
    toaster="b-toaster-bottom-right" no-close-button>
    <progress-bar :gradient="1.5" class="mb-2 ml-4 mr-4" v-if="shown">
      {{ text }}
    </progress-bar>
  </b-toast>
</div>
</template>

<script>
import ProgressBar from './progress-bar';
import eventbus from '@/lib/eventbus';
export default {
  data: () => {
    return {
      text: false,
      shown: false,
      invis: true
    };
  },
  components: {ProgressBar},

  name: 'progress-toast',
  mounted(){
    eventbus.$on('progressToast', text => {
      //console.log({toastText: text});
      if (text) {
        this.text = text;
        this.shown = true;
      }else{
        this.shown = false;
      }

    });
    eventbus.$on('oneShieldError', () => this.shown = false);
    this.delayFn(() => this.invis = false, 500);
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";
.b-toaster-bottom-right{
 pointer-events: none;
}
#progressToast{
  header{
    border: transparent;
    background: transparent;
  }
  background-color: #fdfefe;
  border: solid 1px $teal;
  box-shadow: none;
  margin-bottom: 4px;
  margin-right: 8px;
  pointer-events: none;
  *{
    pointer-events: none;
  }
  &.shown{
    animation: fadeIn linear .2s;
    opacity: 1;
  }
  &.notShown{
    animation: fadeOut linear .5s;
    opacity:0;
    pointer-events: none;
  }
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
</style>
