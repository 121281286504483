import Vue from 'vue';
import VueRouter from 'vue-router';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';

import Home from '../views/AgentHome.vue';
import xsd from '@/views/xsd';
import LoginComponent from '../views/Login.vue';
import Quote from '../views/Quote';
import Quotes from '@/views/Quotes';
import Policy from '@/views/Policy';
import LoginCallback from '@/components/login-callback';
import authConfig from '@/config';


export const oktaAuth = new OktaAuth({
  ...authConfig.oidc,
  restoreOriginalUri: async (_, originalUrl) => {
    let lastPath = localStorage.getItem('lastPath');
    if (lastPath){
      lastPath = JSON.parse(lastPath)?.cur || '/';
    }else{
      lastPath = '/';
    }
    if (originalUrl !== lastPath && lastPath !== router.history.current.path) {
      router.replace({
        path: lastPath
      });
    }
  }
});

Vue.use(VueRouter);
Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    try {
      if (router.currentRoute.path === '/login'){
        return;
      }
      router.push({path: '/login'});
    }catch(ex){
      console.log('saved last url');
    }
  }
});

let requiresAuth = true;
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth
    }
  },
  {
    path: '/login',
    component: LoginComponent
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: '/xsd',
    name: 'xsd',
    component: xsd
  },
  {
    path: '/quote',
    name: 'quote',
    component: Quote,
    meta: {
      requiresAuth
    }
  },
  {
    path: '/policy/:policyId?/:transDate?',
    name: 'policy',
    component: Policy,
    meta: {
      requiresAuth
    }
  },
  {
    path: '/quote/:quoteId/:step/:tab?',
    name: 'getQuote',
    component: Quote,
    meta: {
      requiresAuth
    }
  },
  {
    path: '/policies',
    name: 'policies',
    component: () => import('../views/Policies.vue'),
    meta: {
      requiresAuth
    }
  }, {
    path: '/quotes',
    name: 'quotes',
    component: Quotes,
    meta: {
      requiresAuth
    }
  },
  {
    path: '/submitclaim',
    name: 'submitclaim',
    component: () => import('../views/SubmitClaim.vue'),
    meta: {
      requiresAuth
    },
    beforeEnter() {open('https://lioinsurance.com/submit-a-claim/');}
  },

  {
    path: '/sandbox',
    name: 'sandbox',
    component: xsd,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/debug/quote/:quoteId',
    name: 'debugquote',
    component: () => import('../views/debug/OpenQuote'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/debugpage/:page',
    name: 'debugpassthru',
    component: () => import('../views/debug/index'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '*',
    redirect: '/'
  }

];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
