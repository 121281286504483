<template>
  <b-modal
      id="miscOutdoor" size="xl" :header-class="{'show-close-x': false}"
      title="Miscellaneous Outdoor Property" @hide="cancel(true)"
      :no-close-on-backdrop="true" :no-close-on-esc="true"
      v-model="showModal">
    <div v-if="location">
<!--      <json-viewer :value="{outdoorPropertyList:location.outdoorPropertyList}"/>-->
      <div class="d-flex" v-if="isSave">
        <div class="w-25"></div>
        <div class="flex-fill">
          <progress-bar class="m-3">Saving</progress-bar>
        </div>
        <div class="w-25"></div>
      </div>
      <b-alert v-else-if="error.message" :show="true" variant="danger" >{{error.message}}</b-alert>
      <div v-else class="pl-4">
        <h3 class="mb-4">Outdoor Property Types</h3>
        <div class="d-flex">
          <div v-for="(chunk, ci) in chunkArray(location.outdoorPropList, 3)" :key="`opt${ci}`">
            <div v-for="def in chunk" :key="def.lbl" :class="{['pr-3']:ci!==2,['pl-3']:ci}" class="outdoor-type-row">
              <field :def="def"><span>{{ def.lbl }}</span></field>
              <div v-if="def.hasTag('descriptionRequired') && def.val" style="margin:-16px 0 0 24px">
                <field :def="location.child('describeOther')"/>
              </div>
            </div>
          </div>
        </div>
        <h3 class="m-0 mt-4" v-if="showCoverages">Coverage Options</h3>
        <div class="d-flex" v-if="showCoverages">
          <div>
            <field-column :defs="[
              location.child('valuationType'),
              location.child('causeOfLoss'),
              location.child('inflationGuard')
            ]"/>
          </div>
          <div class=" pl-4 ">
            <field-column :defs="[
              location.child('agreedValue'),
              location.child('perilsDeduct'),
              location.child('outdoorBlanketTotal')]"/>
          </div>

        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100 modal-buttons">

        <b-button
            :disabled="isSave" :class="{disabled:isSave}"
            variant="info" size="lg" @click="cancel()"
        >Cancel</b-button>
        <b-button
            :class="{disabled:!validFormState || isSave}"
            v-if="!error.message" :disabled="!validFormState || isSave"
            variant="primary" size="lg" @click="save()"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {quotemix} from '@/lib/quotemix';
import rfdc from 'rfdc';
import deepEqual from 'deep-equal';
import eventbus from '@/lib/eventbus';
import {maxOSInt} from '@/lib/util';
import ProgressBar from '@/components/util/progress-bar';
import Field from '@/components/fields/field';
import FieldColumn from '@/components/fields/field-column';

const clone = rfdc();

export default {
  components: {FieldColumn, Field, ProgressBar},

  data: () => {
    return {
      error: {message: null},
      saveEnabled: true,
      isSave: false,
      showModal: false,
      deletedPropertyIds: [],
      initialParams: null
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemFromChain', 'itemVal']),
    legacy(){
      return this.dataVersion < 2;
    },
    showCoverages(){
      return this.location?.outdoorPropList.filter(c => c.val).length > 0;
    },
    validFormState() {
      let loc = this.location;
      if (!loc){
        return false;
      }
      let listValid = loc.outdoorPropertyList.length > 0;
      let covValid = (!this.showCoverages) ||
          loc.children
        .filter(c => c.group === 'miscOutdoor' && !c.isValid).length === 0;
      return listValid && covValid;
    },
    unsaved1190(){
      let loc = this.location;
      if (!loc){
        return false;
      }
      return !loc.outdoorPropList.filter(c => c.propertyId).length
        && loc.isOPO;

    },
    paramsToSave() {
      let {location, legacy} = this;
      if (!location) {
        return undefined;
      }
      const {quoteId, policyId, policyCFId, policyCommercialCFId, jurisdictionCFId} = this.quoteData.quote;
      const locVals = l => {
        let fields = l.children.filter(c => c.group === 'miscOutdoor');
        return Object.fromEntries(fields.map(f => [f.key, f.val]));
      };
      return {
        quoteId, policyId, policyCFId, policyCommercialCFId,
        jurisdictionCFId, legacy,
        locations: [{
          locationId: location.dataTree.locationId,
          cfLocationId: location.dataTree.cfLocationId,
          ...locVals(location),
          outdoorProperties: location.outdoorPropertyList
        }]
      };
    },
    touchedGroups() {
      let groups = Object.fromEntries(
        this.hasChanges('buildingFields')
          .map(([chain]) => [this.itemFromChain(chain).group, 1])
      );
      return Object.keys(groups).length ? Object.keys(groups) : ['construction'];
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField', 'removeDef']),
    checkSubject(i, row) {
      let minAmt = row[0].val === '2002' && row[1].val === '1002' ? 10001 : 0;
      row[2].range = [minAmt, maxOSInt];
    },
    cancel() {
      if (this.isSave) {
        return;
      }
      if (this.unsaved1190){
        let buildings = this.location.buildingsDef.children;
        buildings.splice(buildings.findIndex(b => b.isPseudo), 1);
      }
      if (this.location) {
        this.location.removeUnsaved();
      }
      this.$emit('closed');
      this.showModal = false;
    },
    async save() {

      if (!this.saveEnabled) {
        return;
      }

      this.isSave = true;
      this.saveEnabled = false;
      const params = this.paramsToSave;
      const doesNeedUpdate = !deepEqual(params, this.initialParams);
      let loc = this.location;

      if (loc && doesNeedUpdate) {
        const {response} = await this.oneShield('updateOutdoorProperties', params, {toast: 'Updating Outdoor Properties'});
        if (response.hasErrors){
          this.error = response.error;
          this.isSave = false;
          return;
        }

        let props = response.fields.find(f => f.chain === 'locations.outdoorProperties')?.val;
        // if showCoverages is true, we will not have a property in the response
        if (this.showCoverages && Array.isArray(props) && props.length) {
          let foundProp = props.find((p) => p.locationId === loc.dataTree.locationId);
          if (foundProp) {
            loc.treeVals = props;
            this.updateField({chain: loc.chain, props});
          }else{
            let locationId = loc.dataTree.locationId;
            console.warn({missingLocationId: locationId, props, params});
          }
        }
        else if (!this.showCoverages && loc.isOPO){
          // invalidate the location by deleting the pseudo building
          loc.buildingsDef.children[0].treeVals = {_delete: true};
          console.log({deleted_1190_building: loc.buildingsDef.children[0]});
        }
        await this.saveBuildingsByGroup(this.touchedGroups);
        eventbus.$emit('progressToast', false);
      }

      this.$emit('closed');
      this.isSave = false;
      this.saveEnabled = true;
      this.showModal = false;
    }
  },
  mounted() {
    this.isSave = false;
  },
  name: 'outdoor-property',
  props: ['location', 'buildingPanel'],
  mixins: [quotemix],
  watch: {

    location(l) {
      if (l) {
        if (!this.showModal) {
          this.initialParams = clone(this.paramsToSave);
        }

        this.showModal = true;
        this.isSave = false;
      }
    }
  }
};
</script>
<style lang="scss">
.modal-buttons .disabled{
  pointer-events: none;//ignore confusing hover interaction
}
div.outdoor-type-row{
  margin-bottom:-12px;
  margin-top:-14px;
}
</style>
