export const quote = [
  'quoteNumber', 'quoteId', 'submission',
  'customerId', 'customerEligibilityId', 'locked', 'policyId',
  'jurisdictionId', 'totalPremium', 'status', 'underwritingStatus',
  'priorPolicyId', 'underwritingId', 'policyCFId',
  'policyCommercialCFId', 'jurisdictionCFId',
  'jurisdictionGLId', 'policyGLId', 'policyCommercialGLId',
  'aggLiabilityClasses', 'blanketCoverages', 'programCode',
  'scoredLocations', 'underwritingTriggers', 'totals', 'modified',
  'policyNeedsUnderwritingInformation', 'answeredEligibility', 'underwritingReason',
  'isGl', 'proposalId', 'policyNumber', 'wildfire', 'propertyManagerId',
  'emptyAddressId', 'remainingFields', 'files', 'ratingJobId', 'quoteKey', 'quoteInfo',
  'lastRating'
].map(key => {
  let f = {key, type: 'data'};
  if (['ratingJobId', 'quoteKey', 'quoteInfo', 'lastRating'].includes(key)){
    f.tags = 'hasExternalSource';
  }
  return f;
});




