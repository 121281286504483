<template>
<div class="get-quote pb-5">
  <div class="page-header d-flex">
    <Logo class="page-header-logo"/>
    <h1>BROKER PORTAL
      <span v-if="!quoteId">|| Get A Quote</span>
    </h1>
  </div>
  <div class="get-quote-head-bg">
    <div v-if="!bindQuotePage" class="d-flex quote-header">
      <div class="quote-header-detail" v-if="itemVal('quote.modified')">
        <div class="quote-header-detail-propertyName">{{ itemVal('customer.accountName') }}</div>
        <div class="quote-header-detail-info">
          <span class="quote-header-detail-info-key">Quote: </span>{{ itemVal('quote.quoteNumber') }}
          <div class="quote-header-detail-info-popover small" id="pop-quote-info" role="button">
            <i class="fas fa-info-circle" :style="{color:lioTheme.navy}"></i>
          </div>
          <div v-if="isAdmin" class="quote-header-detail-info-button" role="button" @click="getRateTierReport()" v-b-tooltip.hover="'Retrieve Rate Tier report'">
            <span>(</span>UW Info<span>)</span>
          </div>

        </div>
        <div class="quote-header-detail-info"><span class="quote-header-detail-info-key">Policy Effective Date: </span>{{ mmddyyyy(itemVal('policy.effective')) }}</div>
        <!--      <div class="quote-header-detail-info"><span class="quote-header-detail-info-key">Broker: </span>[Broker's Name]</div>-->
        <div v-if="itemVal('quote.status')" class="quote-header-detail-info">
          <span class="quote-header-detail-info-key">Status: </span>{{ statusText }}
          <a v-if="adminUWInfo" id="underwritingReasons">
            <i class="fas fa-info-circle" :style="{color:lioTheme.navy}"></i>
          </a>
        </div>
        <b-popover target="underwritingReasons" triggers="hover focus" variant="navy" v-if="adminUWInfo">
          <template #title>UW Referral Reasons</template>
          <div class="quote-info">
            <ul>
              <li v-for="(reason,ri) in adminUWInfo" :key="ri+'reason'">{{reason}}.</li>
            </ul>
          </div>
        </b-popover>
        <b-popover target="pop-quote-info" triggers="hover focus" variant="navy">
          <template #title>Quote Account Detail</template>
          <div class="quote-info">
            <ul style="list-style-type: none;">
              <li><strong>Name:</strong> {{ itemVal('customer.accountName') }}</li>
              <li><strong>Email:</strong> {{ itemVal('customer.email') }}</li>
              <li><strong>Address:</strong>{{ customerAddress }}</li>
              <li v-if="itemVal('customer.phone')"><strong>Phone:</strong> {{ itemVal('customer.phone') }}</li>
              <li><strong>Last Modified:</strong> {{ itemVal('quote.modified').toLocaleDateString() }}</li>
            </ul>
          </div>
        </b-popover>


      </div>
      <div class="quote-header-estimate">
        <div class="quote-header-estimate-bubble price-bubble d-flex" v-if="isType.snum(itemVal('quote.totalPremium'))">
          <div class="bg-gray text-center">
            <strong>ESTIMATED<br>PREMIUM</strong>
          </div>
          <div class="flex-fill">
            <h2 class="text-center">
              <sup><small>$</small></sup>{{
                currency(itemVal('quote.totalPremium')).replace('$', '').split('.')[0]
              }}
            </h2>
          </div>
        </div>
        <!--<div class="quote-header-estimate-info">
          <span class="quote-header-estimate-info-key">Policy Effective Date: </span>
          {{ mmddyyyy(itemVal('policy.effective')) }}
        </div>-->
      </div>

      <rate-tier-report
          :show-modal="showReport" :is-fetching="isFetchingReport"
          :report="rateTierReport" @reportClosed="showReport=false"
      />
    </div>
    <div class="d-flex step-indicator">
      <template v-for="(bc,i) in breadcrumbs">
        <div
            class="step-dot" :lbl="titleCased(bc)" :key="`step${i}`"
            :class="{

              completed:bcIndex > i,
              active: breadcrumb && breadcrumb.toLowerCase() === bc
            }"
          ></div>
          <div
            class="step-seg" :key="`seg${i}`"
            v-if="i < breadcrumbs.length-1"
            :class="{completed:bcIndex > i}"
        ></div>
      </template>
    </div>
  </div>
  <b-alert :dismissible="true" v-model="showError" variant="danger">{{errorMessage}}</b-alert>
  <quote-step v-if="render" :outerError="bannerlessError"/>
</div>
</template>

<script>
import Logo from '@/components/svgs/logo';
import QuoteStep from '@/components/quote-step';
import {mapGetters, mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import {progressModal} from '@/lib/modal-service';
import { datadogLogs } from '@datadog/browser-logs';
import {lookupError} from '@/lib/error';
import {debounce} from '@/lib/util';
import RateTierReport from '@/components/modal/rate-tier-report';


export default {
  data(){
    return {
      bannerlessError: null,
      showError: false,
      errorMessage: null,
      bg: '#fff',
      render: true,
      unlock: null,
      rateTierReport: [],
      showReport: false,
      isFetchingReport: false
    };
  },
  computed: {
    ...mapState('getQuote', ['pages', 'navigationPath', 'retryOnNextError']),
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain']),
    routeName(){
      return this.$route.name;
    },
    adminUWInfo(){
      if(this.isAdmin){
        let uw = this.itemVal('quote', 'underwritingReason');
        if (uw) {
          let reasons = Array.isArray(uw) ? uw.map(reason => reason.description) : [uw.description];
          let filterReasons = Object.fromEntries(reasons.map(r => [r, r]));
          return Object.keys(filterReasons);
        }
      }
      return null;
    },
    bindQuotePage() {
      return this.pageKey === 'bind-quote';
    },
    pageKey(){
      return this.$route.name === 'quote' ?
        this.navigationPath.length ? this.navigationPath[this.navigationPath.length - 1] : 'scope' :
        this.$route.params.step ?? 'details';
    },
    page(){
      return this.pages[this.pageKey];
    },
    customerAddress() {
      return this.itemFromChain('customer.address')?.address;
    },
    statusText(){
      let status = this.pageKey === 'issuePolicy' ? 'bound' : this.itemVal('quote.status');
      if (status.includes('ancel')){
        status = 'Canceled';
      }
      return this.titleCased(status).replace('-', '');
    },
    quoteId(){
      return this.$route.params.quoteId;
    },
    breadcrumb(){
      return this.page?.breadcrumb ?? '';
    },
    breadcrumbs(){
      let bc = {};
      Object.values(this.pages).forEach(({breadcrumb}) => {
        if (breadcrumb) {
          bc[breadcrumb.toLowerCase()] = true;
        }
      });
      return Object.keys(bc);
    },
    bcIndex(){
      let crumb = this.page?.breadcrumb || '';
      return this.breadcrumbs.findIndex(bc => crumb && bc === crumb.toLowerCase());
    }
  },
  methods: {
    ...mapMutations('getQuote', ['resetSteps', 'setAny']),
    getRateTierReport() {
      this.showReport = true;
      this.isFetchingReport = true;
      const quoteId = this.quoteId;
      this.lioApi('getRateTierReport', null, {quoteId: quoteId}).then(result => {
        this.rateTierReport = result?.response;
      }).catch(result => {
        console.error('Error retrieving Rate Tier report', result);
      }).finally(() => {
        this.isFetchingReport = false;
      });
    }
  },
  name: 'Quote',
  components: { RateTierReport, QuoteStep, Logo},
  watch: {
    debug(d){
      console.log({debug: d});
    },
    routeName(name, old){
      //this blocks navigation and resets the quote state
      if (name !== old && name === 'quote'){
        this.render = false;
        this.resetSteps();
        this.delayFn(() => this.render = true);
      }
    },
    quoteId(id){
      if (id){//quoteId will be null during beforeDestroy event. cache it to
        //release object lock
        this.unlock = id;
      }
    }
  },
  mounted() {
    this.resetSteps(true);
    if (this.quoteId){//quoteId will be null during beforeDestroy event. cache it to
      //release object lock
      this.unlock = this.quoteId;
    }

    if (this.$route.params?.step === 'customize'){
      let params = this.$route.params;
      params.step = 'details';
      this.$router.replace(`/quote/${this.quoteId}/details`);
    }
    // const throttledLog = debounce((...args) => datadogLogs.logger.error(...args), 20);
    //const throttledLog = (...args) => datadogLogs.logger.error(...args); comment out per lint error
    const errHandler = (response, src) => {
      let error = response.error || lookupError(response);
      if (error) {
        const [, domain] = this.oktaUser.email.split('@');
        const throttledLog = debounce((...args) => datadogLogs.logger.error(...args), 20);
        throttledLog(`API Error - ${error.message}`, {
          usr: {
            name: this.oktaUser.name,
            email: this.oktaUser.email,
            domain: domain,
            locale: this.oktaUser.locale,
            roleId: this.oktaUser.userRole,
            partnerId: this.oktaUser.partnerId
          },
          context: {
            source: 'Portal',
            type: `${src}Error`,
            operation: response.requestPayload.operation
          },
          response: {
            ...(response ?? {}),
            error,
            requestPayload: {
              ...(response.requestPayload ?? {}),
              OS_TOKEN: 'REDACTED'
            }
          }
        });
      }else{
      console.warn('oneShield error without viable error', {response});
    }

    this.setAny({error});
    if (this.retryOnNextError){
      if (this.retryOnNextError[0] === error?.operation){
        console.log(`retrying ${error.operation} silently once`);
        this.retryOnNextError[1]();
      }
      this.setAny({retryOnNextError: null});
      return;
    }

    this.errorMessage = error.message ?? 'The portal encountered an unknown error. Contact support: 877.546.7155 or service@lioinsurance.com (M-F, 8:30AM - 8:00PM EST).';
    this.showError = !error?.knownError?.hideBanner;
    this.bannerlessError = error?.knownError?.hideBanner ? error.knownError : null;
    //debugger
      if (error?.knownError?.toast){
        this.$bvToast.toast(error.message, {
          id: 'app-error',
          title: 'Error Encountered',
          noAutoHide: true,
          variant: 'danger',
          solid: true,
          toastClass: 'not-auth-toast',
          toaster: 'b-toaster-bottom-right',
          appendToast: false
        });
      }
    progressModal(false);
  };
        eventbus.$on('oneShieldError', (response) => errHandler(response, 'OneShield'));
        eventbus.$on('apiError', (response) => errHandler(response, 'LioApi'));

  },
  beforeDestroy() {
    if (this.unlock) {
      this.oneShield('releaseObjectLock', {contextId: this.unlock});
    }
    this.resetSteps(true);
    eventbus.$emit('progressToast', false);
    progressModal(false);
    eventbus.setActiveQuote(null);
  }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";


div.quote-info{
  *{color:#eee}
  ul {
    padding-left: 15px;
  }
}


.get-quote{
  position:relative;
  padding: 0;

  .alert {
    padding-bottom: 8px;
    margin: -9px 0 0 6px;
  }
  &-head-bg{
    position: relative;
    background-color: $gray-light;
    border-bottom-left-radius: 180px;
    height: 190px;
    left: 34px;
    margin-bottom: 10px;
  }

  .page-header {
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 1;
    &-logo {
      margin-right: 1rem;
    }
    h1 {
      display: none;
      line-height: 94px;
      margin: 0;
      font-size: 2rem;
      font-weight: 500;

      @media(min-width: 1250px) {
        display: block;
      }
    }
  }

  .quote-header {
    position: absolute;
    top: 34px;
    left: 50%;
    transform: translateX(-50%);

    &-detail {
      &-propertyName {
        font-size: 1.1rem;
      }

      &-info {
        font-size: .8rem;

        &-key {
          color: gray;
        }

        &-popover {
          display: inline;

          i {
            margin-right: 10px;
            margin-top: 2px;
          }
        }

        &-button {
          display: inline;
          color: $primary;
          font-weight: 600;
          font-size: .8rem;

        }
      }
    }

    &-estimate {
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-left: 50px;

      &-bubble {
        width: auto;
        height: 56px;
        border: solid 1px $gray;
        border-radius: 6px;
        background-color: white;

        .bg-gray {
          border-right: solid 1px #1c2d40;
          background-color: #1c2d40;
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          padding: 14px 10px;

          strong {
            color: white;
            font-size: .7rem;
            line-height: 1.4;
          }
        }

        strong {
          display: block;
          line-height: 1.1;
          font-size: 16px;
        }

        h2 {
          margin: 13px 10px;
          white-space: nowrap;
          min-width: 105px;
        }
      }
      &-info {
        margin: 6px 0 0 0;
        font-size: 0.8rem;
        text-align: center;

        &-key {
          color: gray;
        }
      }
    }
  }

  .step-indicator{
    position:absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;

    .step-seg{
      position:relative;
      width:125px;
      height:1px;
      background-color: $gray;
      margin-bottom: .5px;
      &.completed{
        margin-bottom: 1px;
        height:3px;
        background-color: #222;
      }
    }
    .step-dot{
      position:relative;
      top:-4px;
      height:10px;
      width:10px;
      border:solid 1px $gray;
      background-color: $gray-light;
      border-radius: 50%;
      &:after{
        font-size:13px;
        width:130px;
        position:absolute;
        content:attr(lbl);
        text-transform: uppercase;
        left:-60px;
        top:18px;
        text-align: center;
        color:$gray;
      }
      &.completed{
        background-color: #222;
        border-color:#222;
      }
      &.active,&.completed{
        &:after{
          color:#222;
        }
      }
      &.active{
        border: solid 3px #C92D39;
        background-color: white;
        box-shadow: 0 2px 5px $gray;
        z-index: 2;
        width:15px;
        height:15px;
        margin:-3px;
      }

    }
  }
}
</style>
