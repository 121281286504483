<template>
  <div class="tree" :class="{saving}">
    <div>
      <tri-check
          :eventsOnly="true"
          v-model="allItems" size="md" @input="selectAll"
          v-if=" locations.length>1 && selectionMode" class="pt-2 pb-2 tree-node"
          :class="{selected:allItems,partial:isType.nullOrUndef(allItems)}">
        <div class="d-flex">
          <div class="mr-2">
            <b-badge v-if="buildingFilter  && selectedChains.length" :variant="aggregateFieldState().variant">
              {{ aggregateFieldState().valid }}/{{ aggregateFieldState().total }}
            </b-badge>
          </div>
          <h3 class="text-primary pt-0 mb-0 flex-fill">All Locations</h3>

        </div>
      </tri-check>
      <h3 class="text-primary pt-1 mb-2 ml-2" v-else>Locations</h3>
    </div>
    <div v-for="([item,chain]) in locations.filter(l=>!l.isOPO).map(l=>[l,l.chain])" :key="chain" class="loc-div" :class="{'invalid-loc':!validLocation(item.i)}">
      <a @click="collapsed[chain] = !collapsed[chain]" class="collapser"
         v-if="Object.keys(item.buildingList).length && !isPseudo(item.i)">
        <i class="fas fa-caret-right" v-if="collapsed[chain]" v-b-tooltip.hover="'Expand Buildings'"></i>
        <i class="fas fa-caret-down" v-if="!collapsed[chain]" v-b-tooltip.hover="'Collapse Buildings'"></i>
      </a>
      <tri-check

          v-if="!isPseudo(item.i) && selectionMode"
          v-model="item.selected" size="md"
          @input="locationSelection(item.buildingList, ...arguments)"
          class="tree-node" :disabled="!item.buildingList.length"
          :class="{selected:selection[chain],partial:isType.nullOrUndef(selection[chain])}">
        <div class="location-lbl">
          <div class="d-flex">
            <div class="mr-2" v-if="validLocation(item.i) && !isPseudo(item.i) && buildingFilter">
              <b-badge :variant="aggregateFieldState(item.i).variant" v-if="selectedChains.length">
                {{ aggregateFieldState(item.i).valid }}/{{ aggregateFieldState(item.i).total }}
              </b-badge>
            </div>
            <i
                v-else-if="!validLocation(item.i)"
                class="fas fa-info-circle"
                v-b-tooltip.hover="`Choose what to insure at this location (buildings, outdoor property, or both)`"
            ></i>
            <component
                :is="isPseudo(item.i) ? 'em' : 'span'" class="flex-fill"
                @mousedown.cancel="isPseudo(item.i) ? uncheckOthers() : noop()">
              <strong>{{ item.name }}</strong>
            </component>


          </div>
          <div class="ellipsize" style="max-width:250px;text-transform: uppercase"
               :style="{fontStyle:isPseudo(item.i) ? 'italic':''}">
            {{ item.address }}
          </div>

        </div>
      </tri-check>

      <div
          class="ml-4 tree-node building"
          :class="{
              selected:selectionMode && itemFromChain(bchain).selected && bchain!==editBuildingChain,
              'd-none':collapsed[chain],
              'd-flex':!collapsed[chain],
              editing: editBuildingChain !== null,
              highlight: confirmRemoveBuilding === bchain && !b.deleted,
              deleting: deleteBuildingChain === bchain,
            }"
          v-for="([b,bchain]) in isPseudo(item.i) ? [] : item.buildingList.map(b=>[b,b.chain])" :key="bchain">
        <a class="edit-building" v-if="canAddRemove && editBuildingChain === null">
          <i class="fas fa-pencil-alt mr-2" @click="editBuildingTypeLabel(bchain)"
             v-b-tooltip.bottom="`Edit Building Type Label`"></i>
          <i class="fas fa-trash" @click="confirmRemoveBuilding = bchain" tabindex="0"
             v-b-tooltip.bottom="`Remove this Building Type`"></i>

        </a>
        <b-popover v-if="confirmRemoveBuilding === bchain && !b.deleted" :show="confirmRemoveBuilding === bchain"
                   placement="rightbottom" :target="`confirmDelete${bchain}`"
                   :title="`Delete ${itemVal(`${bchain}.name`)}?`" triggers="focus,click">

          <div v-if="item.buildingList.length === 1">
            <div v-if="!item.dataTree.miscFurnCount">
              <p style="color:white">Each location must contain at least one insured building or insured outdoor
                property. </p>
              <p style="color:white">Would you like to change the location to an outdoor-property-only location and add
                outdoor property now?</p>
              <hr>
              <div class="text-right">

                <b-button
                    size="sm" variant="primary"
                    @click="confirmRemoveBuilding = null">NO (CANCEL)
                </b-button>
                <b-button size="sm" variant="info" class="mr-2" @click="setPropOnly(item.chain)">YES (CHANGE)</b-button>
              </div>
            </div>
            <div v-else>
              <p style="color:#eee">Do you want to remove all building types and change location to outdoor property
                only?</p>
              <hr>
              <div class="text-right">
                <b-button size="sm" variant="info" class="mr-2" @click="confirmRemoveBuilding = null">NO (CANCEL)
                </b-button>
                <b-button
                    size="sm" variant="primary"
                    @click="deleteBuilding(bchain, b)">YES (CHANGE)
                </b-button>
              </div>
            </div>
          </div>
          <div v-else>
            <p style="color:#eee">Do you want to remove this building type?</p>
            <hr>
            <div class="text-right">
              <b-button size="sm" variant="info" class="mr-2" @click="confirmRemoveBuilding = null">CANCEL</b-button>
              <b-button
                  size="sm" variant="primary"
                  @click="deleteBuilding(bchain, b)">DELETE
              </b-button>
            </div>
          </div>

        </b-popover>
        <template v-if="bchain===editBuildingChain">
          <div class="add-building edit">
            <b-input-group>
              <b-input
                  @keypress.enter="editBuildingTypeLabel(true)"
                  @keyup.esc="editBuildingTypeLabel(false)"
                  v-model="editBuildingName" autofocus
                  @focus="$event.target.select()"
              />
              <b-input-group-append>
                <b-button class="pr-2 pl-2" @click="editBuildingTypeLabel(false)">
                  <i class="fas fa-times text-danger"></i>
                </b-button>
                <b-button class="pr-2 pl-2" @click="editBuildingTypeLabel(true)"><i
                    class="fas fa-check text-success"></i></b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
        </template>
        <template v-else>
          <b-check
              v-if="selectionMode"
              v-model="itemFromChain(bchain).selected"
              @input="buildingSelection({chain: bchain,loc:item}, ...arguments)"
              class="flex-fill">
            <b-badge v-if="buildingFilter &&  selectedChains.length" :variant="formState(b, false).variant">
              {{ formState(b, true) }}
            </b-badge>
            <a class="ml-1"
               @mousedown.cancel.prevent="uncheckOthers(bchain, true)"
               @click.cancel.prevent="noop"
               :id="`confirmDelete${bchain}`"
            >{{ b.name }}</a>
          </b-check>

          <label v-else class="flex-fill">
            {{ b.name }}
          </label>
          <strong
              v-if="bVal(item.i, b.bi, 'identicalCt')"
              class="mr-2 ident-ct">{{ bVal(item.i, b.bi, 'identicalCt') }}</strong>
          <i :class="typeIcon(item.i, b.bi)" v-if="typeIcon(item.i, b.bi)" class="pr-2 type-icon"></i>
        </template>
      </div>
      <div class="add-building" v-if="canAddRemove && !isPseudo(item.i)">
        <b-input-group v-if="addingBuilding.li === item.i">

          <b-input
              v-model="addingBuilding.typeLabel" autofocus
              @focus="$event.target.select()"
              @keypress.enter="addBuilding(true)"
              @keyup.esc="addBuilding(false)"
          />
          <b-input-group-append>
            <b-button class="pr-2 pl-2 " @click="addBuilding(false)">
              <i class="fas fa-times text-danger"></i>
            </b-button>
            <b-button class="pr-2 pl-2" :disabled="!addingBuilding.typeLabel" @click="addBuilding(true)">
              <i class="fas fa-check text-success"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-button size="sm" v-if="inlineAddRemove && isType.nullOrUndef(addingBuilding.li)"
                  @click="addBuilding(item.i)">
          <i class="fas fa-plus"></i> Add Building
        </b-button>

        <b-button
            size="sm" variant="info" class="ml-2" @click="setPropOnly(item.chain)"
            v-if="!(locations[item.i].buildingList.length)"
            :style="{background:lioTheme.blue}">
          Outdoor Property Only
        </b-button>
        <b-button class="ml-2"
          size="sm"  v-else-if="editOutdoorProp"
          @click.cancel.prevent="editMiscProp(chain)">
          <i class="fas fa-plus" v-if="!itemVal(chain, 'miscFurnCount')"></i>
            Outdoor Property
            <span v-if="itemVal(chain, 'miscFurnCount') > 0">({{ itemVal(chain, 'miscFurnCount') }})</span>

        </b-button>
      </div>
    </div>

    <div v-if="!buildingMode && pseudoLocations.length" class="prop-only-location">
      <hr>
      <h3 class="text-primary pt-1 mb-2 ml-2">Outdoor Property Only Locations</h3>
      <ul class="ml-2" style="list-style: none">
        <li v-for="l in pseudoLocations" :key="l.chain">
          <div class="d-flex">
            <strong class="flex-fill">{{ l.name }}</strong>
            <b-button size="sm"
                v-if="editOutdoorProp"
                @click.cancel.prevent="revert1190(l)" class="pr-3 mr-1">

                <i class="fas fa-plus"></i> Building

            </b-button>
            <b-button size="sm" v-if="editOutdoorProp" @click="outdoorLocation = l">
              Outdoor Property ({{l.child('miscFurnCount').val}})
            </b-button>

          </div>
          <div class="ellipsize" style="max-width:288px" :style="{fontStyle:'italic'}">
            {{ itemVal(`${l.chain}.full-address`) }}
          </div>
        </li>
      </ul>
    </div>
    <outdoor-property :location="outdoorLocation" @closed="savedOutdoorProp"/>

  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import {bldgClassTypes} from '@/lib/fields/field-constants';
import TriCheck from '@/components/util/tri-check';
import eventbus from '@/lib/eventbus';
import {buildingForm} from '@/lib/mix';
import OutdoorProperty from '@/components/modal/outdoor-property';
import allFields from '@/lib/fields/fields';


export default {
  components: {OutdoorProperty, TriCheck},
  data: () => {
    return {
      addingBuilding: {
        li: null,
        typeLabel: 'building type'
      },
      deleteBuildingChain: null,
      confirmRemoveBuilding: null,
      editBuildingName: null,
      editBuildingChain: null,
      inlineAddRemove: true,
      ignoreSelection: null,
      ignoreBubble: false,
      collapsed: {},
      classTypes: bldgClassTypes,
      constructionTypes: [],
      outdoorLocation: null,
      stickySelection: []
    };
  },
  computed: {
    ...mapState('getQuote', ['saving', 'navigationPath']),
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain', 'quoteBuildings', 'buildings', 'flatFieldList']),
    canAddRemove() {
      const route = this.$route;
      return route.params.step === 'details';
    },
    allItems() {
      let selected = this.buildings.filter(b => b.selected);
      let all = selected.length === this.buildings.length;
      let none = !selected.length;
      return all ? true : none ? false : null;
    },
    pseudoLocations() {
      return this.locations.filter(l => l.isOPO);
    },
    validLocation() {
      return () => true;//this.locations[li].buildingsDef?.children?.length ?? 0 > 0;
    },
    selection() {

      let bSel = this.quoteBuildings.map(item => [item.chain, item.selected]);
      let lSel = this.locations.map(l => [l.chain, this.lSelected(l)]);
      return Object.fromEntries([...lSel, ...bSel]);
    },
    emptyLocSelection(){
      let empty = this.locations.filter(l => l.buildingList.length === 0);
      return empty.map(l => `${l.key}:${l.selected}`).join(' | ');
    },
    selectedChains() {
      return this.selection ? Object.entries(this.selection)
        .filter(([, val]) => val).map(([chain]) => chain) : [];
    },
    lSelected() {
      return l => {
        let vals = l.buildingList.map(b => b.selected);
        let none = vals.filter(v => v).length === 0;
        let all = vals.filter(v => !v).length === 0;
        return all ? true : none ? false : null;
      };
    },
    readOnly() {
      return this.buildingMode;
    },

    isPseudo() {
      return li => {
        return !!this.locations[li].isOPO;
      };
    },
    buildingTypes() {
      return li => this.locations[li].buildingList;
    },
    bVal() {
      return (li, bi, key) => {
        try {
          return this.locations[li].buildingList[bi].dataTree[key];
        } catch (ex) {
          console.warn({bVal: ex});
          return null;
        }
      };
    },

    bldg() {
      return (li, bi, key) => {
        try {
          let b = this.locations[li].buildingList[bi];
          return b.child(key);
        }catch(ex){
          return null;
        }
      };
    },

    typeIcon() {
      return (li, bi) => {
        try {
          let {vals, val} = this.bldg(li, bi, 'bClass');
          let {icon} = vals.find(({key}) => key === val);
          return icon;
        } catch (ex) {
          return null;
        }
      };
    }
  },
  methods: {
    ...mapMutations('getQuote', ['setAny', 'removeBType', 'updateField', 'addFieldDefs']),
    async setPropOnly(locationChain) {
      let location = this.itemFromChain(locationChain);
      this.confirmRemoveBuilding = null;
      let firstBuilding = location.buildingList[0];
      if (!firstBuilding) {
        firstBuilding = this.addBuilding(location.li, 'opo');
      }
      firstBuilding.isPseudo = true;
      this.uncheckOthers();
      this.outdoorLocation = location;
    },
    deleteBuilding(bchain) {
      this.deleteBuildingChain = bchain;
      let location = this.locations[this.itemFromChain(bchain).li];
      //abcd
      let hasPseudo = !!location.dataTree.miscFurnCount;
      let lastBuilding = location.buildingList.length === 1;

      this.setSelected(bchain, false);
      this.delayFn(() => {
        let building = this.itemFromChain(bchain);
        if (hasPseudo && lastBuilding) {
          return building.isPseudo = true;
        }
        this.updateField({chain: `${bchain}._delete`, val: true});
        building.setKey(`_delete_${building.key}`);
        let endIndex = this.buildings.length;
        let i = 0;
        const reKey = (k, n) => {
          let keySplit = k.split('-');
          keySplit.splice(keySplit.length - 1, 1, n);
          return keySplit.join('-');
        };
        this.buildings.filter(b => b.li === building.li).forEach(b => {
          let bi = i;
          if (b.key.startsWith('_del')) {
            endIndex++;
            b.setKey(reKey(b.key, endIndex));
          } else {
            let newKey = reKey(b.key, bi + 1);
            if (b.key !== newKey) {
              b.setKey(newKey);
              b.bi = bi;
            }
            i++;
          }
        });
        this.confirmRemoveBuilding = null;
        this.deleteBuildingChain = null;
      }, 395);
    },
    editBuildingTypeLabel(chain) {
      if (this.isType.string(chain)) {
        this.editBuildingChain = chain;
        this.editBuildingName = this.itemVal(`${chain}.name`);
      } else {
        if (chain === true) {
          chain = `${this.editBuildingChain}.name`;
          let val = this.editBuildingName;
          this.updateField({chain, val});

        }
        this.editBuildingChain = null;
        this.editBuildingName = null;
      }
    },
    addBuilding(li, label) {
      const setBuilding = (location) => {
        let {locationId, addressDef: {addressId}} = location.dataTree;
        let bi = location.buildingList.length;
        let name = label ?? this.addingBuilding.typeLabel;
        let fields = {locationId, addressId, name};
        let buildingDef = allFields.buildingDefs(location.li, bi, this.addingBuilding.typeLabel);

        buildingDef.treeVals = fields;

        location.addBuilding(buildingDef);

        this.setSelected(buildingDef.chain, true);
        return buildingDef;
      };

      if (this.isType.bool(li)) {
        if (li === true && this.addingBuilding.typeLabel) {
          setBuilding(this.locations[this.addingBuilding.li]);
        }
        this.addingBuilding.li = this.addingBuilding.typeLabel = null;
        this.inlineAddRemove = true;
      } else if (label) {
        return setBuilding(this.locations[li]);
      } else {
        this.addingBuilding.li = li;
        this.addingBuilding.typeLabel = `Building Type ${this.buildingTypes(li).length + 1}`;
        this.inlineAddRemove = false;
      }
    },
    editMiscProp(chain) {
      this.outdoorLocation = null;
      this.delayFn(() => this.outdoorLocation = this.itemFromChain(chain));
    },
    savedOutdoorProp(){
      this.outdoorLocation = null;
      eventbus.$emit('savedOutdoorProperty');
    },
    revert1190(location){
      this.confirmRemoveBuilding = null;
      this.deleteBuildingChain = null;
      let b1190 = location.buildingsDef.children.find(b => b.isPseudo);
      if (b1190) {
        b1190.isPseudo = false;
      }else{
        console.warn({revert1190: b1190, location});
      }
    },
    uncheckOthers(keep, ensureSelection) {
      Object.keys(this.selection).forEach(chain => {
        if (chain.includes('bui')) {
          this.setSelected(chain, chain === keep);
        }
      });
      if (ensureSelection) {
        this.ignoreSelection = keep;
        this.delayFn(() => this.ignoreSelection = null, 1000);
      }
    },
    locationSelection(buildings, selected) {
      this.selectionHandler(() => {
        buildings.forEach(b => {
          this.setSelected(b.chain, selected);
        });

      });
    },
    selectionHandler(fn) {
      if (this.ignoreBubble) {
        return;
      }
      this.ignoreBubble = true;
      fn();
      Object.entries(this.selection).forEach(([chain, val]) => {
        this.setSelected(chain, val);
      });

      this.delayFn(() => {
        let realSelected = this.quoteBuildings.find(b => b.selected && !b.isPseudo);

        if (realSelected) {
          this.quoteBuildings.forEach(b => {
            if (b.isPseudo) {
              this.setSelected(b.chain, false);
              this.setSelected(b.locChain, false);
            }
          });
        }
        this.ignoreBubble = false;
      });
    },

    buildingSelection({loc, chain}, selected) {
      this.setSelected(chain, selected);
      this.selectionHandler(() => {

        let same = Object.keys(loc.buildings).every(c => this.selection[c] === selected);
        //console.log({building: chain. same, selected});
        let select = same ? selected : null;
        this.setSelected(loc.chain, select);

      });
    },
    selectAll() {
      let val = !this.allItems;

      //this.locations.forEach(l => this.selection[l.chain] = val);
      this.quoteBuildings.forEach(b => this.setSelected(b.chain, val));
    },
    noop() {
    },

    buildTree() {
      this.collapsed = Object.fromEntries(
        this.locations.map(l => {
          if (l.buildingList.length === 0){
            this.setSelected(l.chain, false);
          }
          return [l.chain, false];
        })
      );
    },
    setSelected(chain, selected) {
      if (chain === this.ignoreSelection) {
        return;
      }
      let item = this.itemFromChain(chain);
      if (!item){
        console.warn({setSelected: `${chain}:${selected}`, selection: this.selection});
        return;
      }
      if (item.isLocation && selected && !item.buildingList.length){
        selected = false;
        this.selection[chain] = false;
      }
      item.selected = selected;
      this.updateField({chain, props: {selected}});
    },
    selectFirst() {

      if (!this.buildings.length){
        return;
      }
      this.delayFn(() => {
        if (!Object.entries(this.selection).find(([chain, selected]) => selected === true && chain.includes('building'))) {
          this.uncheckOthers(this.buildings[0].chain);
          this.setSelected(this.buildings[0].chain, true);
          Object.entries(this.selection).forEach(([chain, selected]) => {
            let item = this.itemFromChain(chain);
            if (item.isLocation && selected && !item.buildingList.length){
              this.selection[chain] = false;
              this.setSelected(chain, false);

            }
          });
        }
      });
    }

  },
  mounted() {
    this.buildTree();
    this.selectFirst();
    eventbus.$on('quoteRehydrateStart', () => {
      this.stickySelection = [
        ...this.locations.map(l => [l.chain, l.selected]),
        ...this.quoteBuildings.map(b => [b.chain, b.selected])
      ];
    });
    eventbus.$on('quoteRehydrate', () => {
      this.delayFn(() => {
        if (this.stickySelection.length) {
          this.stickySelection.forEach(([chain, selected]) => {
            this.updateField({chain, props: {selected}});
          });
        }else{
          this.selectFirst();
        }
      }, 10);
    });
  },
  beforeDestroy() {
    this.selectFirst();
  },
  props: ['selectionMode', 'buildingMode', 'editOutdoorProp', 'newQuote', 'resetSelection'],
  name: 'building-selector',
  watch: {

    selectionMode() {
      this.buildTree(Object.values(this.selection || {}).filter(v => v).length === 0);
      this.selectFirst();
    },

    buildingMode(flag) {
      if (!flag) {
        this.selectFirst();
      }
    }
  },
  mixins: [buildingForm]
};
</script>
<style lang="scss">
@use 'sass:color';
@import "../../../assets/scss/variables";

.tree.saving {
  opacity: .65;

  * {
    pointer-events: none !important;
  }
}
.tree {
  .btn {
    background: $gray !important;
  }

  .loc-div {
    &.invalid-loc {
      div.tri-check.tree-node {
        border-color: $gray;
        background: fade-out($gray, .75) !important;

        label.custom-control-label {
          &:before, &:after {
            display: none;
          }
        }

        i {
          position: absolute;
          top: 12px;
          left: -23px;
          color: $danger;
        }
      }
    }

    .tri-check.tree-node {
      .custom-control {
        max-height: 27px;

        label {
          max-height: 28px;
        }
      }
    }

    .tree-node.building {
      position: relative;

      &.deleting {
        background: fade-out($danger, .5) !important;
        height: 0;
        opacity: 0;
        margin-top: -5px !important;
        overflow: hidden;
        transition-delay: .1s;
        transition-duration: .3s;

        a.edit-building {
          display: none;
        }
      }

      a.edit-building {
        position: absolute;
        left: -48px;
        top: -1px;
        background: lighten($info, 10%);
        display: inline-block;
        padding: 5px 20px 0 6px;
        height: 34px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border: inherit;
        border-right: 0;
        border-left-width: 2px;
        opacity: 0;
        transition: 0s opacity;

        i {
          pointer-events: none;
        }
      }

      &.highlight {
        a.edit-building {
          display: none;
        }
      }

      transition: opacity 0s, height 0s, margin-top 0s;
      height: 34px;
      //overflow: hidden;
      margin: 0px 0 5px 24px !important;

      &:hover, &.highlight {
        transition-delay: .25s;
        transition-duration: .25s;
        background: lighten($info, 10%) !important;

        &.editing {
          background: transparent;
        }

        a.edit-building {
          i {
            pointer-events: all;
          }

          opacity: 1;
          transition-delay: .25s;
          transition-duration: .25s;
        }
      }

    }

    .add-building {
      padding-left: 23px;

      &.edit {
        padding-left: 0;

        .input-group {
          width: 280px;
          position: relative;
          top: -5px;
        }
      }


      .input-group {
        input.form-control {
          outline: 0 !important;
          box-shadow: none !important;

          &:focus, &:active, &:focus-within {
            outline: 0 !important;
          }
        }

        .input-group-append {

          .btn {
            background: transparent;

            &:nth-child(2) {
              border-left: 0;
              //background: red !important;
            }
          }
        }
      }

    }
  }

  .prop-only-location {
    h3 {
      opacity: .6;
    }

    * {
      color: #777;

      small {
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
