<template>
  <div class="h-100 d-flex flex-column">
    <div class="flex-fill"></div>
    <div :class="slotClass" :style="slotStyle"><slot/></div>
    <div class="flex-fill"></div>
  </div>
</template>

<script>
export default {
  name: 'align-middle',
  props: ['slotClass', 'slotStyle']
};
</script>
