<template>
  <div class="d-flex ellipsize" :style="{width:width||'100%',maxWidth:width||'100%'}" :title="text">
    <span class="flex-fill text-left mr-2 ellipsize">{{ text }}</span><i class="text-right fas fa-caret-down pt-1"></i>
  </div>
</template>

<script>
export default {
  computed: {
    text(){
      let t = 'Select';
      if (this.yn){
        t = this.t === true ? 'Yes' : this.t === false ? 'No' : t;
      } else if (this.t){
        t = this.t;
      }
      return t;
    },
    width(){
      if (!this.w){
        return null;
      }
      return this.px(Number(this.w.replace('px', '')) - 33);
    }
  },
  name: 'drop100-text',
  props: ['t', 'yn', 'w']
};
</script>

<style lang="scss" scoped>
  .ellipsize{
    cursor:pointer;
    overflow-x:hidden;

  }
</style>
