<template>
  <div class="maintenance">
    <Liologo :size="150"/>
    <div class="h1-flex">
      {{ title }}
    </div>
      <div class="alert-panel">
        <div class="alert-heading">
          <Maintenanceicon :size="26"/>
          <span>{{ messageBody.heading }}</span>
        </div>
        <p style="width:600px">
          {{ messageBody.message }}
        </p>
        <a class="homeLink" href="https://lioinsurance.com">Continue to the LIO home page</a>
      </div>
  </div>
</template>

<script>
import {svgMix} from '@/lib/mix';
import Liologo from '@/components/svgs/lio-logo';
import Maintenanceicon from '@/components/svgs/maintenance-icon';

export default {
  data: () => {
    return {
      c1: 'rgba(158,210,204,.3)',
      bg: 'white'
    };
  },
  computed: {
    messageBody(){
      let [heading, message] = this.message.split('|');
      if (!message) {
        message = heading;
        heading = 'Sorry, we\'re down for maintenance';
      }
      return {
        message, heading
      };   
    }
  },
  components: {Liologo, Maintenanceicon},
  mixins: [svgMix],
  name: 'maintenance',
  props: ['title', 'message']
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";

#app {

  .maintenance {
    padding: 75px 15px 50px;
    background-color: #f4f4f1;
    min-height: 100vh;
    min-width: 350px;
    @media(min-width: 580px) {
      padding-top: 150px;
    }

    .h1-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0 20px;
      @media(min-width: 460px) {
        font-size: 2.00rem;
      }
      @media(min-width: 580px) {
        font-size: 2.75rem;
      }

      div {
        margin-right: 8px;
      }
    }
    
    svg {
      display: block;
      margin: 0 auto 50px;
    }

    .alert-panel {
      display: flex;
      flex-direction: column;
      margin: 50px auto 0;
      padding: 32px 20px;
      background-color: #ffffff;
      border-radius: 50px;
      box-shadow: inset 0 1px 0 1px #f4f4f1, 0 12px 25px #c6c6c6;
      width: 740px;
      max-width: calc(100% - 60px);
      @media(min-width: 460px) {
        padding: 32px 40px;
      }
      @media(min-width: 580px) {
        padding: 32px 70px;
      }

      .alert-heading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: top;
        margin-bottom: 16px;
        text-align: center;

        svg {
          margin: 5px 8px 0 0;
          flex-shrink: 0;
        }

        span {
          color: $coral;
          font-size: 1rem;
          font-weight: 600;
          text-transform: none;
          @media(min-width: 460px) {
            font-size: 1.6rem;
          }
        }
      }

      p {
        font-size: .9rem;
        font-weight: 300;
        line-height:1.3;
        max-width: 100%;
        text-align: center;
        @media(min-width: 460px) {
          font-size: 1.5rem;

        }
      }

      .homeLink {
        text-decoration: underline;
        font-size: .9rem;
        color: $coral;
        text-align: center;
        @media(min-width: 460px) {
          font-size: 1.1rem;
        }
        &:hover {
          text-decoration:none;
          color: #C54F43;
        }
      }
    }    
  }
}
</style>
