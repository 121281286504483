<template>
  <div style="min-height:300px">

    <div class="mb-3" v-for="({title,blanketType}) in blanketGroups(dataVersion)" :key="title">
      <h3>Blanket {{ title }} Coverages
        <b-button
            :disabled="saving"
            class="ml-4 quote-step-button" variant="primary"
            :id="`add${title}${blanketLength(blanketType)}`"
            :ref="`btn${title}${blanketLength(blanketType)}`"
        ><i class="fas fa-plus" style="color:white"></i> Add {{title}} Blanket
        </b-button>
      </h3>

      <bld-popop
          :title="title" :buildingData="buildingData"
          :index="blanketLength(blanketType)" :add="true"
          :blanket-type="blanketType"
          @toggleSelectAll="toggleSelectAll"
      />

      <div style="min-height: 80px">
        <b-table v-if="blanketList(blanketType).length"
                 small table-variant="secondary"
                 striped bordered style="width:700px"
                 :items="blanketList(blanketType)"
                 :tbody-tr-class="(item) => item.total ? 'plain' : 'zero'"
                 :fields="['blanket','total','buildings']">
          <template #cell(blanket)="data">
            <div>{{data.value}}</div>
          </template>
          <template #cell(total)="data">
            <span>
              <i class="fas fa-info-circle text-danger mr-2" v-if="!data.value"
                 v-b-tooltip.hover="'Blanket will only be included if total is greater than zero.'"></i>
              ${{currency(data.value,false, false)}}
            </span>
          </template>
          <template #cell(buildings)="data">
            <div class="d-flex">
              <div>
                <div v-for="(lname, i) in data.item.locations" :key="lname+i" :class="{invis:data.item.merge[i]}">
                  {{ lname || '.' }}&nbsp;
                </div>
              </div>
              <div class="ml-3">
                <div v-for="(bname, i) in data.item.buildings" :key="i+bname">{{ bname }}</div>
              </div>
              <div class="text-right flex-fill">
                <b-button
                    :disabled="saving"
                    variant="light" :style="{backgroundColor: lioTheme.gray}"
                    @click="removeBlanket(data.item.i,blanketType)" class="mr-1">
                  <i class="fas fa-trash"></i>
                </b-button>
                <b-button
                    :disabled="saving"
                    variant="light" :style="{backgroundColor: lioTheme.gray}"
                    :id="`add${title}${data.item.i}`">
                  <i class="fas fa-edit"></i>
                </b-button>
                <bld-popop
                    :title="title" :buildingData="buildingData"
                    :index="data.item.i" :blanketType="blanketType"
                    @checkEvent="checkEvent"
                    @toggleSelectAll="toggleSelectAll"
                />
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';
import {quotemix} from '@/lib/quotemix';

import TriCheck from '@/components/util/tri-check';
import {blanketMix} from '@/lib/mix';
import { isType } from '@/lib/mytype';

const bldPopop = Vue.component('blankiePop', {
  components: {TriCheck},
  data: () => {
    return {
      ind: null,
      vis: false,
      freeze: false,
      initializing: true
    };
  },
  methods: {
    mouse(b, bt){
      this.freeze = true;
      b[bt] = b[bt] === this.ind ? null : this.ind;
      this.delayFn(() => this.freeze = false, 333);
      this.$emit('checkEvent', this.target);
    },
    multiSelect(args){
      this.freeze = true;
      this.delayFn(() => this.freeze = false, 333);
      this.$emit('toggleSelectAll', args);
    }
  },
  computed: {
    target(){
      let {title, add, ind} = this;
      return `${add ? 'add' : 'edit'}${title}${ind}`;
    },
    blanketParentSelected() {
      return ({blanketType, blanketIndex, level, targetId}) => {
        const buildings = this.buildingData.filter(b => targetId === (level === 'loc' ? b.loc.li : b.parentId));
        const availableBuildings = buildings.filter(b => b[blanketType] === blanketIndex || !b[blanketType]);
        const allSelected = availableBuildings.every(b => !isType.nullOrUndef(b[blanketType]));
        const noneSelected = availableBuildings.every(b => isType.nullOrUndef(b[blanketType]));
        if (allSelected) {
          return true;
        } else if (noneSelected) {
          return false;
        } else {
          return null;
        }
      };
    },
    getBuildingName() {
      return (building) => {
        return building.identicalCt > 1 ? building.name : building.parentName;
      };
    }
  },
  mounted(){
    this.vis = true;
    this.ind = this.index;
    let target = `add${this.title}${this.ind}`;
    if (this.add) {
      this.$root.$on('bv::popover::hide', () => {
        this.vis = false;
      });
      this.$root.$on('bv::popover::show', popEvent => {
        let targetId = popEvent.target.id;
        if (targetId !== target && !this.freeze) {
          this.$root.$emit('bv::hide::popover', target);
          this.vis = false;
        } else {
          this.vis = true;
        }
      });
    }
    //let {blanketType, vis, freeze, ind} = this;
    this.delayFn(() => this.initializing = false, 500);
    //console.log({target: this.target, vis, freeze});
  },
  watch: {
    index(ind, prev){
      if ((this.add && !ind) || this.initializing || (!this.vis && !this.freeze)){
        this.ind = ind;

      }/*else {
        let {blanketType, vis, freeze, initializing} = this;
        console.log({blanketType, ind, vis, freeze, prev, initializing});
      }*/
    }
  },
  template: `
    <b-popover @hidden="ind = index" :target="'add' + title + ind" :triggers="add?'click':'focus'" placement="rightbottom" variant="navy">
    <template #title>Select Blanket {{ind + 1 }} Buildings</template>
    <perfect-scrollbar :options="{suppressScrollX:true}" style="max-height: 300px;margin:0 -16px 50px 0">
      <div v-for="(b,i) in buildingData" :key="i + blanketType + ind">
        <strong v-if="b.bi === 0 && (!b.parentId || b.cloneNum === 0)" style="color:white" class="d-block mt-1">
      <span class="d-block" :style="{color: 'white', cursor: 'pointer'}" @click="multiSelect({level: 'loc', blanketType, blanketIndex: ind, targetId: b.loc.li})">
        <tri-check class="" style="pointer-events: none" :eventsOnly="true" :value="blanketParentSelected({blanketType, blanketIndex: ind, level: 'loc', targetId: b.loc.li})">
        <span style="color: white">{{ b.loc.name }}</span>
        </tri-check>
      </span>
        </strong>
        <span v-if="b.identicalCt > 1 && !b.cloneNum" class="d-block ml-3 mb-1" @click="multiSelect({level: 'bld', blanketType, blanketIndex: ind, targetId: b.parentId})">
        <tri-check class="" style="pointer-events: none" :eventsOnly="true" :value="blanketParentSelected({blanketType, blanketIndex: ind, level: 'bld', targetId: b.parentId})">
          <span style="color: white">{{ b.parentName }}</span>
        </tri-check>
    </span>
        <div
            @mousedown="mouse(b,blanketType)"
            v-if="b[blanketType] === null || b[blanketType] === ind"
            style="cursor: pointer"
            class="ml-2 mb-1"
        >
          <b-check :class="{'ml-2': b.identicalCt === 1, 'ml-4': b.identicalCt > 1}" style="pointer-events: none"
                   :disabled="false"
                   :checked="b[blanketType] !== null" :unchecked-value="null"
          >
            <span style="color: white">{{b.identicalCt > 1 ? b.name : b.parentName}}</span>
          </b-check>
        </div>
        <b-check v-else class="ml-3" style="opacity: .5" disabled="disabled" :checked="true">{{b.name}}</b-check>
      </div>
      <hr>

    </perfect-scrollbar>
    <div class="text-right">
      <b-button style="position:absolute;bottom:11px;right:11px;" variant="primary" size="sm" @click="$root.$emit('bv::hide::popover')">DONE</b-button>
    </div>
    </b-popover>`,
  props: ['buildingData', 'index', 'blanketType', 'title', 'add']
});

export default {
  data: () => {
    return {
      originalValues: '',
      isCheckEvent: false,
      dirty: false
    };
  },
  components: {bldPopop},
  computed: {
    ...mapGetters('getQuote', ['locations', 'buildings']),
    ...mapState('getQuote', ['saving']),
    dirtyTracker(){
      return JSON.stringify(this.blanketList('bldIndex').map(b => b.buildingIds));
    },
    blanketGroups(){
      return dataVersion => [
        { title: 'Building', blanketType: 'bldIndex'},
        { title: 'BPP', blanketType: 'bppIndex', dataVersion: 1}
      ].filter(b => !b.dataVersion || b.dataVersion >= dataVersion);
    },
    bppCounter(){
      return this.blanketList('bppIndex').length;
    },
    bldCounter(){
      return this.blanketList('bldIndex').length;
    }

  },
  mixins: [quotemix, blanketMix],
  methods: {
    renumber(type){
      if (!this.isCheckEvent){
        return;
      }
      let index = null;
      this.blanketList(type).forEach((b, i) => {
        if (index === null && b.blanket !== i + 1){
          index = i;
        }
      });
      if (index === null) {
        return;
      }
      this.removeBlanket(index, type);
    },
    toggleSelectAll({level, blanketType, blanketIndex, targetId}) {
      const buildings = this.buildingData.filter(b => targetId === (level === 'loc' ? b.loc.li : b.parentId));
      const availableBuildings = buildings.filter(b => b[blanketType] === blanketIndex || isType.nullOrUndef(b[blanketType]));
      const allSelected = availableBuildings.every(b => !isType.nullOrUndef(b[blanketType]));
      availableBuildings.forEach(b => {
        if (allSelected) {
          b[blanketType] = null;
        } else {
          b[blanketType] = blanketIndex;
        }
      });
    },
    removeBlanket(index, blanketType){
      if (this.isCheckEvent){
        this.$root.$emit('bv::hide::popover');
      }
      if (this.loadedBlanketIdsByType[blanketType][index]) {
        const removedId = this.loadedBlanketIdsByType[blanketType].splice(index, 1);
        this.deletedBlanketsIdsByType[blanketType].push(removedId[0]);
      }
      this.buildingData.forEach(b => {
        if (b[blanketType] === index){
          b[blanketType] = null;
        }else if (b[blanketType] > index){
          b[blanketType]--;
        }
      });
      this.save();
    },
    checkEvent(){
      this.isCheckEvent = true;
      this.delayFn(() => this.isCheckEvent = false, 100);
    },
    cleanBlankets(){
      this.dirty = false;
      this.originalValues = this.dirtyTracker;
    },
    save(route){
      return new Promise(res => {
        let doSave = () => {
          this.saveBlankets(!!route).then(result => {
            if (result && !result.response.hasErrors) {
              this.rehydrate({fields: result.response.fields});
              this.rehydrateBlankets();
              this.delayFn(() => {
                this.cleanBlankets();
              });
            }
            res(result);
          }).finally(() => {
            if (route) {
              this.$router.push(route);
            }
          });
        };
        doSave();
        let retryOnNextError = ['updateBlanketCoverages', doSave];
        this.setAny({retryOnNextError});
      });
    }
  },
  mounted() {
    this.delayFn(() => {
      this.cleanBlankets();
    });
  },
  watch: {
    bldCounter(ct, old){
      if (ct < old){
        this.renumber('bldIndex');
      }
    },
    bppCounter(ct, old){
      if (ct < old){
        this.renumber('bppIndex');
      }
    },
    dirtyTracker(bldgIds){
      this.dirty = bldgIds !== this.originalValues;
    }
  },
  name: 'blankets'
};
</script>

<style lang="scss">

@import "../../../assets/scss/tables";
@import "../../../assets/scss/variables";

.zero td{

  div {
    font-style: italic;
    text-decoration: line-through;
    i{
      cursor:pointer;
    }
  }
}
.ps__rail-y {
  opacity: 0.6;
  right: 3.5px;
}
</style>
