<template>
  <div :style="{height:ht}">
    <div class="h-100 d-flex flex-column">
      <div class="flex-fill" v-if="!ht"></div>
      <div :class="slotClass" :style="innerStyle"><slot/></div>
      <div class="flex-fill" v-if="!ht"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'valign-mid',
  computed: {
    ht(){
      return this.h ? this.px(this.h) : undefined;
    },
    innerStyle(){
      let {ht, slotStyle = {}} = this;
      if (ht){
        return {
          ...slotStyle,
          height: this.isType.string(ht) ? ht : `${ht}px`
        };
      }
      return slotStyle;
    }
  },
  props: ['slotClass', 'slotStyle', 'h']
};
</script>

