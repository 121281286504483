<template>
  <b-modal v-if="!answered" v-model="show" title="Eligibility" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
    <div class="m-5 pl-4 pr-4" v-if="updating">
      <progress-bar>Updating Quote</progress-bar>
    </div>
    <div v-else>
      <div class="d-flex" v-if="!exposure && !error">
        <div class="flex-fill"></div>
        <div class="mr-4">
          Do you have any of the below exposures?
        </div>
        <b-form-radio-group
            v-model="exposure"
            :options="options"/>
        <div class="flex-fill"></div>
      </div>
      <div v-if="exposure===true" class="m-5 text-center">
        <div v-if="imSure">Unfortunately these exposures fall outside our guidelines.</div>
        <div v-else>Are you sure you have one or more of these exposures?</div>
      </div>
      <div v-else-if="error!==null" class="text-center">
        <b-alert :show="true" :dismissible="false" variant="danger">{{error.message}}</b-alert>

      </div>
      <div class="d-flex" v-else-if="questions.length">
        <ul v-for="(chunk,ci) in chunkArray(questions, Math.ceil(questions.length / 2))" :key="`qchnk${ci}`" class="flex-fill ml-5">
          <li v-for="(q,i) in chunk" :key="`q${i}`" class="mt-4">{{q}}</li>
          <li v-if="ci === 1" class="mt-4">Bridges over $100,000 in property value</li>
        </ul>

      </div>

      <div class="d-flex" v-else>
        <div class="flex-fill"></div>
        <div class="w-50">
          <progress-bar>Retrieving Eligibility Questions...</progress-bar>
        </div>
        <div class="flex-fill"></div>
      </div>
    </div>
      <template #modal-footer v-if="!imSure && !error">
        <div :class="{invis:updating}">
        <b-button size="lg" variant="info" v-if="exposure" @click="exposure=null">NO, GO BACK</b-button>

        <b-button size="lg" variant="primary" v-if="exposure" @click="submit(true)">YES</b-button>

        <b-button size="lg" variant="primary" v-if="!exposure" :disabled="exposure === undefined" @click="submit(false)">SUBMIT</b-button>
        </div>
      </template>
      <template #modal-footer v-else>

        <router-link to="/" :class="{invis:updating}">
          <b-button size="lg" variant="info">MAIN MENU</b-button>
        </router-link>

      </template>

  </b-modal>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import ProgressBar from '@/components/util/progress-bar';
import eventbus from '@/lib/eventbus';

export default {
  components: {ProgressBar},
  data: () => {
    return {
      updating: false,
      show: true,
      error: null,

      imSure: false,
      exposure: undefined,
      options: [
        {text: 'Yes', value: true},
        {text: 'No', value: false}
      ]
    };
  },
  computed: {
    answered(){
      return this.itemVal('quote.answeredEligibility') === true;
    },
    questions(){
      let list = this.itemFromChain('eligibility.questions');
      return list && list.val ? list.val
        .filter(q => !q.lbl.toLowerCase().includes('do any of the following')).map(li => {
          let text = li.lbl;
          return text.replace('?', '');
        }) : [];
    }

  },
  methods: {
    reset(){
      this.imSure = this.exposure = null;
      this.show = true;
    },
    submit(hasExposure){
      if (hasExposure){
        this.imSure = true;
      }
      this.updating = true;
      let {quoteId, policyId, customerId, customerEligibilityId} = this.quoteData.quote;
      let params = {quoteId, policyId, customerId, customerEligibilityId, hasExposure};
      this.oneShield('updateEligibilityQuestions', params).then(({response}) => {
        if (!response.hasErrors) {
          this.reset();
          this.rehydrate({fields: response.fields});
          console.log({updatedEligibility: this.itemVal('quote.answeredEligibility')});
        }

      });
    },
    closeError(){
      this.error = null;
      this.show = false;
    }
  },
  name: 'eligibility',
  mixins: [quotemix],
  mounted(){
    eventbus.$on('oneShieldError', ({error}) => {
      this.updating = false;
      this.error = error;
    });
    this.updating = false;
    if (!this.answered){
      let {jurisdiction, product} = this.quoteData.scope;
      //debugger;
      this.oneShield('eligibilityQuestionList', {jurisdiction, product})
        .then((result) => {

          this.finishProg = true;
          let {fields} = result.response;
          if (Array.isArray(fields)) {
            fields.filter(f => f.type === 'list').forEach(list => {
              let {chain, val} = list;
              this.updateField({chain, val});
              this.updateField({chain, val: true, prop: 'valid'});
            });
          }
        });
    }
  },
  beforeDestroy() {
    this.reset();
  },
  watch: {
    answered(){
      this.reset();
    }
  }
};
</script>

<style scoped>

</style>
