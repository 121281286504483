<template>
<div>


  <div class="mr-4 mb-5" v-for="(l,i) in locationManager.children" :key="`${l.guid}`">
    <div class="mr-2 pt-2 text-center">
      <h4 style="text-transform: none" class="mt-1">Location {{ i + 1}}
        <i class="fas fa-info-circle text-danger" v-if="address(l).focusedState.readyToValidate && (!address(l).isValid || !address(l).val.verified)" v-b-tooltip="'Address is invalid or can not be verified'"></i>
      </h4>
    </div>
    <div class="d-flex">
      <div class="flex-fill">
        <field :def="addUniq(l.addressDef, l)" style="margin-top:-3px"/>
      </div>

    </div>
  </div>
  <div class="mt-4 mr-4 text-center" v-if="canAdd">
    <a v-if="!incompleteLoc" class="text-primary" @click="addLocation">+ Add another location</a>
  </div>

</div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {mapGetters, mapMutations} from 'vuex';
import fields from '@/lib/fields/fields';
import Field from '@/components/fields/field';

let locationManager = fields.quoteFields.locations;

export default {
  components: {Field},
  data: () => {
    return {
      canAdd: true,
      locationManager
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemVal', 'enumValObj']),
    addUniq(){
      return (addressDef, parent) => {
        addressDef.uniqWithin = locationManager.children.map(l => l.addressObj);

        addressDef.deleteAction = this.canDelete && locationManager.children.length > 1 ?
            () => this.deleteLocation(parent) :
            false;
        return addressDef;
      };
    },
    canDelete(){
      return this.isType.nullOrUndef(this.itemVal('quote', 'wildfire'));
    },
    address(){return l => l.addressDef;},
    invalid(){
      return l => {
        let address = l.addressDef;
        if (!address.readyToValidate){
          return false;
        }
        let valid = address.isValid && address.val.verified;
        console.warn({valid});
        return !valid;
      };
    },
    addresses(){
      return locationManager.children.map(l => l.addressDef);
    },
    incompleteLoc(){
      return this.addresses.filter(a => !a.isValid || !a.val.verified).length;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['removeDef', 'updateField']),
    addLocation(){
      if (!this.incompleteLoc || this.locations.length === 0) {
        locationManager.addLocation({address: {street2: false}});
      }
    },
    deleteLocation(l){
      locationManager.removeLocation(l.key);
      console.log(locationManager.children.map(l => [l.li, l.num, l.key]));
    }
  },
  name: 'add-locations',
  mixins: [quotemix],
  mounted() {
    this.canAdd = true;
    if (!this.addresses.length) {
      this.addLocation();
    }
  }

};
</script>


<style lang="scss">
@import "../../../assets/scss/get-quote";
.add-location {
  .remove-loc {
    height: 36px;
    width: 42px;
    position: absolute;
    top: 26px;
    right: -15px;
    border:solid 1px $gray;
  }

  div.quote-panel {
    max-width: 1200px;
    margin: 0 auto;

    label.field {
      padding: 4px 14px 0 24px;
      font-weight: 700;
    }

    .modal-footer {
      button.btn{
        width:250px;
      }
    }
  }

}
</style>
