import {isType} from '@/lib/mytype';
import {parseShortDate} from '@/lib/util';

export const clone = (o) => {
  try {
    if (!o || isType.primitive(o)) {
      return o;
    }
    return JSON.parse(JSON.stringify(o));
  }catch(ex){
    return o;
  }
};
let pad = n => (`${n}`).padStart(2, '0');

export const longDate = (d = new Date()) => {
  d = new Date(d);
  return `${d.getFullYear()}${pad(d.getMonth() + 1)}${pad(d.getDate())}000000`;
};

export const mmddyyyy = (d = new Date()) => {
  d = new Date(d);
  return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()}`;
};

export const parseDate = (s, useOSTimezone) => {
  try {
    let y, m, d;
    if (isType.snum(s)) {
      if (s.length === 13) {//1643393021075
        return Number(s);
      }
      if (s.startsWith('20') && s.length > 7) {//"20220128050855"
        y = Number(s.substr(0, 4));
        m = Number(s.substr(4, 2)) - 1;
        d = Number(s.substr(6, 2));
        if(s.length > 9){
          let h, min, sec;
          h = Number(s.substr(8, 2));
          min = Number(s.substr(10, 2));
          sec = Number(s.substr(12, 2));
          if (useOSTimezone === true){
            let timestring = `${y}-${pad(m + 1)}-${pad(d)}T${pad(h)}:${pad(min)}:${pad(sec)}`;
            return parseShortDate(timestring);
          }
          let dt = new Date(y, m, d, h, min, sec);
          //debugger;
          return dt.valueOf();
        }
        return new Date(y, m, d).valueOf();
      }
    }
    return new Date(s).valueOf();
  }catch(ex){
    return null;
  }
};
