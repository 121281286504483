<template>
  <svg viewBox="0 0 100 100" :style="{height: px(size), width: px(size)}">
    <g id="Group_11" data-name="Group 11" transform="translate(-127.5 -162.013)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="50" cy="50" r="50" transform="translate(127.5 162.013)" fill="#1c2d40"/>
      <g id="Group_2" data-name="Group 2" transform="translate(153.241 199.616)">
        <g id="Group_1" data-name="Group 1">
          <path id="Path_1" data-name="Path 1" d="M257.5,275.208v18.229h0a5.208,5.208,0,0,0,5.208-5.208V270A5.208,5.208,0,0,0,257.5,275.208Z" transform="translate(-238.296 -270)" fill="#fff"/>
          <path id="Path_2" data-name="Path 2" d="M203.71,288.229V275.208A5.208,5.208,0,0,0,198.5,270h0v23.437h16.927l-3.007-5.208Z" transform="translate(-198.502 -270)" fill="#fff"/>
          <path id="Path_3" data-name="Path 3" d="M293.221,270a11.719,11.719,0,1,0,11.719,11.719A11.719,11.719,0,0,0,293.221,270Zm0,18.229a6.51,6.51,0,1,1,6.51-6.51A6.518,6.518,0,0,1,293.221,288.229Z" transform="translate(-254.484 -270)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ['size'],
  name: 'liologo'
};
</script>
