import Vue from 'vue';
import Vuex from 'vuex';
import getQuote from './get-quote';
import localForage from 'localforage';
import eventbus from '@/lib/eventbus';
import { datadogRum } from '@datadog/browser-rum';
let defaultEnv = process.env.DEFAULT_ENV;
Vue.use(Vuex);
let _commit;
let local = false; //process.env.REDIRECT_URL.includes('localhost');
let _oktaUser = local ? {
  name: 'Local Developer',
  email: 'developing@localhost.local'
} : null;
let initPromise;
export default new Vuex.Store({
  modules: {getQuote},
  getters: {
    validSession: (state, getters) => {
      return (curTime = Date.now()) => {
        let valid = false;
        if (state.osUser.sessionId && getters.osSessionExpiry && state.oktaToken.accessToken) {
          valid = curTime < getters.osSessionExpiry && curTime < state.oktaToken.expiresAt;
        }
        return valid;
      };
    },
    osSessionExpiry: (state) => {
      return state.sessionStart + state.sessionTimeoutPeriod;
    }
  },
  state: {
    local,
    sessionTimeoutPeriod: 60000 * 15,
    appBg: 'white',
    debug: local,
    debugOptions: {
      quoteEffectiveOff: 0,
      apiEnv: defaultEnv,
      admin: null,
      showDebugger: local
    },
    sessionStart: null,
    loggingIn: false,
    isAuthenticated: local,
    isAuthPending: true,
    authError: null,
    osUser: {
      f: 'One Shield User',
      userName: '',
      password: '',
      partnerId: 0,
      sessionId: null
    },
    oktaToken: {
      accessToken: null,
      expiresAt: null
    },
    lastUserInput: new Date().valueOf(),
    oktaUser: _oktaUser,
    scopeUser: false,
    storeInit: false,
    okta: null
  },

  mutations: {
    userInput(state){
      state.lastUserInput = new Date().valueOf();
    },
    setRoot(state, obj){
      Object.keys(obj).forEach(k => {
        if (k.includes('.')){
          _commit('setChained', {[k]: obj[k]});
        }else {
          state[k] = obj[k];
        }
      });
    },
    setAny(state, obj) {
      Object.keys(obj).forEach(k => {
        if (k.includes('.')){
          _commit('setChained', {[k]: obj[k]});
        }else {
          state[k] = obj[k];
        }
      });
    },
    setOSUser: (state, osUser) => {
      Object.entries(osUser).forEach(([k, val]) => {
        state.osUser[k] = val;
      });
    },
    setChained: (state, {chain, val}) => {

      let spl = chain.split('.').reverse();
      let o = state;
      while (spl.length > 1){
        let k = spl.pop();
        if (o[k] === undefined){
          o[k] = {};
        }
        o = o[k];
      }
      o[spl.pop()] = val;

    }
  },
  actions: {
    debugOption: ({commit, state}, opt) => {
      let debugOptions = state.debugOptions;
      Object.entries(opt).forEach(([k, v]) => debugOptions[k] = v);
      commit('setAny', {debugOptions});
      localForage.setItem('debugOptions', debugOptions);
      state.getQuote.env = debugOptions.apiEnv;

    },
    init({dispatch, commit, state}){
      if (initPromise){
        return initPromise;
      }
      _commit = commit;
      state.local = location.href.includes('localhost');
      initPromise = new Promise(res => {
        localForage.getItem('debugOptions').then(opts => {
          localForage.getItem('osSession').then((cache) => {
            if (cache) {
              console.log({osSession: cache});
              let {sessionStart, osUser, lastUserInput} = cache;
              if ((new Date().valueOf() - sessionStart) < state.sessionTimeoutPeriod) {
                commit('setAny', {sessionStart, lastUserInput});
                commit('setOSUser', osUser);
                console.log({sessionExists: state.osUser, osUser});
              }
            }
            if (opts){
              dispatch('debugOption', opts);
            }
            //commit('sessionCreds', state.debugOptions.apiEnv === 'qa');
            commit('setAny', {storeInit: true});
            res(true);
          });
        });
      });
      return initPromise;
    },
    bindToAuthState: async ({ state, commit }, auth) => {
      commit('setRoot', {okta: auth});
      const initAuthState = authState => {
        if (authState.isAuthenticated) {

          const {accessToken, expiresAt} = authState.accessToken;
          state.oktaToken.accessToken = accessToken;
          console.log({expiresAt});
          state.oktaToken.expiresAt = expiresAt * 1000;
          const { userRole, partnerId } = authState.accessToken?.claims ?? {};
          auth.getUser().then(oktaUser => {
            oktaUser.userRole = userRole;
            oktaUser.partnerId = partnerId;
            datadogRum.setUser({
              id: oktaUser.sub,
              name: oktaUser.name,
              email: oktaUser.email,
              roleId: oktaUser.userRole,
              partnerId: oktaUser.partnerId
            });
            commit('setAny', {
              isAuthenticated: authState.isAuthenticated,
              isAuthPending: authState.isAuthPending,
              authError: authState.error,
              oktaUser: oktaUser
            });
          });
        }

      };
      auth.authStateManager.subscribe(async authState => {
        commit('setRoot', {loggingIn: true});
        // handle emitted latest authState
        initAuthState(authState);
      });
      if (!auth.isLoginRedirect()) {
        // Trigger an initial authState change event when the app startup
        auth.authStateManager.updateAuthState();
        const authState = auth.authStateManager.getAuthState();
        if (authState.isAuthenticated) {
          initAuthState(authState);
        }
      }
    },
    async logout({ commit}, auth){
      try {
        localForage.setItem('osSession', null).then(() => {});
        localStorage.setItem('lastPath', null);
        commit('setAny', {
          isAuthenticated: false,
          isAuthPending: false,
          osUser: {
            userName: '',
            password: '',
            partnerId: 0,
            sessionId: null
          },
          osSession: {},
          oktaUser: {}
        });
        await auth.signOut();
      } catch (e) {
        console.warn('Error logging out', e);
      }
    }
  }
});
