<template>
<svg viewBox="0 0 915 446" :style="layout">
  <path d="M 0 441.1 C 103.2 440.7, 206.7 440.3, 309.8 440 C 291.5 405.7, 273.2 371.4, 254.9 337.2 C 203.8 337.4, 152.6 337.6, 101.5 337.9 C 101.1 255.7, 101.1 178.6, 100.7 96.4 C 100.5 64.3, 64.5 0, 0 9.6 C 0 153.3, 0 297.4, 0 441.1 Z" :fill="p('fill')"/>
  <path d="M 445.9 10.1 C 445.7 127.5, 445.4 244.9, 445.2 362.3 C 434.8 411.8, 391.9 446, 344.6 443.9 C 344.9 361.7, 344.6 179.1, 344.9 96.9 C 343.6 69.1, 376.8 14.2, 424.6 9 C 431.4 8.3, 438.6 8.6, 445.9 10.1 Z" :fill="p('fill')"/>
  <path d="M 700.7 8.6 C 819.1 8.6, 915 105.5, 915 224.9 C 915 344.3, 819.1 441.1, 700.7 441.1 C 582.4 441.1, 486.5 344.3, 486.5 224.9 C 486.5 105.5, 582.4 8.6, 700.7 8.6 M 588.8 224.9 C 588.8 287.2, 638.9 337.8, 700.7 337.8 C 762.5 337.8, 812.6 287.2, 812.6 224.9 C 812.6 162.5, 762.5 112, 700.7 112 C 638.9 112, 588.8 162.5, 588.8 224.9 Z" :fill="p('fill')"/>
</svg>
</template>

<script>
import {svgMix} from '@/lib/mix';

export default {
  data: () => {
    return {
      defaults: {
        fill: 'white',
        w: 730,
        h: 390
      }
    };
  },
  mixins: [svgMix],
  name: 'lio'
};
</script>

<style scoped lang="scss">

</style>
