<template>
  <b-input-group class="phone-field"
      :disabled="disable" :state="fieldValid" style="position: relative"
      :variant="multitip ? 'light' : ''"
  >
    <b-input-group-prepend :state="fieldValid" :disabled="disable">
      <b-button :class="validClass" tabindex="-1">+1</b-button>
    </b-input-group-prepend>
    <b-input
        :disabled="disable" :autofocus="autofocus"
        @focus="touch(true)" @blur="updateVal" :state="fieldValid" :autocomplete="'nope'"
        v-model="def.val" :placeholder="def.placeholder" :id="def.guid" :chain="def.chain"
        v-b-tooltip.hover="def.isValid?'':def.validationTip" :maxlength="25"
        :class="{transparent: def.isTypeValid}"
    />
    <span class="format-overlay" v-if="def.isTypeValid">
      {{ def.formatted }}
    </span>
  </b-input-group>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  mixins: [fieldmix],
  methods: {
    updateVal(){
      this.touch(false);
      this.def.val = this.def.digits;
    }
  },
  name: 'fieldtype_phone'
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.phone-field {
  .format-overlay {
    pointer-events: none;
    position: absolute;
    top: 7px;
    left: 44px;

  }

  input.form-control.transparent {
    color: rgb(0,0,0,0) !important;

    &:active, &:focus {
      color: #333 !important;
    }
  }
}
</style>
