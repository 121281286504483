<template>
  <div class="vh-100 mh-100" >
    <div class="quote-step d-flex flex-column mh-100" v-if="$route.name ==='quote' || render===true">

      <h2 class="title" v-if="page && page.title && !page.hideTitle">{{ (pageTitle) }} <small v-if="page.subtitle">{{ subTit }}</small></h2>
      <component :is="`quote-${componentName}`"/>
    </div>
    <not-editable v-else-if="render.statusKey" :status="render.statusKey" :quote-id="routeQuoteId" :error-text="render.error"/>
    <div class="d-flex mt-5" v-else-if="routeQuoteId">
      <div class="flex-fill"></div>
      <div class="w-25" style="margin-top: 50px;">
        <progress-bar>Opening Quote... </progress-bar>
      </div>
      <div class="flex-fill"></div>
    </div>

  </div>
</template>

<script>
import QuoteDetails from '@/components/quote-steps/quote-details';
import QuoteEligibility from '@/components/quote-steps/quote-eligibility';
import QuoteCustomer from '@/components/quote-steps/quote-customer';
import QuoteIssuePolicy from '@/components/quote-steps/quote-issue-policy';
import QuoteLocations from '@/components/quote-steps/quote-locations';
import QuoteScope from '@/components/quote-steps/quote-scope';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import SessionTimer from '@/components/session-timer';
import {isType} from '@/lib/mytype';
import QuoteBindQuote from '@/components/quote-steps/quote-bind-quote';
import {guid} from '@/lib/util';
import ProgressBar from '@/components/util/progress-bar';
import { datadogLogs } from '@datadog/browser-logs';
import NotEditable from '@/components/quote-steps/shared/not-editable';
import eventbus from '@/lib/eventbus';
import {portalEvents} from '@/lib/mix';
import {quotemix} from '@/lib/quotemix';
import {progressModal} from '@/lib/modal-service';

export default {
  components: {
    NotEditable, ProgressBar, QuoteBindQuote, SessionTimer,
    QuoteScope, QuoteLocations, QuoteIssuePolicy, QuoteCustomer, QuoteEligibility, QuoteDetails
  },
  data(){
    return {
      opening: false,
      canAdvance: false,
      render: false,
      details: false,
      inMemComponent: 'scope',
      navOnceFlag: false,
      exiting: false
    };
  },
  mixins: [quotemix],
  computed: {
    ...mapState('getQuote', ['pages', 'navigationPath', 'isRating']),
    ...mapGetters('getQuote', ['itemVal', 'itemFromChain', 'locations']),
    page(){
      let p = this.pages[this.componentName];
      return p;
    },
    quoteStatus(){
      return this.itemVal('quote.status');
    },
    pageTitle(){
      if (!isType.primitive(this.render) && this.render.title){
        return this.render.title;
      }
      let title = this.page?.title ?? '';
      let tab = this.$route.params.tab;
      if (tab && this.page.tabs){
        //details will fallback to default;
        return this.page.tabs[tab] ? this.page.tabs[tab] : title;
      }
      return title;
    },
    quoteLoaded(){
      return Boolean(this.itemVal('quote.quoteId'));
    },
    routeQuoteId(){
      return this.$route.params.quoteId ?? null;
    },
    componentName(){
      let rName = this.$route.name;
      return rName === 'quote' ?
        (this.inMemComponent || 'scope') :
        this.$route.params.step ?? 'details';
    },
    subTit(){
      let qid = this.itemVal('quote.quoteId');
      return this.page.subtitle ? this.page.subtitle.replace('::qid::', qid || guid().toUpperCase()) : '';
    },
    nextStepRoute(){
      let params = this.$route.params;

      let step = this.page.next || params.step;
      if (['scope', 'eligibility', 'locations', 'customer'].includes(step)){
        return {
          name: 'quote',
          path: '/quote/locations'
        };
      }
      if (params.tab && ['exposures', 'blankets'].includes(step)){
        params = this.clone(params);
        params.tab = null;
      }
      let name = this.$route.name;
      let quoteId = this.routeQuoteId || undefined;
      if (name === 'getQuote' && quoteId) {
        name = 'getQuote';
      }
      if (step && !isType.string(step)){

        let computedStep = step.when.find(condition => {
          let v = this.itemVal(condition.chain);
          return condition.eq === v;
        });
        step = computedStep ? computedStep.page : step.default.page;

      }
      return ({
        name,
        params: {
          ...params, step, quoteId
        }
      });
    }
  },
  name: 'quote-step',
  methods: {
    ...mapMutations('getQuote', ['rehydrate', 'resetSteps', 'setNavPath', 'updateField']),
    ...mapActions('getQuote', ['getQuoteDetail']),
    exit(){
      this.exiting = true;
      this.$router.push({path: '/'});
    },

    goNext(){
      if (this.canAdvance && !this.navOnceFlag){
        this.navOnceFlag = true;
        if (this.$route.name === 'quote'){
          this.inMemComponent = this.page.next;
        }else{
          this.$router.push(this.nextStepRoute);
        }
        this.delayFn(() => this.navOnceFlag = false, 800);
      }
    },
    async open(){
      if (this.opening || this.exiting){
        console.log('prevent duplicate open quote operation');
        if (isType.string(this.quoteStatus)){
          this.conditionalRender();
        }
        return;
      }
      if (this.routeQuoteId) {
        let hasErrors;
        this.opening = true;
        this.getQuoteDetail({id: this.routeQuoteId, oneShield: this.oneShield})
        .then(async (result) => {
          let response = result.response;

          hasErrors = result.ex || response.hasErrors && (response.errorText || 'Unknown Error');
          datadogLogs.logger.info('Fetched quote details', { quoteId: this.routeQuoteId });
          const jobId = response?.propertyBag?.ratingJobId;
          //todo: change the isRating setter to here?
          if(jobId && !this.isRating) {
            //progressModal('Rating and Underwriting Your Quote');
            this.getRatingStatus(jobId, this.routeQuoteId).then(() => {});//noop ->wait for eventbus
            let waiting = true;
            eventbus.$on('ratingFinish', () => {
              if (!waiting) {
                return;//prevent multiple rating listeners
              }
              waiting = false;
              progressModal(false);
            });
          }
          //let status = this.itemVal('quote.status');
          const scoredLocations = this.itemFromChain('quote.scoredLocations').val;

          if (!scoredLocations && this.locations.length) {
            const lookupAddresses = this.locations.map(({dataTree: {addressDef}}) => ({
              street1: addressDef.street1,
              city: addressDef.city,
              zipcode: addressDef.zipcode,
              state: addressDef.stateCode
            }));

            const getLocationsResult = await this.oneShield('getScoredLocations', { addresses: lookupAddresses }, { dontDie: true});


            // TerrorismTerritory is at the policy level, so grab the first present value
            const terrorismTerritory = getLocationsResult.response.find(l => l.terrorismTerritory?.id)?.terrorismTerritory.id ?? '-1';
            this.updateField({
              chain: 'quote.scoredLocations',
              val: getLocationsResult.response
            });
            let mappedScores = this.locations.map(l => l.scores);
            console.log({mappedScores});
            this.updateField({
              chain: 'policy.terrorismTerritory',
              val: terrorismTerritory
            });
            eventbus.$emit('scoresAdded');

            this.opening = false;
          }
        })
        .catch(ex => {
          console.log('quote locations have not been evaluated', ex);
        })
        .finally(() => {
          if(hasErrors){
            console.log({hasErrors});
            //localhost debugging helper. You're welcome
            if (location.hostname.includes('localhost') && hasErrors.startsWith('object is locked')){
              this.render = true;
              return;
            }
            this.render = {
              statusKey: 'err',
              error: [hasErrors]
            };

          }
        });
      }
      else if (this.$route.name === 'quote'){
        this.render = true;
      }
    },
    conditionalRender(){
      let status = this.quoteStatus;

      if (!isType.string(status)){
        if (!this.quoteLoaded && !this.exiting){
          this.open();//page stuck in loading state. Calling open should resolve
        }
        this.render = false;
      }


      else if (status.startsWith('Referred') && !this.isAdmin){
        this.render = {
          statusKey: 'uw'
        };
      }
      else if (status === 'Bound'){
        this.render = {
          statusKey: 'bound'
        };
      }
      else if (status.startsWith('Reje')){
        this.render = {
          statusKey: 'rej'
        };
      }
      else if (status.startsWith('Decli')){
        this.render = {
          statusKey: 'dec'
        };
      }
      else if (status.startsWith('Laps')){
        this.render = {
          statusKey: 'exp'
        };
      }
      else if (status.toLowerCase().includes('cancel')){
        this.render = {
          statusKey: 'canc'
        };
      }
      else{
        this.render = true;
      }
    }
  },
  mounted() {

    if (!this.navigationPath || this.routeQuoteId) {
      this.open();
    } else {
      this.delayFn(() => this.render = true);
    }

    if (!this.navigationPath.length){
      this.setNavPath(this.componentName);
    }
    document.title = `${this.pageTitle} (LIO Insurance)`;
    eventbus.$on('lockResult', (locked) => {
      this.updateField({chain: 'quote.locked', val: locked});
      if (!locked){
        this.render = {
          statusKey: 'cantLock'
        };
        this.$parent.showError = false;
      }
    });
    eventbus.$on('quoteRehydrate', this.conditionalRender);
  },
  beforeDestroy() {
    this.setNavPath(null);
    this.clickStreamEvent(portalEvents.QuoteClose, this.componentName, this.quoteId, {routeName: this.$route.name});
  },
  watch: {
    componentName(cn, prev){
      if (prev && cn !== prev) {
        this.setNavPath(cn);
      }
    },
    sessionId(id, old){
      if (old === null && id){
        this.open();
      }
    },
    quoteStatus(){
      this.conditionalRender();
    },
    pageTitle: {
      handler(t) {
        this.navOnceFlag = true;
        if (isType.nullOrUndef(t)){
          t = '';
        }
        this.delayFn(() => this.navOnceFlag = false, 500);
        document.title = `${t} (Get a Quote - LIO Insurance)`;
      },
      deep: true
    },
    outerError(knownError){
      if (knownError?.type === 'quoteLock'){
        this.render = {
          statusKey: 'cantLock'
        };
      }
    },
    locations(locs){
      if (locs.find(l => !l.isLocation)){
        console.log(locs);
      }
    }
  },
  props: ['outerError']
};
</script>

<style lang="scss">
.quote-step {
  .quote-panel{
    position:relative;
    .val-buttons{
      position:absolute;
      top:0;
      right:0
    }
  }
  .title {
    //text-transform: uppercase;
    text-align: center;
    margin-top:18px;
    margin-bottom: 8px;

    small {
      text-transform: none;
      vertical-align: text-top;
      top: 2px;
      left: 4px;
      position: relative;
    }
  }
}
</style>

