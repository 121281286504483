<template>
<div>


  <div class="d-flex mb-3 mt-3 pl-3">
    <div style="width:50%">
      <field-column :defs="storeFields('propertyCoverages').filter(f=>f.hasTag('col1'))" />
    </div>
    <div style="width:50%">
      <field-column :defs="storeFields('propertyCoverages').filter(f => f.hasTag('col2'))" />
    </div>

  </div>
  <div class="pb-3 pl-3 w-100" style="min-width:800px;max-width: 1100px" v-if="itemVal('windstorm.windstormCoveredState')">

    <field-column label-w="35%" field-w="65%" :defs="storeFields('windstorm')"/>
  </div>
</div>
</template>

<script>
import {detailsTab, quotemix} from '@/lib/quotemix';

import eventbus from '@/lib/eventbus';
import FieldColumn from '@/components/fields/field-column';
import {clone} from '@/lib/util';
import {isType} from '@/lib/mytype';
import deepEqual from 'deep-equal';
export default {
  data: () => {
    return {
      apiSaveFunction: 'updatePropertyCoverages',
      quoteFields: ['quoteId', 'policyId', 'policyCommercialGLId', 'policyCFId'],
      tabName: 'propertyCoverages'

    };
  },
  components: {FieldColumn },
  computed: {

    additionalParams(){
      let {locationId, cfLocationId, identicalBuildings} = this.buildings[0].dataTree;
      let {propertySubjectId, cfBuildingCoverageId} = identicalBuildings[0];
      return {
        locationId,
        cfLocationId,
        propertySubjectId,
        cfBuildingId: cfBuildingCoverageId,
        bppBlanketId: this.itemVal('propertyCoverages.bppBlanketId')
      };
    },
    dirty(){
      return this.propCovDirty || this.wsDirty;
    },
    propCovDirty(){
      let apiEntries = Object.entries(clone(this.apiParams))
          .map(([k, v]) => {
            if (isType.date(v)){
              v = v.toJSON();
            }
            return [k, v];
          });


      return !deepEqual(this.originalVals, Object.fromEntries(apiEntries));
    },
    wsDirty(){
      return this.storeFields('windstorm').some(f => f.dirty);
    }
  },
  methods: {
    save(){
      let promises = [];
      if (this.wsDirty){
        const keyPrefix = k => k.includes('_') ? k.split('_')[0] : k;
        const wsFields = this.storeFields('windstorm');
        let keyVals = wsFields.filter(f => !this.isType.nullOrUndef(f.val) && f._active !== false)
            .map(f => {
              let v = f.val;
              f.setPristine();
              return [keyPrefix(f.key), v];
            });
        let windstormProperties = Object.fromEntries(keyVals);
        windstormProperties.windstormCoveredState = this.findState().code;
        let wsPromise = this.propStore(this.itemVal('quote.quoteId'), {windstormProperties});
        promises.push(wsPromise);
        wsPromise.then(({response}) => {
          if (!response.hasErrors){
            wsFields[0].parent.setPristine();
          }
        });
      }
      if (this.propCovDirty) {
        let options = {savingFlag: true, toast: `Saving ${this.titleCased(this.tabName)}`, rehydrate: true};
        promises.push(
          this.oneShield(this.apiSaveFunction, this.apiParams, options)
        );
      }

      return promises.length ? Promise.all(promises) : Promise.resolve('unchanged');
    },
    updateVals(){
      this.cleanSlate();
    }
  },
  mixins: [quotemix, detailsTab],
  mounted() {
    this.updateVals();
    eventbus.$on('quoteRehydrate', this.updateVals);

  },
  name: 'property-coverages'
};
</script>
