<template>
  <b-modal v-model="showModal" size="lg" title="Rate Tier Report" id="rateTierReport" ok-title="OK">
    <div v-if="isFetching" class="m-5 p-4">
      <progress-bar class="mb-5">Loading...</progress-bar>
    </div>
    <p v-if="!report.length && !isFetching">No rate tier has been calculated for this quote yet</p>
    <perfect-scrollbar v-else-if="!isFetching && reports && reports.length" class="pr-3 pl-3" style="max-height: 600px;">
      <div v-for="(item,i) in reports" :key="i" class="report-card mb-2" :class="{visible:item.visible}">

          <h3
            class="w-100 text-left"
             :style="{color:item.visible?lioTheme.coral:lioTheme.navy}">

              Report {{report.length - i}} <span style="font-weight: 100">({{timeStamp(item.calculatedAt)}} <em v-if="!i"> - Most Recent</em>)</span>

          </h3>
        <div class="pl-4 mb-2">
          <h3>Score Summary</h3>
          <ul class="pl-4">
            <li v-for="(loc, idx) in item.report.content.locations" :key="idx">
              <strong>Location Id</strong>: {{ loc.locationId }} <strong>Score</strong>: {{ loc.locationScore }}
            </li>
          </ul>
          <h3 class="mt-2" @click="toggle(item)">
            <a :style="{color:item.visible?lioTheme.coral:lioTheme.navy}">
              <i class="fas mr-2"
                 :class="{'fa-caret-down': item.visible,'fa-caret-right': !item.visible}"></i>
              {{ item.visible ? 'Hide' : 'Show' }} Detail
            </a>
          </h3>
          <b-collapse :id="`report${i}`" :visible="item.visible">
            <json-list :obj="item.report"/>
          </b-collapse>
        </div>
      </div>
    </perfect-scrollbar>
    <template #modal-footer>
      <div class="w-100">
        <b-button size="lg" variant="primary" @click="$emit('reportClosed')">OK</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import JsonList from '@/components/util/json-list';
import ProgressBar from '@/components/util/progress-bar';
import {sortByKey} from '@/lib/util';
export default {
  data: () => {
    return {
      reports: []
    };
  },
  name: 'rateTierReport',
  components: {ProgressBar, JsonList},
  methods: {
    toggle(item){
      item.visible = !item.visible;
    }
  },
  computed: {

    timeStamp(){
      return dateString => {
        let date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      };
    }
  },
  props: ['report', 'isFetching', 'showModal'],
  watch: {
    report(report){
      let dated = report.map(r => {
        if (r && r.calculatedAt) {
          r.calculatedAt = new Date(r.calculatedAt);
          r.sortBy = r.calculatedAt.valueOf();
          r.visible = report.length === 1;
        }
        return {...r};
      });
      let sorted = sortByKey(dated, 'sortBy').reverse();
      this.reports.splice(0, this.reports.length, ...sorted);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
.report-card{
  background-color: $light;
  padding:12px 0 0 12px;
  border:solid 1px $gray;
  border-radius: 6px;
  &.visible{
    background-color: white;
  }
}
</style>
