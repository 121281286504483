<template>
  <b-checkbox @change="touch(true)" :disabled="disable" v-model="def.val" :id="def.guid"  :chain="def.chain">
    <slot>
      <span v-if="def.inlineLabel">{{def.inlineLabel}}</span>
    </slot>
  </b-checkbox>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  mixins: [fieldmix],
  name: 'fieldtype_bool'
};
</script>

<style scoped>

</style>
