let componentRef;
export const hook = (vm) => {
  componentRef = vm;
};

export const simpleModal = (title, msg, btns) => {
  return componentRef.spawnModal(title, msg, btns);
};
export const progressModal = (title, msg = 'Please wait...') => {
  if (!title){
    return componentRef.hideModal();
  }
  return componentRef.spawnModal(title, msg, null);
};
