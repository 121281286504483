export const bindQuote = [
  { key: 'pmtOption', type: 'select', lbl: 'Bill Plan', vals: [], resetVals: true },
  { key: 'uploadType', type: 'select', width: '100%', vals: [] },

  // Radio Buttons Temporarily removed - LIO-3785 - 03/24/22 - EG
  /*{ key: 'payerList', type: 'radio', tags: 'payer', classList: 'w-100',
    vals: {
      $conditional: true,
      $switch: [{
        test: {
          chain: 'customer.hasPropertyManager',
          includedIn: ['onSite', 'offSite']
        },
        result: [
          {key: 'namedInsured', lbl: 'Named Insured'},
          {key: 'pm', lbl: 'Property Manager'},
          {key: 'other', lbl: 'Other (direct bill only)'}
        ]
      }],
      defaultVal: [
        {key: 'namedInsured', lbl: 'Named Insured'},
        {key: 'other', lbl: 'Other (direct bill only)'}
      ]
    },
    defaultVal: 'namedInsured'
  },
  {
    key: 'billingAddress', type: 'geocode', classList: 'w-75', tags: 'payer',
    anyJurisdiction: true,
    active: {
      $conditional: true,
      test: {chain: 'bindQuote.payerList', eq: 'other'}
    }
  },*/
  {
    key: 'billingDisplayAddress', type: 'text',
    tags: 'payer', readOnly: true,
    // With radio buttons removed, keep the display address active
    /*active: {
      $conditional: true,
      test: {chain: 'bindQuote.payerList', ne: 'other'}
    },*/
    val: {
      $conditional: true,
      $switch: [{
        test: {chain: 'bindQuote.payerList', eq: 'namedInsured'},
        result: { $compute: {chain: 'customer.address', prop: 'address' }}
      }, {
        $and: [
          {chain: 'bindQuote.payerList', eq: 'pm'},
          {chain: 'customer.propertyManagerAddress.street1', ne: null }
        ],
        result: {
          $compute: {chain: 'customer.propertyManagerAddress', prop: 'address' }
        }
      }, {
        $and: [
          {chain: 'bindQuote.payerList', eq: 'pm'},
          {chain: 'customer.propertyManagerAddress.street1', eq: null }
        ],
        result: {
          $compute: {chain: 'customer.propertyManagerDisplayAddress'}
        }
      }],
      // defaultVal: null
      /*These conditions ^^ will not be met while radio buttons are removed
      Leaving them in place for easy re-implimentation in a near future release
      The defaultVal should be null again once radio buttons are restored*/
      defaultVal: {$compute: {chain: 'customer.address', prop: 'address'}}
    }
  },
  {key: 'billingCareOf', lbl: 'C/O or Primary Contact', type: 'text', tags: 'contact', optional: true},
  {key: 'billingEmail', lbl: 'Email', type: 'text', tags: 'contact'},
  {key: 'billingPhone', lbl: 'Phone', type: 'phone', tags: 'contact', classList: 'w-75', optional: true},
  {
    key: 'printedName', type: 'text',
    placeholder: 'Please type your full name',
    lbl: 'Printed Name', width: '260px'
  },
  {
    key: 'electronicAgreement', type: 'bool', lbl: 'I agree to electronic approval'
  },
  {
    key: 'fraudStatement', type: 'bool', lbl: 'I have read the Fraud Statements above'
  },
  {
    key: 'files', type: 'data', val: []
  }
];
