<template>
  <div>
    <div class="detail text-center p-4" v-if="premiumPanel">
      <h4 class="mt-3" style="text-transform: none"><strong>Quote #</strong></h4>
      <h1 class="mb-4 text-primary" style="text-decoration: underline">{{ itemVal('quote.quoteNumber') }}</h1>
      <h4 style="text-transform: none">Estimated Premium</h4>
      <h1 class="mb-4 text-primary">
        <sup><small style="font-weight: 900">$</small></sup>
        {{ ttl('totalPremium',false) }}
      </h1>
      <div v-if="this.quoteData.baseline.terrorism">
        <h4 style="text-transform: none">Includes</h4>
        <div><strong>Terrorism Premium:</strong> {{ ttl('terrorismPremium') }}</div>
      </div>
    </div>
    <perfect-scrollbar style="max-height:600px;overflow: auto" v-else>
      <table class="summary m-2 mr-4 ml-4">
        <tbody>
        <tr v-if="!isGl">
          <td colspan="2"><h3 class="text-center" v-b-tooltip.hover="">Coverages Summary</h3></td>
        </tr>
        <tr v-if="!isGl">
          <td>Total Building Limit</td>
          <td>{{ aggTotal('buildingLimit') }}</td>
        </tr>
        <tr v-if="!isGl">
          <td>Total Business Personal Property Limit</td>
          <td>{{ getBPP }}</td>
        </tr>
        <tr v-if="!isGl">
          <td>Total Outdoor Property Limit</td>
          <td>$ {{ currency(outdoorAmt, false, false) }}</td>
        </tr>

        <tr v-if="!isGl">
          <td>Valuation</td>
          <td> {{getValuation}} </td>
        </tr>

        <tr v-if="!isGl">
          <td>Business Income</td>
          <td>{{ getBI }}</td>
        </tr>
        <tr v-if="!isGl">
          <td>Employee Theft</td>
          <td>{{ ttl('employeeTheftLimit') }}</td>
        </tr>
        <tr v-if="!isGl">
          <td>Trees and Shrubs (incl. wind)</td>
          <td>{{ ttl('treesShrubsLimit') }} / <span style="white-space: nowrap">{{
              ttl('treesShrubsAggregateLimit')
            }}</span></td>
        </tr>

        <tr v-if="!isGl">
          <td colspan="2"><h3 class="mt-1 text-center">Ordinance or Law</h3></td>
        </tr>
        <tr v-if="!isGl">
          <td>Coverage A:</td>
          <td>Included</td>
        </tr>
        <tr v-if="!isGl">
          <td>Coverage B:</td>
          <td>{{ buildingMin('demolitionLimit', 1, totals.ordinanceBLimit) }}</td>
        </tr>
        <tr v-if="!isGl">
          <td>Coverage C:</td>
          <td>{{ buildingMin('incrConstCost', 1, totals.ordinanceCLimit) }}</td>
        </tr>
        <tr>
          <td colspan="2"><h3 class="mt-1 text-center">Liability Coverage</h3></td>
        </tr>
        <tr>
          <td>General Liability Limits</td>
          <td>{{ ttl('glEachOccLimit') }} / <span style="white-space: nowrap">{{ ttl('glAggLimit') }}</span></td>
        </tr>
        <tr>
          <td>Damage to Premises Rented to You:</td>
          <td>{{ ttl('premierRentalLimit') }}</td>
        </tr>
        <tr>
          <td colspan="2"><h3 class="mt-1 text-center">Additional Insured Coverage</h3></td>
        </tr>

        <tr>
          <td>Board Members and Officers:</td>
          <td>{{ isIncluded('boardMembersIncluded') }}</td>
        </tr>
        <tr>
          <td>By Written Contract or Agreement:</td>
          <td>{{ isIncluded('byWrittenContractIncluded') }}</td>
        </tr>
        <tr>
          <td>Managers or Lessors of Premises:</td>
          <td>{{ isIncluded('managersOrLessorsIncluded') }}</td>
        </tr>
        <tr>
          <td>Off Site Board Meeting Venue:</td>
          <td>{{ isIncluded('offSiteBoardMeetingIncluded') }}</td>
        </tr>
        </tbody>
      </table>

    </perfect-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {quotemix} from '@/lib/quotemix';

export default {
  name: 'cov-summary',
  computed: {
    ...mapGetters('getQuote', ['quoteData', 'quoteBuildings']),
    isGl() {
      return this.quoteData.quote?.isGl ?? false;
    },
    totals(){
      return this.quoteData.quote?.totals ?? {};
    },
    isIncluded() {
      return (key) => {
        return this.totals[key] ? 'Included' : 'Excluded';
      };
    },
    ttl(){
      return (key, sign = true) => {
        let ttl = this.totals[key];
        if (this.isType.nullOrUndef(ttl)){
          ttl = 0;
        }
        else if (this.isType.snum(ttl)){
          ttl = Number(ttl);
        }
        return `${sign ? '$ ' : ''}${this.currency(ttl, 0, false)}`;

      };
    },
    getBI() {
      if (this.dataVersion < 2) {
        const aggBuildingTotal = this.aggTotal('bizIncPremLimit', false);
        return `$ ${ aggBuildingTotal !== '0' ? aggBuildingTotal : this.ttl('businessIncome', false) }`;
      } else {
        return `$ ${this.currency(this.quoteData.propertyCoverages.bizIncPremLimit ?? 0, 0, false)}`;
      }
    },
    getBPP() {
      return `$ ${this.currency(this.quoteData.propertyCoverages.bizPersonalProp ?? 0, 0, false)}`;
    },
    getValuation() {
      return this.buildings.map(b => this.itemFromChain(`${b.chain}.valuation`).selectedText)[0] ?? 'Extended Replacement Cost';
    },
    outdoorAmt(){
      return this.locations.reduce((amt, l) => {
        const locationTotal = this.itemVal(`${l.chain}.outdoorBlanketTotal`) ?? 0;
        return amt + Number(locationTotal);
      }, 0);
    },
    buildingMin() {
      return (key, sign = true, max = 0, defaultVal = 300000) => {
        let min =  this.buildings.length ? Math.min(...this.quoteBuildings.map(b => Number(b.dataTree[key]))) : 0;
        if (min === Infinity) {
          min = 0;
        }
        min = Math.max(min, Number(max));
        if (isNaN(min)){
          min = defaultVal;
        }
        return `${sign ? '$ ' : ''}${this.currency(min, 0, false)}`;
      };
    },
    aggTotal(){
      return (key, sign = true) => {
        let agg = this.quoteBuildings.reduce((total, b) => {

          let ident = Number(b.dataTree.identicalCt || 1);
          return total + ((Number(b.dataTree[key]) || 0) * ident);
        }, 0);
        return `${sign ? '$ ' : ''}${this.currency(agg, 0, false)}`;
      };
    }
  },
  mixins: [quotemix],
  props: ['premiumPanel']
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";
.detail {
  border: solid 1px $gray;
  background-color: $light;
  //border-radius: 8px;
  //height: 285px;
  //width: 100%;
  text-align: center;
}
table.summary{
  td{
    padding:3px 0;
  }
  td:nth-child(1){
    padding-right:12px;
    font-weight: 900;

  }
}
</style>
