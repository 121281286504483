<template>

  <div class="invis">secs:{{remSecs}}</div>

</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import {portalEvents} from '@/lib/mix';

export default {
  data: () => {
    return {
      warn: false,
      now: null

    };
  },
  computed: {
    ...mapState(['debug']),
    ...mapState(['sessionStart', 'lastUserInput', 'sessionTimeoutPeriod']),
    maxDur(){
      return this.sessionTimeoutPeriod / 1000;
    },
    elapsedSecs(){
      return Math.round(this.now / 1000) - Math.round(this.sessionStart / 1000);
    },
    remSecs(){
      return this.now ? this.maxDur - this.elapsedSecs : '00';
    },
    remMin(){
      return Math.floor(this.remSecs / 60);
    },
    elapsedMin(){
      return Math.floor(this.elapsedSecs / 60);
    },
    expired(){
      return  this.sessionId === null || this.elapsedSecs > this.maxDur - 1;
    },
    warning(){
      return this.elapsedSecs > this.maxDur - 65;
    },
    padMin(){
      return this.now ? this.pad(this.remMin) : 15;
    }
  },
  name: 'session-timer',
  methods: {
    ...mapMutations(['setAny']),
    ...mapActions(['logout']),
    storeLogout(){
      this.clickStreamEvent(portalEvents.Logout, 'OS:SessionExpired');
      this.logout(this.$auth);
    },
    update(){
      if (this.sessionId) {
        this.now = new Date().valueOf() + 999;
      }
    },
    setDebug(){
      let d = !this.debug;
      this.setAny({debug: d});
      console.log({d});
    },
    refresh(){
      this.oneShield('jurisdictionList').then(({response}) => {
        console.log({refreshSession: response});
      });
    }
  },
  created(){
    this.update();
  },
  mounted() {
    this.refresh();
    this.update();
    setInterval(() => this.update(), 1000);
    this.delayFn(() => this.modalTimer = false, 3333);
  },
  watch: {
    expired(exp){
      if (exp && this.sessionId){
        this.storeLogout();
      }
    },
    warning(warn){
      if (warn) {
          console.log('auto refresh active user');
          this.refresh();
      }
      this.warn = warn;
    }
  }
};
</script>


