<template>
  <div>

    <div class="d-flex mb-3">
      <div style="width:49%">
        <field-column :defs="storeFields('underwriting').filter(f => f.hasTag('col1'))" label-w="72%" field-w="28%"/>

      </div>
      <div style="width:51%" class="pl-3">
        <field-column :defs="storeFields('underwriting').filter(f => !f.hasTag('col1'))" label-w="32%" field-w="19%"/>

      </div>
    </div>
  </div>
</template>

<script>
import {quotemix, detailsTab} from '@/lib/quotemix';
import FieldColumn from '@/components/fields/field-column';

export default {
  data: () => {
    return {
      apiSaveFunction: 'updateUnderwritingDetails',
      quoteFields: ['quoteId', 'policyId', 'underwritingId', 'policyGLId', 'policyCommercialGLId'],
      tabName: 'underwriting'
    };
  },
  name: 'underwriting',
  mixins: [quotemix, detailsTab],
  components: {FieldColumn}
};
</script>

<style scoped>

</style>
