import {
  brokerDeductibles,
  hoaOpoDeductibles,
  liabilityClasses, liabilityVals,
  outdoorPropType,
  perilsDeductibles, stateIdByCode
} from '@/lib/fields/field-constants';
import typeDefs from '@/json/types-list.json';

export const locationFields = [
  'name', 'num', 'addressId', 'locationId', 'cfLocationId', 'scores',
  'liabilityCoverageId', 'classCodes', 'locationHazardId',
  'miscFurnCount', 'rateTier', 'hurricaneTier'
].map(key => ({key, type: 'data'}));



export const exposuresMultiselect = {
  lbl: 'General Liability Class (Select all that apply)',
  tags: 'exposures',
  minSelection: 1, type: 'multi', key: `liabilityClasses`,

  runtimeFilter: true,
  vals: liabilityVals,
  _val: Object.fromEntries(
    liabilityVals.map(({key}) => [key, false])
  )
};

//console.log(exposures.vals);

export const outdoorPropTypeList = Object.entries(outdoorPropType).map(([key, lbl]) => {
  return {
    lbl, key: `outdoorType${key}`,
    propertyClass: Number(key) === 1001 ? 2001 : 2002, //boats is only outlier
    propertyType: key,
    group: 'outdoorList',
    type: 'bool'
  };
});

export const miscOutdoorProp = [
  {
    key: 'perilsDeduct',
    classList: 'w-100',
    type: 'select',
    lbl: 'All Perils Deductible',
    tags: 'allowUnlisted',
    defaultVal: 5000,
    vals: {
      $conditional: true,
      $switch: [
        {
          test: {admin: true},
          result: perilsDeductibles
        },
        {
          $and: [
            { chain: 'quote.programCode', eq: 'HOA' },
            { chain: 'is1190::'}
          ],
          result: hoaOpoDeductibles
        }
      ],
      defaultVal: brokerDeductibles
    },
    lockable: true
  },
  {key: 'causeOfLoss', classList: 'w-50', type: 'select', lbl: 'Cause of Loss', vals: typeDefs.causesOfLoss },
  {key: 'valuationType', classList: 'w-100', type: 'select', lbl: 'Valuation Type', vals: typeDefs.valuation },
  {key: 'inflationGuard', classList: 'w-50', type: 'select', lbl: 'Inflation Guard', vals: typeDefs.inflationGuard },
  {key: 'agreedValue', type: 'yn', lbl: 'Agreed Value' },
  {key: 'describeOther', type: 'text', placeholder: 'Describe Other', classList: 'w-100',
    active: {
      $conditional: true, test: {chain: `sibling::outdoorType1012`, eq: true}
    }
  }, {
    key: 'outdoorBlanketTotal', lbl: 'Total Blanket Outdoor Property',
    labelTip: '$25,000 Tree and shrubs limit cannot be increased.',
    classList: 'w-100', type: 'currency', min: 1
  }
].map(field => {
  field.group = 'miscOutdoor';
  return field;
});
