<template>
  <b-modal :visible="true" :title="titleCased(title)" size="md" id="verify" hide-footer
           @hidden="select" :no-close-on-backdrop="true" :no-close-on-esc="true">
    <div v-if="['unverified','match-none','no-results'].includes(status)">We are not able to automatically verify the chosen address.</div>
    <div v-else-if="!addresses">We could not find a matching address.</div>
    <div v-else>
      <p class='headerMsg'>Did you mean any of these addresses?</p>
      <b-form-radio-group>
      <div v-for="item in addresses" :key="item.street1+item.zipcode">
        <b-form-radio v-model="selected" :value="item">{{ item.street1 }}, {{ item.city }} {{ item.state }}
          {{ item.zipcode }}
        </b-form-radio>
      </div>
      <b-form-radio v-model="selected" value="noMatch">None of these are correct</b-form-radio>
    </b-form-radio-group>
    </div>
    <div v-if="selected === 'noMatch'">
      <p class='supportMsg'>If this is a phasing community or you have other questions
        please contact LIO support at support@lioinsurance.com or call 1-877-546-7155 Monday through Friday between
        8:30am&nbsp;-&nbsp;8:00pm&nbsp;ET</p>
    </div>
    <div class='btnMsg'>
      <b-button variant="primary" size="md" @click="select">Continue</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      selected: null
    };
  },
  methods: {
    select() {
      if (['unverified', 'match-none', 'no-results'].includes(this.status)){
        this.$parent.resetAll();
      }else {
        this.$parent.correctAddress(this.selected);
      }
    }
  },
  name: 'AddressSuggestion',
  props: ['title', 'addresses', 'status'],
  mounted() {
    if (!Array.isArray(this.addresses) || this.addresses.length === 0){
      this.selected = 'noMatch';
    }
  }
};
</script>

<style lang="scss">
.headerMsg {
  text-align: center;
  margin-bottom: 20px;
}

.supportMsg {
  font-size: 11px;
  text-align: center;
  margin-top: 20px;
}

.btnMsg {
  text-align: center;
  margin-top: 20px;
  .btn-primary{
    border-radius: 25px;
    width:150px;
    &:hover{
      ///color:white;
      background-color: #ccc;
    }
  }
}
</style>
