<template>
  <div class="w-100 d-flex">
    <b-dropdown v-if="!locked"
        :boundary="def.size === 'lg' ? 'scrollParent' : 'viewport'"
        :autofocus="autofocus"
        :popper-opts="{ positionAbsolute: def.size !=='lg' }" :disabled="disable || empty"
        class="flex-fill" no-caret :variant="multitip ? 'light' : ''"
        :size="def.size" :id="def.guid" :style="styleObj" :chain="def.chain"
        :menu-class="'dynamic-menu'" :class="{animate}"
        @show="touch(true)" @blur="touch(true)" :state="fieldValid"
        toggle-class="ellipsize"
    >
      <template #button-content>
        <drop100-text :t=" multitip?'[multiple]' : def.selectedText" :w="def.width"/>
      </template>
      <b-dropdown-item @click="def.val = def.emptyVal" v-if="def.val && def.canReset">
        <i class="fas fa-trash" style="margin-top:3px;float:right;" :style="{color:lioTheme.gray}"></i>
        Clear
      </b-dropdown-item>
      <b-dropdown-item v-for="({key,lbl}, i) in def.vals" @click="def.val = key" :key="`select${def.key}${i}`">
        {{ lbl }}
      </b-dropdown-item>
    </b-dropdown>
    <span v-else class="flex-fill">{{def.val}}</span>
    <div v-if="lockable">
      <b-button :class="validClass" @click="toggleLock">
        <i class="fas" :class="{'fa-unlock': !locked, 'fa-lock': locked}"></i>
      </b-button>
    </div>
    <i v-else-if="locked" class="fas fa-lock"
       v-b-tooltip="`This field has been locked and cannot be modified. Please contact us if you have questions.`"></i>
  </div>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';
import Drop100Text from '@/components/util/drop100-text';
import {lioTheme} from '@/lib/lioTheme';

export default {
  components: {Drop100Text},
  mixins: [fieldmix],
  name: 'fieldtype_select',
  computed: {
    empty(){
      return !this.def?.vals?.length;
    },
    styleObj() {
      let {def, multitip} = this;
      return {
        maxWidth: def.width || '100%',
        backgroundColor: multitip ? lioTheme.lightGray : 'transparent'
      };
    },
    animate() {
      return this.def.animateEmpty && this.def.vals?.length === 0;
    },
    vals(){
      return this.def.vals;
    }
  },
  watch: {
    vals(vals){
      //track vals mutations via conditionals. Val may need to be reset
      if (!this.def.selectedItem && !this.def.hasTag('allowUnlisted')){
        if (vals?.length === 1){
          this.def.val = vals[0].key;
        }else {
          this.def.reset(true);
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

div.dropdown.animate {
  button {
    background-color: $light;
    pointer-events: none;

    i {
      color: transparent;
      background: conic-gradient(transparent 0%, $info 20%, darken($info, 20%) 50%, transparent 50%);
      height: 26px;
      width: 26px;
      overflow: hidden;
      //border:solid 1px $info;
      border-radius: 50%;
      position: relative;
      top: 2px;
      left: 0;
      transform: rotate(0deg);
      animation: Animation 1s linear infinite;

      &:before {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        height: 17px;
        width: 17px;
        background-color: $light;
        border-radius: 10px;
      }
    }
  }
}

@keyframes Animation {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

div.dropdown.valid {
  .dropdown-toggle {
    border-color: $success;

    i {
      color: $success;
    }
  }
}

div.dropdown.invalid {
  .dropdown-toggle {
    border-color: $danger;

    i {
      color: $danger;
    }
  }
}
</style>
