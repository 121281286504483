import {cleanseParams, clone, decodeXml, sortByKey} from '@/lib/util';
import {isType} from '@/lib/mytype';
import allFields from '@/lib/fields/fields';
import {liabilityVals, windHailDeductibleIdsByValue} from '@/lib/fields/field-constants';

// getApiBuildingsFromBuildings
export const decompressBuildings = (bldgs, extractFields) => {

  extractFields = [
    'name', 'locationId', 'addressId', 'externalId',
    'cfLocationId', 'identicalCt', 'identicalBuildings', 'bClass',
    'num', '_delete', 'constructionType', ...extractFields
  ];

  bldgs = bldgs.filter(b => b.isBuilding && (!!b.dataTree.num || !b.dataTree._delete)) // skip unsaved buildings (!num) that were deleted
    .flatMap((building) => {
      let bFields = Object.fromEntries(extractFields.map(key => [key, building.dataTree[key]]));
      if (building.isPseudo){
        bFields = {//default vals for outdoor only bld
          ...bFields,
          buildingLimit: 0,
          bClass: 10956,
          floorCt: 1,
          roofType: 1,
          totalBldgSqFt: 1,
          yearBuilt: (new Date()).getFullYear(),
          name: 'Outdoor Property Only'
        };
      }
      let location = building.parent.parent;
      bFields.cfLocationId = bFields.cfLocationId ?? location.dataTree.cfLocationId;
      let {scores} = location;
      // apply building data from location
      if (isType.object(scores)) {
        bFields.bgITerritory = scores?.bgITerritory.id ?? bFields.bgITerritory;
        bFields.bgIITerritory = scores?.bgIITerritory.id ?? bFields.bgIITerritory;
        bFields.ppc = scores?.ppc.id ?? bFields.ppc;
        bFields.specialTerritory = scores?.specialTerritory.id ?? bFields.specialTerritory;
        bFields.windHailDeductible = getWindHailDeductible(bFields, location, scores);
      }

      let identicalCt = Number(bFields.identicalCt || 1);
      let identField = building.child('identicalCt');
      if (!identField._originalVal){//0, null, undefined?
        identField._originalVal = 1;
      }
      const deleteCount = Number(identField._originalVal) - Number(identField.val);
      const shouldDecrease = deleteCount > 0;
      let masterId;
      // Expand identical building instances
      return [...Array(shouldDecrease ? Number(identField._originalVal) : identicalCt).keys()].map(i => {
        let buildingInstance = clone(bFields);
        if (shouldDecrease && i + 1 > identicalCt){
          buildingInstance._delete = true;
        }
        const buildingInstanceFields = bFields.identicalBuildings?.[i];
        buildingInstance.buildingUiRef = `${building.li}_${building.bi}_${i}`;
        buildingInstance.num = building.buildingNum;
        let buildingInstanceId = buildingInstanceFields?.buildingId;
        if (i === 0) {
          // use existing instance Id if exists or buildingUiRef as temp Id for new building
          masterId = buildingInstanceId ?? buildingInstance.buildingUiRef;
        }
        if (buildingInstanceFields) {
          buildingInstance = {
            ...buildingInstance,
            ...buildingInstanceFields
          };
        }
        delete buildingInstance.identicalBuildings;
        if (building.isPseudo) {
          buildingInstance.externalId = `::po::${masterId}::property only`;
        }else{
          buildingInstance.externalId = `clone::${i}::${identicalCt}::${masterId}`;
        }
        return buildingInstance;
      });
    });
  return bldgs;
};

const windHailDeductibleByConstructionType = {
  // Frame and Masonry
  '1': windHailDeductibleIdsByValue['5%'],
  '2': windHailDeductibleIdsByValue['5%'],
  // Non-Combustible
  '3': windHailDeductibleIdsByValue['2%'],
  '5': windHailDeductibleIdsByValue['2%'],
  // Fire Resistive
  '6': windHailDeductibleIdsByValue['1%'],
  '7': windHailDeductibleIdsByValue['1%']
};

function getWindHailDeductible(building, location, hazards) {
  if (location.dataTree.hurricaneTier === '1') {
    // None - HurricaneTier 1 means WindHailExclusion is set
    return windHailDeductibleIdsByValue['None'];
  }

  let hurricaneTierWindHail = windHailDeductibleIdsByValue['None'];

  // If in a coastal hurricane zone, calculate hurricane wind hail by construction type
  if (['2', '3'].includes(location.dataTree.hurricaneTier)) {
    hurricaneTierWindHail = windHailDeductibleByConstructionType[building.constructionType?.toString()] ?? windHailDeductibleIdsByValue['None'];
  }
  return Math.max(Number(hurricaneTierWindHail), Number(hazards.windstormHailDeductible.id)).toString();
}

// getBuildingsFromApiBuildings
export const compressBuildings = (buildingsList, buildingCoverages) => {
  let compressed = {};
  let oldParentIdByNewId = {};
  let childIdsByParentId = {};
  buildingsList.filter(b => b.externalId?.startsWith('clone::'))
    .forEach(building => {
      let [cloneIndex, identicalCt, parentId] = building.externalId.replace('clone::', '').split('::');
      let isParent = cloneIndex === '0';
      const buildingCoverage = buildingCoverages.find(coverage => building.buildingId === coverage.buildingId);
      if (buildingCoverage) {
        building = {
          ...building,
          ...buildingCoverage
        };
      }
      // keep track of children for original parentId (may be a temporary Id if the building was just created)
      childIdsByParentId[parentId] = childIdsByParentId[parentId] || [];
      childIdsByParentId[parentId][cloneIndex] = {
        index: cloneIndex,
        buildingId: building.buildingId,
        buildingSubjectId: building.buildingSubjectId,
        propertySubjectId: building.propertySubjectId,
        cfBuildingCoverageId: building.cfBuildingCoverageId,
        buildingBlanketId: building.buildingBlanketId,
        bppBlanketId: building.bppBlanketId
      };
      const buildingId = building.buildingId;
      // the children Ids are unique to their respective instances and are not shared
      delete building.buildingId;
      delete building.buildingSubjectId;
      delete building.propertySubjectId;
      delete building.cfBuildingCoverageId;
      delete building.buildingBlanketId;
      delete building.bppBlanketId;
      if (isParent) {
        // track mapping of old id to retrieve children
        oldParentIdByNewId[buildingId] = parentId;
        if (buildingId && parentId.includes('_')){
          // replace temporary id (for new building) with real OS id
          building.externalId = `clone::${[cloneIndex, identicalCt, buildingId].join('::')}`;
        }
        building.identicalCt = Number(identicalCt);
        compressed[buildingId] = building;
      }
    });
  buildingsList.filter(b => b.externalId?.startsWith('::po::'))
    .forEach(building => {
      building._isPseudo = true;
    });
  // Buildings OneShield creates for us don't have externalIds set. We need to hydrate them anyway.
  const defaultBuildings = buildingsList.filter(b => !b.externalId?.startsWith('clone::')).map(building => {
    const buildingCoverage = buildingCoverages.find(coverage => building.buildingId === coverage.buildingId);
    if (buildingCoverage) {
      building = {
        ...building,
        ...buildingCoverage
      };
    }
    const buildingInstanceIds = {
      index: 0,
      buildingId: building.buildingId,
      buildingSubjectId: building.buildingSubjectId,
      propertySubjectId: building.propertySubjectId,
      cfBuildingCoverageId: building.cfBuildingCoverageId,
      buildingBlanketId: building.buildingBlanketId,
      bppBlanketId: building.bppBlanketId
    };
    delete building.buildingId;
    delete building.buildingSubjectId;
    delete building.propertySubjectId;
    delete building.cfBuildingCoverageId;
    delete building.buildingBlanketId;
    delete building.bppBlanketId;
    return {
      ...building,
      identicalBuildings: [buildingInstanceIds]
    };
  });

  // add identicalBuilding Id references to building data
  compressed = Object.entries(compressed).map(([masterId, jangoFett]) => {
    jangoFett.identicalBuildings = childIdsByParentId[oldParentIdByNewId[masterId]];
    return jangoFett;
  });
  return [
    ...defaultBuildings,
    ...compressed
  ];

};

export const parseLocationData = (lists, stateData, updateField) => {
  let allBuildings = lists.allBuildings;
  let buildings = lists.buildings;

  let aggLiabilityClasses = Object.fromEntries(
    Object.keys(liabilityVals).map(item => [item.key, false])
  );

  let mainAddress = lists.addresses.find(a => a.isBilling || a.isMailing);
  if (mainAddress) {
    Object.entries(mainAddress).forEach(([prop, val]) =>
      updateField({chain: `customer.address.${prop}`, val})
    );
  } else{
    console.error('primary address not found', {
      locations: lists.locationMeta,
      addresses: lists.addresses
    });
  }
  lists.locationMeta = sortByKey(lists.locationMeta, 'num');

  let locations = (lists.locationMeta || []).map(({num, locationId, addressId, locationHazardId, hurricaneTier}) => {
    let location = {
      address: lists.addresses.find(a => a.addressId === addressId),
      hurricaneTier
    };

    let placeholderAddress = lists.addresses.find(a => !a.city);
    if (placeholderAddress){
      updateField({chain: 'quote.emptyAddressId', val: placeholderAddress.addressId});
    }

    location.outdoorProperties = lists.outdoorProperties.filter(p => p.locationId === locationId).sort((a, b) => {
      return Number(b.num) - Number(a.num);
    }).map((op, i) => {

      // Rectify the sorted properties in case OS skips numbers
      op.num = i + 1;
      return cleanseParams(op, true);
    });
    let locationPropertyCoverage = lists.propertyCoverages?.find(lc => lc.locationId === locationId);

    location.cfLocationId = locationPropertyCoverage?.cfLocationId;
    location.locationPropertyCoverage = locationPropertyCoverage;
    let locationLiabilityCoverage = lists.liabilityCoverages.find(lc => lc.locationId === locationId);
    location.liabilityCoverageId = locationLiabilityCoverage?.liabilityCoverageId;
    location.premOpsTerritory = locationLiabilityCoverage?.premOpsTerritory;
    location.rateTier = locationLiabilityCoverage?.rateTier;
    let locationLiabilities = lists.liabilities
      .filter(exposure => exposure.locationId === locationId)
      .reduce((liabilities, {
        liabilityAdditionalClassId,
        liabilityClassCode,
        liabilityClass, ...rest
      }) => {
        liabilities.classCodes = liabilities.classCodes || [];
        liabilities.liabilityClasses = liabilities.liabilityClasses || {};
        aggLiabilityClasses[liabilityClass] = true;
        liabilities.liabilityClasses[liabilityClass] = true;
        liabilities.classCodes.push({
          classCode: liabilityClassCode,
          additionalClassId: liabilityAdditionalClassId,
          classId: liabilityClass
        });

        // These are rehydrated into the field in the addLocation call
        liabilities.fetchedLiabilityClasses = liabilities.fetchedLiabilityClasses || {};
        liabilities.fetchedLiabilityClasses[liabilityClassCode] = true;

        // For the rest of the field properties, assign them to the root object if they have a value
        Object.entries(rest).forEach(([k, v]) => {
          if (!isType.nullOrUndef(v)) {
            liabilities[k] = v;
          }
        });
        return liabilities;
      }, {});
    if (locationLiabilities) {
      location = {...locationLiabilities, ...location};
    }
    if (isType.object(location)) {
      location.locationId = locationId;
      location.locationHazardId = locationHazardId;

    }
    let loc = stateData.locations.addLocation(location);
    let bldgs = allBuildings.filter(b => b.addressId === addressId)
      .map((b, bi) => {
        b.num = Math.max(1, bi + 1);
        b.key = `loc-${num}_bldg-${b.num}`;
        b.locationId = locationId;
        let bFields = Object.keys(b).map(key => {
          let v = decodeXml(b[key]);
          if (isType.object(v)){
            return {key, val: v};//todo: ensure label mapping->bind in fields.js?
          }
          return {key, val: v};
        });
        return allFields.addBuilding(num, b.num, b.name, bFields, bi);
      });
    let psi = bldgs.findIndex(b => b.isPseudo);
    if (psi > -1){//todo: why is main building not picking up pseudo
      buildings.push(bldgs[psi]);//hack workaround
    }else {
      buildings.push(...bldgs);
    }
    return loc;
  });

  if (locations && locations.length) {
    updateField({chain: 'quote.aggLiabilityClasses', val: aggLiabilityClasses});
  }
  if (!locations.length) {
    console.warn('no locations returned', lists);
  }

  return locations;
};
