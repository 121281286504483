<template>
  <div>
    <h1 class="m-4 text-center">ISSUE POLICY</h1>
    <div class="d-flex">
      <div class="flex-fill"></div>
      <div class="detail text-center mr-4 pt-4" style="height:inherit;width:333px" :style="{background:lioTheme.lightGray}">
        <div class="mt-4"><strong>Policy #</strong></div>
        <h2 class="mb-5">{{ itemVal('quote.policyNumber') }}</h2>
        <strong>Premium</strong>
        <h1 class="mb-5"><sup>$</sup>
          {{ ttl('totalPremium',false) }}

        </h1>
      </div>
      <div style="width:650px" class="p-4">
        <p class="large">Thank you for your bind order. Here's what to expect next:</p>
        <ul class="pl-4">
          <li class="large">Policy documents will be generated within the next 5-10 minutes and will be available for review within the
            <em>Policies</em> section of this portal.</li>
        </ul>
      </div>
      <div class="flex-fill"></div>
    </div>
    <div class="text-center mt-4">
      <router-link to="/">
        <b-button variant="info" size="lg">MAIN MENU</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {progressModal} from '@/lib/modal-service';

export default {
  computed: {
    totals(){
      return this.quoteData.quote?.totals;
    },
    ttl(){
      return (key, sign = true) => {
        let ttl = this.totals[key];
        if (this.isType.nullOrUndef(ttl)){
          ttl = 0;
        }
        else if (this.isType.snum(ttl)){
          ttl = Number(ttl);
        }
        return `${sign ? '$ ' : ''}${this.currency(ttl, 0, false)}`;

      };
    }
  },
  mounted() {
    //defensive (LIO-1662)
    progressModal(false);
  },
  mixins: [quotemix],
  name: 'quote-issue-policy'
};
</script>
<style>
.large{
  font-size: 18px;
}
</style>
