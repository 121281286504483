import {maxOSInt} from '@/lib/util';
import typeDefs from '@/json/types-list.json';
import {brokerDeductibles, perilsDeductibles, stateIdByCode} from '@/lib/fields/field-constants';

export const propertyCoverages = [
  { key: 'bizIncPremLimit', dataVersion: 2, type: 'currency', defaultVal: 150000,
    lbl: 'Business Income Coverage Limit', labelTip: 'Optionally add a higher coverage beyond included $150,000',
    range: [150000, maxOSInt], tags: 'col1', title: 'Business Income'},
  { key: 'moLmtIndem', dataVersion: 2, type: 'select', lbl: 'Monthly Limit of Indemnity', vals: typeDefs.moLmtIndem, tags: 'col1', optional: true },
  { key: 'bizIncAgreedValue', dataVersion: 2, type: 'yn', lbl: 'Agreed Value', group: 'addlCov', tags: 'col1', defaultVal: true, optional: true },
  { key: 'extPeriodDays', dataVersion: 2, type: 'select', lbl: 'Extended Period (days)',  vals: typeDefs.extPeriodDays, tags: 'col1', optional: true},
  { key: 'bizIncMaxDays', dataVersion: 2, type: 'yn', lbl: 'Maximum Period of Indemnity', tags: 'col1', optional: true},
  {
    key: 'bizPersonalProp', title: 'Additional Coverages', dataVersion: 2, lbl: 'Business Personal Property', classList: 'w-75',
    type: 'currency', tags: 'col2', optional: true, min: 1,
    val: {
      $conditional: true,
      runOnce: true,
      $compute: {
        chain: 'quote.blanketCoverages',
        find: {type: '2'},
        prop: 'limit'
      }
    }
  },
  {
    key: 'bppBlanketId', type: 'data', tags: 'col1',
    val: {
      $conditional: true,
      $compute: {
        chain: 'quote.blanketCoverages',
        find: {type: '2'},
        prop: 'blanketId'
      }
    }
  },
  { key: 'assocByLawsCov', dataVersion: 2, type: 'select', lbl: 'Association By-Laws Coverage', tags: 'col2', vals: typeDefs.assocByLawsCovTypes,
    active: {
      $conditional: true,
      $and: [
        {
          chain: 'quote.programCode',
          eq: 'COA'
        },
        {
          gl: false
        }
      ],
      keepVal: true
    }
  },
  { key: 'waterDamageDeductible', lbl: 'Water Damage Per Unit Deductible', type: 'select',
    canReset: true, tags: 'col2,allowUnlisted', lockable: true,
    vals: {
      $conditional: true,
      $switch: [{
        test: {admin: true},
        result: perilsDeductibles
      }],
      defaultVal: brokerDeductibles
    },
    active: {
      $conditional: true,
      $and: [
        { chain: 'quote.programCode', eq: 'COA' },
        { gl: false }
      ],
      optional: true
    }
  },
  {
    key: 'iceDamming',
    lbl: 'Ice Damming Per Unit',
    type: 'select',
    tags: 'col2',
    defaultVal: 5000,
    vals: perilsDeductibles,
    readOnly: {
      $conditional: true,
      test: {admin: false}
    }
  },
  {
    key: 'aopPerUnit',
    lbl: 'AOP Per Unit',
    type: 'yn',
    tags: 'col2',
    active: {
      optional: true,
      $conditional: true,
      $and: [
        { chain: 'quote.programCode', eq: 'COA' },
        {$or: [
          { chain: 'scope.jurisdiction', eq: stateIdByCode.UT },
          { chain: 'scope.jurisdiction', eq: stateIdByCode.SC }
        ]}
      ]
    }
  },
  { key: 'premierPropertyEnhancement', type: 'data', lbl: 'Premier Property Enhancement' }
];
