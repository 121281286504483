<template>
  <div class="add-location">
      <div class="d-flex flex-column">
        <div class="flex-fill"></div>
        <p class="text-center mb-5">
          Please enter all physically separate geographic locations that this policy will cover.
        </p>
        <div class="quote-panel" style="width:666px">
          <add-locations class="mb-4 mr-4 ml-4 mt-3" @setComponentRef="setLocationsRef"></add-locations>
        </div>
        <div class="flex-fill"></div>
      </div>
      <div class="text-center mt-5" style="position: relative">
        <b-button size="lg" variant="info" class="quote-nav-button btn-secondary mr-4" @click="$parent.inMemComponent = 'eligibility'">Back</b-button>
        <b-button size="lg" :disabled="!locations.every(l=>l.addressDef.dataTree.verified)" class="quote-nav-button" variant="primary" @click="$parent.inMemComponent = 'customer'">Continue</b-button>
        <div class="exit-link">
          <a @click="$parent.exit">Exit to the main menu</a>
        </div>
      </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import AddLocations from '@/components/quote-steps/shared/add-locations';

export default {
  components: {AddLocations},
  data: () => {
    return {
      sameAsMailing: true,
      addLocComponent: null
    };
  },
  computed: {
    ...mapGetters('getQuote', ['itemVal', 'enumValObj', 'locations'])
  },
  mixins: [quotemix],
  name: 'quote-location',
  methods: {
    ...mapMutations('getQuote', ['addFieldDefs', 'rehydrate', 'spliceGroup', 'updateField']),
    ...mapActions('getQuote', ['getQuoteDetail']),
    setLocationsRef({vm}){
      this.addLocComponent = vm;
    }
  }
};
</script>
