<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'LoginCallback',
  async beforeMount () {
    try {
      await this.$auth.handleLoginRedirect();
    } catch (error) {
      console.error(error);
      let loginError = '0';
      if (error.message?.includes?.('not assigned to the client application')) {
        loginError = 'not-authorized';
      }
      this.$router.replace({path: `/login?err=${loginError}`});
    }
  },
  mounted () {
    if (!this.$auth.isLoginRedirect()) {
      console.log('Not in login redirect, redirecting...');
      this.$router.replace({path: '/'});
    }
    this.$router.replace({path: `/login` });
  },
  render() { 
    return this.$scopedSlots.default && this.$scopedSlots.default({});
  }
});
</script>
