<template>
  <div class="d-none">{{dataFields.length}}</div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {isType} from '@/lib/mytype';

export default {
  name: 'selection-proxy',
  data: () => {
    return {
      synching: false,
      debounceTimer: null
    };
  },

  computed: {
    ...mapGetters('getQuote', ['itemFromChain', 'buildings', 'flatFieldList']),
    multiUpdated(){
      return this.def.state === 'multi' && this.dataFields.find(f => f.dirty);
    },
    multitip(){
      let {state, chains, vals, selectionVals, type} = this.def;

      let hasTip = !this.labelsOnly && state && state === 'multi' ? 'Multiple Values' : null;

      if (hasTip){
        let uniq = {};
        selectionVals.forEach((v, i) => {
          if (uniq[v]){
            uniq[v].push(chains[i]);
          }else{
            uniq[v] = [chains[i]];
          }
        });
        if (Object.keys(uniq).length === 1){
          return false;
        }

        return Object.keys(uniq).map(v => {
          let lbl = v === 'null' ? 'Not set' :
            type === 'select' ?
              vals.find(kv => `${kv.key}` === `${v}`) : v;

          if (type === 'select' && this.isType.object(lbl)){
            lbl = lbl.lbl;
          }
          let count = uniq[v].length;
          if (lbl === 'null'){
            lbl = 'Not set';
          }
          return {lbl, count};

        });
      }
      return false;
    },
    dataFields(){
      return (
        this.flatFieldList.filter(entity =>
          entity.isEntity && entity.hasTag(this.def.selectionProxy) && entity.selected)
          .map(entity =>  entity.child(this.def.key))
      ).flat();
    },
    itemVal(){
      return this.def.val;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField']),

    // calculate field display from remote sources
    syncFields(instant){
      let fields = this.dataFields;
      //debugger;
      if (fields.length){
        const fanVals = () => {
          this.synching = true;//prevent update of fields via itemVal watcher
          let chains = fields.map(f => f.chain);
          let selectionVals = fields.map(f => f.val);
          let val = isType.nullOrUndef(selectionVals[0]) ? null : selectionVals[0];
          let state = selectionVals.length < 2 || selectionVals.every(v => v === selectionVals[0]) ?
            'clean' : 'multi';
          let props = {state, chains, selectionVals, touched: false, blurred: false, val};
          this.updateField({chain: this.def.chain, props});
          this.delayFn(() => this.synching = false);
        };
        clearTimeout(this.debounceTimer);
        if (instant === true){
          return fanVals();
        }
        this.debounceTimer = setTimeout(() => this.delayFn(fanVals), 100);
      }
    }
  },
  mounted(){

    this.syncFields();
  },
  props: ['def', 'labelsOnly'],
  watch: {
    dataFields(cur, prev){

      let chains = flds => flds.map(f => f.chain).join('');
      //console.log({cur: chains(cur), prev: chains(prev)});
      this.syncFields(chains(cur) !== chains(prev));

    },
    itemVal(val){

      if (!this.synching && (this.def.touched || this.def.state !== 'multi')) {
        const props = {val, touched: true};
        this.dataFields.forEach(({chain}) => this.updateField({chain, props}));
      }
    },
    multiUpdated(mup){
      if (mup){
        this.syncFields();
      }
    }
  }
};
</script>

<style scoped>

</style>
