import {mapGetters, mapMutations, mapState} from 'vuex';
import {isType} from '@/lib/mytype';
import {lookupError} from '@/lib/error';
import eventbus from '@/lib/eventbus';


export default {
  computed: {
    ...mapGetters('getQuote', ['computedVal', 'itemVal', 'itemFromChain']),
    ...mapState('getQuote', ['saving', 'lockedFields']),
    lockable(){
      return isType.bool(this.def.locked) && this.isAdmin;
    },
    locked(){
      return Boolean(this.def.locked || this.lockedFields[this.def.chain]);
    },
    multitip(){
      let {state, chains, vals, selectionVals, type} = this.def;

      let hasTip =  state && state === 'multi' ? 'Multiple Values' : null;

      if (hasTip){
        let uniq = {};
        selectionVals.forEach((v, i) => {
          if (uniq[v]){
            uniq[v].push(chains[i]);
          }else{
            uniq[v] = [chains[i]];
          }
        });
        if (Object.keys(uniq).length === 1){
          return false;
        }

        return Object.keys(uniq).map(v => {
          let lbl = v === 'null' ? 'Not set' :
            type === 'select' ?
              vals.find(kv => `${kv.key}` === `${v}`) : v;

          if (type === 'select' && this.isType.object(lbl)){
            lbl = lbl.lbl;
          }
          let count = uniq[v].length;
          if (lbl === 'null'){
            lbl = 'Not set';
          }
          return {lbl, count};

        });
      }
      return false;
    },
    fieldValid(){
      if (this.multitip){
        return null;
      }
      let valid = !this.def.isRequired ? null : this.def.isValid;
      let {touched, blurred} = this.def;
      if (!blurred){
        return undefined;
      }
      if (valid !== false){
        return valid;
      }if (!touched || !blurred){
        return undefined;
      }
      return false;
    },
    autofocus(){
      return this.def.autoFocus ?? undefined;
    },
    disable(){
      return this.saving || this.disabled || this.def.disabled;
    },
    validClass(){
      return {
        valid: this.fieldValid === true,
        invalid: this.fieldValid === false,
        neutral: isType.nullOrUndef(this.fieldValid)
      };
    }
  },
  props: ['def', 'disabled'],
  methods: {
    ...mapMutations('getQuote', ['updateField', 'setAny']),
    touch(focus = true){
      this.def.touched = true;
      this.def.blurred = !focus;
    },
    setProp(prop, val){
      this.def[prop] = val;
    },
    toggleLock(isRevert){
      let {locked} = this.def;
      locked = !locked;
      //update in mem and propertyBag
      let {lockedFields} = this;
      this.updateField({chain: this.def.chain, val: locked, prop: 'locked'});
      lockedFields[this.def.chain] = locked;
      this.setAny({lockedFields});
      if (isRevert === true){
        return;
      }
      this.propStore(this.itemVal('quote.quoteId'), {lockedFields}).then(({response}) => {
        if (response.hasErrors){
          response.errorId = 'fieldlock';
          response.error = lookupError(response);
          eventbus.$emit('apiError', response);
          this.toggleLock(true);
        }
        else {
          console.log(`${this.def.chain} -> ${locked ? '' : 'un'}locked`);
        }
      });

    }
  }
};

