<template>
  <b-dropdown
    boundary="viewport" :autofocus="autofocus"
    :popper-opts="{ positionAbsolute: true }"
    :class="{'w-100':!def.width, ...validClass}" no-caret :chain="def.chain"
    :size="def.size" :id="def.guid" :disabled="disable" @show="touch(true)"
    @hide="touch(false)" :state="fieldValid" :data-valid="fieldValid">
    <template #button-content>
      <drop100-text :t="def.val" :yn="true"/>
    </template>
    <b-dropdown-item @click="def.val = true">
      Yes
    </b-dropdown-item>
    <b-dropdown-item @click="def.val = false">
      No
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';
import Drop100Text from '@/components/util/drop100-text';

export default {
  components: {Drop100Text},
  mixins: [fieldmix],
  name: 'fieldtype_yn'
};
</script>

<style scoped>

</style>
