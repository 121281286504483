<template>
  <div class="d-flex selection-head">
    <h4
        class="d-inline-block p-2"
        :style="{background:lioTheme[selection.length ? 'lightGray' : 'gray']}"
        >
      {{ selection.length }} Building Types selected
    </h4>
    <valign-mid>
      <div class="ml-3">
        <h3 v-if="selectedLocations.length===1">Location //
          <span>
            {{ itemFromChain(`${selectedLocations[0].chain}`).address }}
          </span>
        </h3>
        <h3 v-else-if="selectedLocations.length">{{ selectedLocations.length }} Locations</h3>
        <div v-if="!selection.length">Make a selection on the left to proceed.</div>
      </div>
    </valign-mid>
  </div>
</template>

<script>
import ValignMid from '@/components/util/valign-mid';
import {quotemix} from '@/lib/quotemix';
import {buildingsMix} from '@/lib/mix';

export default {
  data: () => {
    return {
      watchOnly: true,
      filterPanel: 'none'
    };
  },
  name: 'building-selection',
  components: {ValignMid},
  mixins: [quotemix, buildingsMix]
};
</script>

<style lang="scss">
.selection-head{
  h4{
    border-radius: 6px;
    border:solid 1px #ccc
  }
  h3{
    color:black !important;
    span {
      font-weight: 400
    }
  }
}
</style>
