<template>
  <div class="yn-radio">
    <b-form-radio-group
        :id="def.guid"
        v-model="def.val"  :chain="def.chain"
        :options="options"
    />
  </div>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  data: () => {
    return {
      options: [
        {text: 'Yes', value: true},
        {text: 'No', value: false}
      ]
    };
  },
  mixins: [fieldmix],
  name: 'fieldtype_yn-radio'
};
</script>

<style lang="scss">
div.yn-radio{
  div.custom-control {
    &.custom-control-inline {
      margin-right: 25px;
    }
  }
}
</style>
