<template>
  <b-input
      :disabled="disable" :autofocus="autofocus"
      @focus="touch(true)" @blur="touch(false);" :state="fieldValid" :autocomplete="def.autocomplete"
      v-model="def.val" :placeholder="def.placeholder" :id="def.guid" :chain="def.chain"
      v-b-tooltip.hover="def.isValid?'':def.validationTip" :maxlength="def.maxlength"
  />
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  mixins: [fieldmix],
  name: 'fieldtype_text'
};
</script>

<style scoped>

</style>
