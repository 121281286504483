<template>
  <div class="debugger"
       :style="{'--d1':'red','--dot':lioTheme[sessionRemain>25?'success':sessionRemain > 10 ? 'warning' : 'danger']}">
    <a @click="$store.state.debug=true" v-if="!debug && !inProd">
      <i class="fas fa-eye" style="color:#aaa"></i>
    </a>
    <div v-else-if="debug">
      <div class="d-flex" style="background-color: white;">
        <b-dropdown :text="treeFilterType" size="sm">
          <b-dropdown-item v-for="ft in treeFilterTypes" @click="treeFilterType = ft" :key="ft">{{ft}}</b-dropdown-item>
        </b-dropdown>

        <b-btn v-if="recording" size="sm" @click="recording=false" v-b-tooltip.hover.bottom="'Stop recording'">
          <i class="fas fa-stop"></i>
          Stop
        </b-btn>
        <b-btn v-else size="sm" @click="recording=true" v-b-tooltip.hover.bottom="'Begin recording user interaction session to DataDog'">
          <i class="fas fa-dot-circle text-danger"></i>
          Record
        </b-btn>
        <b-check size="sm" class="mt-1 ml-2" v-if="oktaUser.partnerId && local" v-model="admin" v-b-tooltip="`experimental`" switch>Admin</b-check>
        <a class="close flex-fill text-right" @click="$store.state.debug=false">
          <i class="fas fa-times-circle"></i>
        </a>
      </div>

      <div style="margin-bottom: -1px;margin-top:3px;position: absolute;top:17px;left:0;z-index: 2"
           class="pt-1 pl-2 w-100">
        <div class="float-right d-inline-block text-right mr-1">
          <small class="mr-1"><router-link to="/sandbox">Env:</router-link></small>

          <b-dropdown size="sm" :text="apiEnv">
            <b-dropdown-item v-for="e in environments" :key="e" @click="switchEnv(e)">{{ e }}</b-dropdown-item>
          </b-dropdown>
        </div>

      <small :class="{'text-danger': !sessionId}">Session ID:</small>
      <b-badge
          style="width:77px;cursor: pointer;margin-left:3px"
          v-b-tooltip.hover.bottom="'Hard Refresh Session - may affect access to locked objects associated with your session'"
          @click="refreshSession"
          :variant="sessionRemain>25?'success':sessionRemain > 10 ? 'warning' : 'danger'">
        {{ refreshing ? '- refreshing -' : sessionId || 'null' }}
      </b-badge>
      <div class="session-bar">
        <div
            :style="{width:`${sessionRemain}%`, background:lioTheme[sessionRemain>25?'success':sessionRemain > 10 ? 'warning' : 'danger']}"></div>
      </div>
    </div>

    <json-viewer v-if="showTree" :value="ezTree" :expand-depth="treeDepth" style="margin: -1px -5px 0;z-index: 1"/>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import eventbus from '@/lib/eventbus';
import {datadogRum} from '@datadog/browser-rum';

export default {
  data: () => {
    const inProd = process.env.DEFAULT_ENV === 'prod';
    const environments = ['dev', 'int', 'qa', 'uat', 'suppdev', 'suppuat'];

    if (location.href.includes('localhost')){
      environments.push('local');
    }
    if (inProd) {
      // Only accessible via debugger in prod
      environments.push('prod');
    }
    return {
      showTree: true,
      recording: false,
      quoteEffectiveOff: 0,
      admin: null,
      inProd,
      treeFilterTypes: ['grouped tree', 'tree', 'raw tree', 'flat list'],
      treeFilterType: 'grouped tree',
      refreshing: false,
      filterDebug: true,
      validation: true,

      env: null,
      environments,
      now: (new Date()).valueOf(),
      fifteen: 60000 * 15,
      eventBus: {
        osReady: eventbus.osReady,
        awaitingSession: eventbus.awaitingSession,
        gating: eventbus.curQuoteId,
        curReq: eventbus.currentGatedReq,
        activeQuoteQueue: eventbus.activeQuoteQueue.map(({operation, guid}) => {
          return {operation, guid};
        }),
        queue: eventbus.queue.map(({operation, guid}) => {
          return {operation, guid};
        })
      }
    };
  },
  computed: {
    ...mapState(['debug', 'sessionStart', 'debugOptions', 'oktaUser', 'local']),
    ...mapState('getQuote', ['quoteFields', 'lockedFields']),
    ...mapGetters('getQuote', ['quoteData', 'itemVal', 'hasChanges', 'flatFieldsObject']),


    sessionRemain() {

      let {refreshing, now, sessionStart, fifteen} = this;
      if (refreshing) {
        return 0;
      }
      return 100 - (100 * ((now - sessionStart) / fifteen));
    },
    unsavedChanges(){
      let mutations = this.hasChanges({requireTouch: true});
      if (mutations.length){
        return Object.fromEntries(
            mutations.map(([chain, unsavedVal, originalVal]) => {
              let ch = chain;
              if (chain.includes('.buildings.')){
                chain = `buildings${chain.split('buildings')[1]}`;
              }
              return [chain, {
                unsavedVal, originalVal,
                field: this.flatFieldsObject[ch]}];
            })
        );
      }
      return undefined;
    },
    treeDepth(){

      return this.treeFilterType.includes('grouped') ?
          3 : 2;
    },
    ezTree(){
      let {unsavedChanges, eventBus} = this;
      let tree = {
        eventBus,
        lockedFields: this.lockedFields,
        unsavedChanges
      };
      let treeType = this.treeFilterType;
      if (treeType === 'raw tree'){
        tree.quoteFields = this.unfiltered;

      }else if(treeType === 'flat list'){
        tree.quoteFields = this.flatFieldsObject;

      }else {
        let getter = treeType.includes('grouped') ? 'groupedTree' : 'dataTree';
        let entries = Object.entries(this.quoteFields).map(([key, entity]) =>
            [`${key}${getter === 'groupedTree' ? '*' : ''}`, entity[getter]]);
        tree.quoteFields = Object.fromEntries(entries);
      }

      return tree;
    },
    unfiltered() {
      let data = {};
      Object.keys(this.quoteFields).forEach(group => {
        if (this.quoteFields[group]) {
          data[group] = this.quoteFields[group];
        }
      });
      return data;
    }
  },
  methods: {
    ...mapMutations(['setRoot', 'setOSUser']),
    ...mapMutations('getQuote', ['setAny', 'updateField']),
    ...mapActions(['debugOption']),
    refreshSession() {
      this.refreshing = true;
      eventbus.$emit('refreshSession', true);
    },
    switchEnv(e){
      this.env = e;
      let osUser = this.osUser;
      osUser.sessionId = null;
      this.setRoot({sessionStart: null});
      this.setOSUser(osUser);
      eventbus.sessionId = null;
      eventbus.ready = false;
      eventbus.apiEnv = e;
      eventbus.$emit('refreshSession', true);
      this.debugOption({apiEnv: e});
    },
    spoofAdmin(flag){
      this.debugOption({admin: flag});
    }
  },
  mounted() {
    eventbus.$on('queueUpdate', ({gating, curReq, queue}) => {
      this.eventBus.gating = gating;
      this.eventBus.curReq = curReq;
      this.eventBus.queue = queue;
    });
    this.delayFn(() => {
      this.admin = this.isAdmin;
      this.env = this.apiEnv;
      this.quoteEffectiveOff = this.debugOptions.quoteEffectiveOff;
    }, 1234);
    setInterval(() => this.now = new Date().valueOf(), 1234);
  },
  props: ['canClose'],
  name: 'debugger',
  watch: {
    admin(admin){
      this.debugOption({admin});
    },
    treeFilterType(){
      this.showTree = false;
      this.delayFn(() => this.showTree = true);
    },
    recording(record){
      if (record){
        datadogRum.startSessionReplayRecording();
      }else{
        datadogRum.stopSessionReplayRecording();
      }
    },
    quoteEffectiveOff(offset){
      this.debugOption({quoteEffectiveOff: Number(offset)});
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.debugger {
  @keyframes blinkDot {
    0% {
      background: $success;
      box-shadow: 0 0 0 $success;
    }
    15% {
      background: darken($success, 10%);
      box-shadow: 0 0 2px darken($success, 10%);
    }
    50% {
      background: $success;
      box-shadow: 0 0 0 $success;
    }
    85% {
      background: lighten($success, 10%);
      box-shadow: 0 0 2px lighten($success, 10%);
    }
    100% {
      background: $success;
      box-shadow: 0 0 0 $success;
    }
  }

  .session-bar {
    width: 77px;
    background-color: rgba(255, 255, 255, .9);
    height: 3px;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 0px;
    left: 62px;
    z-index: 1;

    div {
      z-index: 2;
      height: 1.5px;
      position: absolute;
      top: 0;
      left: 1px;
      background-color: $success;

      &:after {
        position: absolute;
        content: '';
        right: -.5px;
        width: 2px;
        top: -.25px;
        border-radius: 3px;
        height: 2px;
        animation-name: blinkDot;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        /*animation-direction: alternate;*/
      }
    }
  }

  height: auto;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 1px;
  right: 0;
  z-index: 1000;
  border: dotted 1px $gray;
  border-radius: 4px;

  a.close {
    /* position:absolute;
    top:2px;
    right:2px;*/
    cursor: pointer;
    z-index: 1001;
  }

  .jv-container.jv-light {
    z-index: 0;
    background-color: rgba(255, 255, 255, .75);

    .jv-code {
      padding-bottom: 5px;
      padding-right: 0;
    }
  }
}
</style>
