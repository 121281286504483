export const policy = [
  {
    lbl: 'Do you have an existing policy?', autoFocus: true,
    chain: 'policy.hasExisting', type: 'yn'
  },
  {
    lbl: 'Prior Policy Expiration Date',
    chain: 'policy.priorExpiry', type: 'date', min: new Date(), max: new Date(2050, 0, 1),
    active: { $conditional: true, test: {chain: 'policy.hasExisting', eq: true}, keepVal: true}
  },
  {
    lbl: 'Desired Policy Effective Date',
    chain: 'policy.effective', type: 'date', min: new Date(),
    max: (d => {
      return new Date(d.getFullYear(), d.getMonth() + 4, d.getDate());
    })(new Date())
  },
  {
    lbl: 'Policy Expiration Date',
    chain: 'policy.expiry', type: 'date', readOnly: true
  },
  {
    lbl: 'Have there been losses in the last 4 years?',
    chain: 'policy.hasLosses', type: 'yn'
  },
  {
    lbl: 'Total incurred loss amount in last 4 years', tags: 'impactsRateTier',
    chain: 'policy.totalLosses', type: 'currency', classList: 'w-100',
    active: { $conditional: true, chain: 'policy.hasLosses', eq: true},
    labelTip: 'Total should include allocated loss adjustment expense (ALAE)'
  },
  {
    lbl: 'Has there been a lapse in coverage',
    chain: 'policy.hasLapse', type: 'yn',
    active: { $conditional: true, chain: 'policy.hasExisting', eq: true, keepVal: true}
  },
  {
    lbl: 'Prior Policy Expiring Premium',
    chain: 'policy.expiringPremium', type: 'currency',
    classList: 'w-100', tags: 'impactsRateTier',
    active: { $conditional: true, chain: 'policy.hasExisting', eq: true, keepVal: true}
  },
  {
    key: 'terrorism',
    lbl: 'Certified Acts of Terrorism',
    labelTip: 'Declining terrorism requires a document signature and upload at bind.  Document available as part of Proposal document',
    type: 'select', classList: 'w-100',
    vals: [{ key: true, lbl: 'Accept' }, { key: false, lbl: 'Do not Accept' }]
  },
  {
    // This is a "data" field, but needs to be picked up by apiParams so use !when instead
    chain: 'policy.terrorismTerritory', type: 'select', classList: 'd-none', vals: [], optional: true
  }
];
