<template>
<b-modal id="cancelQuote" :title="`Cancel Quote ${quoteNum}`" v-model="show">
  <div class="d-flex" v-if="canceling">
    <div class="w-25"></div>
    <div class="w-50"><progress-bar>Canceling</progress-bar></div>
    <div class="w-25"></div>
  </div>
  <div v-else>
    <p>Cancelation Reason (optional)</p>
    <b-textarea rows="3" v-model="reason"/>
  </div>
  <template  #modal-footer>
    <div class="text-center">
      <b-button size="lg" variant="info" @click="show=false">
        DO NOT CANCEL
      </b-button>
      <b-button size="lg" variant="primary" @click="cancel" :disabled="!isType.string(reason) || !reason.length">
        CANCEL QUOTE
      </b-button>

    </div>

  </template>
</b-modal>
</template>

<script>
import ProgressBar from '@/components/util/progress-bar';
export default {
  components: {ProgressBar},
  data: () => {
    return {
      canceling: false,
      show: false,
      reason: null
    };
  },
  methods: {
    cancel(){
      let {id, reason} = this;
      this.canceling = true;
      this.oneShield('cancelQuote', {quoteId: id, reason}).then(() => {
        this.canceling = false;
        this.show = false;
        this.reason = null;
        this.$emit('canceled');
      });
    }
  },
  name: 'cancel-quote',
  props: ['id', 'quoteNum'],
  watch: {
    quoteNum: {
      handler(num) {
        this.show = !!num;
        this.reason = null;
      },
      deep: true
    }
  }
};
</script>

<style scoped>

</style>
