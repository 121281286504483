import allFields from '@/lib/fields/fields';
//import {allStatusStrings} from '@/lib/field-constants';

export default {
  step: 'scope',
  activePath: [], //scope, eligibility,customer, etc
  pages: {
    scope: {
      title: `Hi, let's get started.`, next: 'eligibility',
      breadcrumb: 'Scope', route: false
    },
    eligibility: {
      title: `First, let's check eligibility.`, next: 'locations',
      breadcrumb: 'Eligibility', route: false
    },
    locations: {
      title: `What location is being insured?`, next: 'customer',
      breadcrumb: 'Location', route: false
    },
    customer: {
      title: `Who is being insured?`, breadcrumb: 'Insured', next: 'details',
      route: false
    },
    details: {
      title: `Let's get into the details.`, subtitle: '', breadcrumb: 'Details',
      next: {
        when: [{chain: 'quote.isGl', eq: true, page: 'exposures'}],
        default: {page: 'buildings'}
      }
    },
    'bind-quote': {
      title: `Almost Done! Let's review and finish up.`, breadcrumb: 'Bind Quote'/*, subtitle: ` - # ::qid::`*/, next: 'issuePolicy'
    },
    'issuePolicy': {
      title: `You're all set! The policy is bound.`, breadcrumb: 'Policy Issuance', hideTitle: true
    }
  },
  quoteFields: allFields.quoteFields,

  quotePropertyBagMap: {
    hasPropertyManager: 'customer.hasPropertyManager',
    propertyManagerName: 'customer.propertyManagerName',
    propertyManagerAddress: 'customer.propertyManagerAddress',
    propertyManagerDisplayAddress: 'customer.propertyManagerDisplayAddress',
    propertyManagerId: 'customer.propertyManagerId',
    customerId: 'customer.customerRegistryId',
    ratingJobId: 'quote.ratingJobId',
    quoteKey: 'quote.quoteKey',
    quoteInfo: 'quote.quoteInfo',
    lastRating: 'quote.lastRating',
    incumbentCarrier: 'customer.incumbentCarrier',
    billingCareOf: 'bindQuote.billingCareOf',
    billingEmail: 'bindQuote.billingEmail',
    billingPhone: 'bindQuote.billingPhone'
  },
  lockedFields: {},
  remIncomplete: [],
  quotes: [],
  policies: [],
  error: null,
  retryOnNextError: null,
  locationIndex: 0,
  navigationPath: [],
  buildingTypeIndex: 0,
  buildingFieldsValid: false,
  buildingFilter: false,
  isRating: false,
  env: 'dev',
  saving: false
};
//console.log({allthefields:allFields.quoteFields})
