<template>
  <b-container class="get-quote" fluid>

    <div class="head-bg"></div>
    <div class="d-flex mt-4" style="position:relative;height:88px">
      <logo class="mr-4"/>
      <align-middle>
        <h1>Schema Explorer
          <div class="d-flex">
<!--            <b-button-group>
              <b-button v-for="m in modes" :key="m" :variant="`${m===mode?'primary':''}`" @click="mode=m">
                {{titleCased(m)}}
              </b-button>
            </b-button-group>-->
            <div class="d-flex ml-3" style="height:36px">
              <b-button-group>
                <b-button @click="mode='schemaViewer'" :variant="mode==='schemaViewer'?'primary':''">XSD</b-button>
                <b-button @click="mode='semaniticJson'" :variant="mode==='semanticJson'?'primary':''">Semantic JSON</b-button>
              </b-button-group>
              <b-button-group v-if="mode==='schemaViewer'">
                <b-button disabled style="background-color: #eee;color:black!important;"><strong
                    style="color:black !important;">View:</strong></b-button>
                <b-button
                    v-for="key in Object.keys(json)" :key="key"
                    :variant="displayType ===key?'info' : 'secondary'"
                    @click="displayType=key"
                >{{ key }}
                </b-button>
                <b-button disabled style="background-color: #eee;color:black!important;width:191px;text-align: right"><strong
                    style="color:black !important;">Expand&nbsp;depth:</strong></b-button>
                <b-button v-for="n in 7" :key="n" :variant="depth ===n?'primary' : 'secondary'"
                          @click="depth=n">{{ n }}
                </b-button><b-input v-model="typeQuery" class="w-25" placeholder="Find Type" v-if="displayType.toLowerCase().includes('types')"/>
              </b-button-group>
            </div>
          </div>
        </h1>
      </align-middle>

    </div>

    <b-row>
      <b-col cols="12">
        <div class="quote-panel" style="background-color: white">
          <strong v-if="loading">Loading Data...</strong>
          <div v-resize="checkLoading">
            <json-viewer :value="json[displayType]" :expand-depth="depth"
                         v-if="render && mode==='schemaViewer'" sort theme="xsd-json-theme"
            />
            <div v-else>
              <h3>Semantic JSON Common Attributes</h3>
              <json-viewer :value="semJson" :expand-depth="5"
                           sort theme="xsd-json-theme"
              />
              <div class="d-flex mt-3">
                <div class="flex-fill mr-2">
                  <h3>Other Attributes Grouped by Attribute Name</h3>
                  <json-viewer :value="semanticAttribs" :expand-depth="2"
                               sort theme="xsd-json-theme"
                  />
                </div>
<!--                <div class="flex-fill mr-2">
                  <h3>Other Attributes Grouped by Attribute Name</h3>
                  <json-viewer :value="semJsonByAttr" :expand-depth="2"
                               sort theme="xsd-json-theme"
                  />
                </div>-->
                <div class="flex-fill ml-2">
                <h3>Conditional Props Grouped by Attr Name</h3>
                <json-viewer :value="conditionalAttribs" :expand-depth="3"
                             sort theme="xsd-json-theme"
                />
              </div>
              </div>
            </div>

          </div>
          <div v-if="mode==='interactiveSchema'">
            <div class="w-25">
              <b-input v-model="typeQuery" placeholder="Filter"/>
            </div>
            <div class="d-flex">
              <div v-for="(chunk,i) in chunkArray(displayChains,perPage/3)" class="d-flex ml-2" :key="`chunk${i}`">
                <div class="text-right" style="width:275px">
                  <valign-mid class="input-row" v-for="(c,i) in chunk" :key="c+i">
                    <label class="ellipsize text-right" v-b-tooltip.hover.left="c.split('.').join('\n\r -> ')">
                      {{ lbl(c) }}
                    </label>
                  </valign-mid>
                </div>
                <div style="width:275px" class="ml-2">
                  <div v-for="c in chunk" :key="c">
                    <valign-mid class="input-row">
                      <b-dropdown v-if="typeDef(c) && typeDef(c).vals && typeDef(c).vals.length > 1" :text="txt(c)" class="w-100" no-caret lazy menu-class="drop-menu">
                        <template #button-content>
                          <drop100-text :t="txt(c)"/>
                        </template>
                        <b-dropdown-item v-for="(v,i) in typeDef(c).vals" :key="`${c}${v}${i}`"
                                         v-b-tooltip="v.toString().length >88?v:undefined">
                          {{ v.toString().substr(0, 88) }}{{ v.toString().length > 88 ? '...' : '' }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-form-datepicker v-else-if="isDate(c)" :value="isDate(c)"/>
                      <b-input-group v-else>
                        <b-input-group-prepend v-if="c.endsWith('Amount') || c.endsWith('Limit')">
                          <b-button>$</b-button>
                        </b-input-group-prepend>
                        <b-input :value="txt(c)"/>
                      </b-input-group>
                    </valign-mid>

                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex page-controls pt-3">

              <div class="ml-2">
                <b-dropdown :text="`Show ${perPage}`" class="mr-2">
                  <b-dropdown-item v-for="n in [30,45,60,75,150,300,600]" :key="n" @click="perPage=n">{{ n }}
                  </b-dropdown-item>
                </b-dropdown>
                Showing {{ startItem }} - {{ (startItem - 1) + perPage }} of {{ vals.length }}
              </div>
              <div class="flex-fill"></div>
              <div class="text-right mr-2">
                <b-pagination
                    variant="info"
                    v-model="currentPage"
                    :total-rows="vals.length"
                    :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>


        </div>

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import allFields from '../lib/fields/fields';
import AlignMiddle from '@/components/util/align-middle';
import Logo from '@/components/svgs/logo';
import Drop100Text from '@/components/util/drop100-text';
import ValignMid from '@/components/util/valign-mid';
import {getType, isType} from '@/lib/mytype';

import {typeJson, findType} from '@/slayer/xsd';
import {quotemix} from '@/lib/quotemix';
import {sortByKey} from '@/lib/util';
//import {allKeys} from '@/lib/field-typedefs';
//console.log(JSON.stringify(allKeys))
let sJson = require('../json/semantic.json');

let semJsonByAttr = sJson.byAttr;
let semJsonByType = sJson.types;
let semJson = sJson;
delete semJson.byAttr;
delete semJson.types;


export default {
  data() {

    return {
      modes: ['semanticJson', 'schemaViewer', 'interactiveSchema'],
      mode: 'semanticJson',
      currentPage: 1,
      perPage: 45,
      interactive: false,
      render: false,
      depth: 1,
      displayType: 'types',
      json: typeJson,
      allTypes: null,
      typeQuery: '',
      loading: false,
      semJson, semJsonByAttr, semJsonByType
    };
  },
  mixins: [quotemix],
  computed: {

    typeVals(){
      return this.json[this.displayType];
    },
    semanticAttribs(){
      let excluded = [
        'chain', 'classList', 'group', 'i',
        'key', 'lbl', 'optional',
        'page', 'tags', 'title', 'type', 'groupList',
        'tagList', 'isField', '_val', 'clientHeight', 'guid',
        '_originalVal', 'conditionalProps', 'conditionals', 'selectionProxy',
        '_forceDirty', 'touched', 'blurred', 'parent'
      ];
      let attrs = {};
      this.flatFieldList.forEach(f => {
        let keys = Object.keys(f).filter(k => !excluded.includes(k));
        keys.forEach(k => {
          /*if (k.startsWith('_')){
            k = k.substr(1);
          }*/
          if (attrs[k] === undefined){
            attrs[k] = {
              count: 0,
              uniqPrimitiveVals: {},
              usedBy: {},
              valTypes: {}
            };
          }
          let attr = attrs[k];

          let v = f[k];
          attr.count++;
          let incr = (a1, a2) => {
            attr[a1][a2] = attr[a1][a2] ? attr[a1][a2] + 1 : 1;
          };
          if (isType.primitive(f[k])) {
            incr('uniqPrimitiveVals', f[k]);
          }
          incr('usedBy', f.type);
          incr('valTypes', getType(v));
        });
      });

      let sorted = sortByKey(attrs, 'count', false);
      let forceEntries = Object.entries(sorted).map(([key, obj], i) => [`${i < 10 ? 0 : ''}${i}-${key}`, obj]);
      return Object.fromEntries(forceEntries);
    },
    rawSemJson(){
      return Object.entries(allFields).flatMap(([page, fields]) => {
        return fields.map(f => {
          if (!f.chain){
            f.chain = `${page}.${f.key}`;
          }
          return f;
        });

      });
    },
    conditionalAttribs(){
      let conditionals = {

      };
      //let attrs = [];
      this.rawSemJson.forEach(f => {
        if (!f.chain){
          console.log(f);
        }
        Object.entries(f).forEach(([key, val]) => {
          if(isType.object(val) && val.$conditional){

            if (!conditionals[key]){
              conditionals[key] = {
                count: 0,
                usedBy: {}
              };
            }
            conditionals[key].count++;
            conditionals[key].usedBy[f.chain] = val;
        //    attrs.push({prop: key, ...val})
          }
        });
      });
      let sorted = sortByKey(conditionals, 'count', false);
      let forceEntries = Object.entries(sorted).map(([key, obj], i) => [`${i < 10 ? 0 : ''}${i}-${key}`, obj]);
      return Object.fromEntries(forceEntries);
      //return conditionals;
    },
    startItem() {
      return ((this.currentPage - 1) * this.perPage) + 1;
    },
    chainObj() {
      let chains = this.json.CDXF.values || {};
      const cVal = chain => {
        let spl = chain.split('.').reverse();
        let o = this.json.CDXF;

        while (spl.length > 2){
          let k = spl.pop();
          try {
            if (isType.snum(k)) {
              k = Number(k);

            }
            if (!o[k]) {

              if (o[0][k]){
                o = o[0];
              }
              /*else if (o[k][k]){
                o = o[k];
              }*/
              else {
                return o;
              }
            }

            o = o[k];

          }catch(ex){
            console.warn({chain, o, k});
            debugger;
            return o;
          }
        }
        return o[spl.pop()] || o;
        //obj._cdxfFullPath_ = chain;
      };
      if (this.typeQuery){
        let filtered = {};
        let q = this.typeQuery.toLowerCase();
        Object.keys(chains).forEach(c => {
          if (c.split('.').pop().toLowerCase().includes(q)){

            filtered[c] = cVal(c);
          }
        });
        //debugger
        return filtered;
      }
      return chains;
    },
    typeDef() {
      return c => this.val(c).typeDef;
    },
    lbl() {
      return c => c.split('.').pop();

    },
    isDate() {
      return c => {
        let txt = this.txt(c);
        if (isType.snum(txt) && txt.length === 14) {
          let y = txt.substr(0, 4);
          let m = txt.substr(4, 2);
          let d = txt.substr(6, 2);
          return `${y}-${m}-${d}`;
        }
        return null;
      };
    },
    txt() {
      return c => {
        let v = this.val(c);
        return typeof v === 'string' ? v : v.value || v.code || v.id;
      };
    },
    val() {
      return key => this.chainObj[key];
    },
    displayChains() {
      return Object.keys(this.chainObj).reverse().slice(this.startItem, this.startItem + this.perPage);
    },
    vals() {
      return Object.values(this.chainObj);
    }
  },
  name: 'xsd',
  components: {ValignMid, Drop100Text, Logo, AlignMiddle},
  methods: {
    checkLoading(e, el){
      let dt = this.displayType;
      console.log({typeVals: this.typeVals});
      if (dt === 'types' || dt === 'CDXF') {
        //this.socket.emit('xsdTypes');
      }
    },

    foundTypes(ft){

      if (ft.length) {
        let found = {XSD: {}, CDXF: this.chainObj};
        ft.forEach(t => found.XSD[t.name] = t);
        this.json.foundTypes = found;
        this.json = JSON.parse(JSON.stringify(this.json));
        this.displayType = 'foundTypes';
      }else {
        //this.displayType = 'types';
        this.json.foundTypes = {};
      }
      this.depth = 2;
      this.render = true;
    },
    setupInteractive() {
      //this.json.CDXF=xml2Obj(xmlString,this.json.types);
      this.render = true;
    }
  },
  mounted() {
    this.editSoap = JSON.stringify(this.soapJs);
  },

  watch: {

    typeQuery(q){
      if (q.length > 1){
        this.foundTypes(findType(q));
      }else {
        this.json.foundTypes = {};
      }
    },
    mode(m) {
      if (m.toLowerCase().includes('schema')) {
        this.setupInteractive();
      }
    },

    depth() {
      this.render = false;
      this.delayFn(() => this.render = true);
    },
    displayType(t) {
      this.depth = t === 'CDXF' ? 3 : 1;
    }

  }
};


</script>

<style lang="scss">
@import "../assets/scss/get-quote";

div.container-fluid div.quote-panel {
  .drop-menu {
    max-height: 800px;
    overflow: auto;

  }

  max-width: 9999px;

  .mh400 {
    border: solid 1px $gray;
    margin-right:8px;
    margin-left:-4px;
    height: auto;
    max-height: 400px;
    overflow: auto;
  }

  div.xsd-json-theme {
    background: #000;
    white-space: nowrap;
    color: #eee;
    font-size: 14px;

    * {
      font-family: Consolas, Menlo, Courier, monospace !important;
      color: #eee;
      font-size: 14px;
    }

    font-family: Consolas, Menlo, Courier, monospace !important;

    .jv-ellipsis {
      color: #ccc;
      background-color: #333;
      display: inline-block;
      line-height: 0.9;
      font-size: 0.9em;
      padding: 0px 4px 2px 4px;
      border-radius: 3px;
      vertical-align: 2px;
      cursor: pointer;
      user-select: none;
    }

    .jv-button {
      color: #49b3ff
    }

    .jv-key {
      color: $light;
    }

    .jv-item {
      &.jv-array {
        color: $gray;
      }

      &.jv-boolean {
        color: fuchsia;
      }

      &.jv-function {
        color: $gray;
      }

      &.jv-number {
        color: $info;
      }

      &.jv-number-float {
        color: lime;
      }

      &.jv-number-integer {
        color: lime;
      }

      &.jv-object {
        color: $gray;
      }

      &.jv-undefined {
        color: #e08331
      }

      &.jv-string {
        color: #72d9f3;
        word-break: break-word;
        white-space: normal;
      }
    }

    .jv-code {
      .jv-toggle {
        &:before {
          padding: 0px 2px;
          border-radius: 2px;
        }

        &:hover {
          &:before {
            background: #eee;
          }
        }
      }
    }
  }
}
</style>
