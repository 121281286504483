import {mmddyyyy} from '@/lib/util';

const quoteFields = [
  { key: 'quote.policyId', val: 'PolicyId'},
  { key: 'quote.policyNumber', val: 'PolicyIdentifierPolicyNumber'},
  { key: 'quote.quoteNumber', val: 'QuoteNumber'},
  { key: 'quote.quoteId', val: 'contextObjectId' },
  { key: 'quote.customerId', selector: 'CDXFPolicyCustomer::id' },
  { key: 'quote.jurisdictionId', selector: 'CDXFCustomerJurisdiction::id', xform: '_' },
  { key: 'quote.customerEligibilityId', selector: 'CustomerEligibility::id' },

  { key: 'quote.totalPremium', val: 'PolicyTranPremiumCurrentTotal'},
  { key: 'quote.status', val: 'contextObjectStatus value'},
  { key: 'quote.underwritingStatus', val: 'UnderwritingStatus'},
  { key: 'quote.quoteName', val: 'QuoteName' },
  { key: 'quote.priorPolicyId', selector: 'PriorPolicyPackage::id' },
  { key: 'quote.underwritingId', selector: 'PolicyUnderwriting::id'},
  { key: 'quote.policyNeedsUnderwritingInformation', type: 'yn', val: 'PolicyNeedsUnderwritingInformation'},
  { key: 'quote.answeredEligibility', val: 'CustomerEligibility DoFollowingApply id', xform: '=== 2'},
  { key: 'quote.jurisdictionGLId', selector: 'PolicyGLJurisdictionLiabilityCoverage::id' },
  { key: 'quote.policyGLId', selector: 'PolicyGL::id' },
  { key: 'quote.policyCommercialGLId', selector: 'PolicyGL::parentId' },
  { key: 'quote.policyCFId', selector: 'PolicyCF::id' },
  { key: 'quote.policyCommercialCFId', selector: 'PolicyCF::parentId' },
  { key: 'quote.jurisdictionCFId', selector: 'PolicyCFJurisdiction::id'},
  { key: 'quote.programCode', val: 'ProgramType code' },
  { key: 'quote.underwritingReason', selector: 'PolicyUnderwritingTriggerDetails', map: [['UnderwritingTriggerAgentDescription', 'description']] },
  { key: 'quote.wildfire', val: 'wildFireScore value'},

  { key: 'quote.isGl', val: 'PolicyOptions id', xform: '=== 2'},
  { key: 'quote.modified', val: 'LastUpdateDate', type: 'date' },

  {
    key: 'quote.totals', type: 'data',
    selector: 'payload',
    map: [
      ['PolicyTranPremiumCurrentTotal', 'totalPremium'],
      ['PersonalPropertyLimit', 'bppPremium'],
      ['TotalTerrorismPremium', 'terrorismPremium'],
      ['BuildingAmountofInsurance', 'buildingLimit'],
      ['BuildingDeductible id', 'buildingDeductible'],
      ['BusinessIncomewithExtraExpensePremierLimit id', 'businessIncome'], //TODO: Should this be extra coverage or base?
      ['EmployeeTheftLimit id', 'employeeTheftLimit'],
      ['TreesShrubsandPlantsLimit id', 'treesShrubsLimit'],
      ['TreesShrubsandPlantsAggregateLimit id', 'treesShrubsAggregateLimit'],
      ['GeneralLiabilityEachOccurrenceLimitList id', 'glEachOccLimit'],
      ['GeneralLiabilityGeneralAggregateLimitList id', 'glAggLimit'],
      ['OrdinanceorLawCoverageBPremierLimit id', 'ordinanceBLimit'],
      ['OrdinanceorLawCoverageCPremierLimit id', 'ordinanceCLimit'],
      ['GeneralLiabilityDamagePremisesRentedtoYouCoverageLimit id', 'rentalLimit'],
      ['GeneralLiabilityDamagePremisesRentedtoYouCoverageLimitPremierEnhancement id', 'premierRentalLimit'],
      ['AdditionalInsuredBoardMembersandOfficersLimit', 'boardMembersIncluded', {type: 'yn'}],
      ['AdditionalInsuredByWrittenContractorAgreementLimit', 'byWrittenContractIncluded', {type: 'yn'}],
      ['AdditionalInsuredManagersorLessorsofPremisesLimit', 'managersOrLessorsIncluded', {type: 'yn'}],
      ['AdditionalInsuredOffSiteBoardMeetingVenueLimit', 'offSiteBoardMeetingIncluded', {type: 'yn'}]
    ]
  }, {
    key: 'quote.remainingFields', type: 'data',
    selector: 'validationMessage',
    map: [
      ['message', 'field', {pluck: [' : ', ' ...']}]
    ]
  },
  {
    key: 'customer.phoneId',
    selector: 'CDXFCustomerPhoneNumber::id'
  }, {
    key: 'customer.phone',
    val: 'CDXFIncludedBusinessPrimaryPhoneNumber'
  }, {
    key: 'customer.accountName',
    val: 'IncludedBusinessBusinessName'
  }, {
    key: 'customer.email',
    val: 'CDXFEmailAddress'
  }, {
    key: 'policy.terrorism',
    selector: `PolicyCertifiedActsofTerrorismCoverageIndicator`,
    type: 'yn'
  }, {
    key: 'policy.terrorismTerritory',
    type: 'select',
    selector: 'TerrorismTerritory'
  }, {
    key: 'policy.hasLapse',
    type: 'yn',
    selector: 'Hastherebeenalapseincoverage'
  }, {
    key: 'policy.hasExisting',
    type: 'data',
    selector: 'PriorPolicyPackage::externalObjectId',
    xform: '=== yesPrior'
  }, {
    key: 'policy.priorExpiry',
    type: 'date',
    val: 'PriorPolicyExpirationDate'
  }, {
    key: 'policy.expiry',
    type: 'date',
    val: 'PolicyExpirationDate'
  }, {
    key: 'policy.effective',
    type: 'date',
    val: 'PolicyEffectiveDate'
  }, {
    key: 'policy.expiringPremium',
    val: 'PriorPolicyExpiringPremium'
  }, {
    key: 'policy.hasLosses',
    type: 'yn',
    val: `Havetherebeenanylossesinthelastfouryears`
  }, {
    key: 'policy.totalLosses',
    val: `TotalIncurred`
  },   {
    type: 'data',
    key: 'quote.quoteId',
    val: 'contextObjectId'
  }, {
    type: 'select',
    key: 'scope.jurisdiction',
    selector: 'CDXFStateCode'
  }, {
    type: 'select',
    key: 'scope.product',
    selector: 'ProgramType'
  }, {
    type: 'select',
    key: 'scope.program',
    selector: 'PolicyOptions'
  }, {
    key: 'locations.addresses',
    type: 'list',
    selector: `CDXFCustomerAddress`,
    map: [
      ['@os_st_id', 'addressId'],
      ['CDXFAddressLine1', 'street1'],
      ['CDXFAddrssCity', 'city'],
      ['CDXFAddressState id', 'state'],
      ['CDXFAddressState code', 'stateCode'],
      ['CDXFAddressCounty', 'county'],
      ['CDXFAddressZipCode', 'zipcode'],
      ['CDXFPreferredMailingAddress id', 'isMailing', '=== 111'],
      ['CDXFPreferredBillingAddress id', 'isBilling', '=== 111'],
      ['CDXFPreferredPrimaryAddress id', 'isPrimary', '=== 111']
    ]
  }, {
    key: 'locations.locationMeta',
    type: 'list',
    selector: `CDXFCustomerLocation`,
    map: [
      ['@os_st_id', 'locationId'],
      ['CDXFRefLocatedAtRef', 'addressId', '_'],
      ['CDXFLocationSequenceNumber', 'num'],
      ['LocationHazard @os_st_id', 'locationHazardId'],
      ['CustomerLocationHurricaneEligibility @os_st_id', 'locationHurricaneEligibilityId'],
      ['HurricaneTierAssignment id', 'hurricaneTier']
    ]
  },
  {
    key: 'locations.propertyCoverages',
    type: 'list',
    selector: 'PolicyCFLocation',
    map: [
      ['@os_st_id', 'cfLocationId'],
      ['CoveredLocationRef', 'locationId', '_'],
      ['MiscellaneousOutdoorPropertyTotal', 'outdoorBlanketTotal'],
      ['MiscellaneousOutdoorPropertyDeductible', 'perilsDeduct', {type: 'select'}],
      ['MiscellaneousOutdoorPropertyCausesOfLoss', 'causeOfLoss', {type: 'select'}],
      ['MiscellaneousOutdoorPropertyValuationType', 'valuationType', {type: 'select'}],
      ['MiscellaneousOutdoorPropertyInflationGuardPercentage', 'inflationGuard', {type: 'select'}],
      ['MiscellaneousOutdoorPropertyAgreedValueIndicator', 'agreedValue', {type: 'select'}]
    ]
  },
  {
    key: 'locations.buildings',
    type: 'list',
    selector: `CDXFCustomerLocation CDXFCustomerBuilding`,
    map: [
      ['@os_st_id', 'buildingId'],
      ['@os_st_id::parent', 'locationId'],
      ['@externalObjectId', 'externalId'],
      ['CDXFRefLocatedAtRef::parent', 'addressId', '_'],
      ['CDXFBuildingNumber', 'num'],
      ['CDXFBuildingName', 'name'],
      ['BuildingClass', 'bClass'],
      ['CDXFConstructionType', 'constructionType', {key: 'id', lbl: 'value', type: 'select'}],
      ['CDXFTotalSquareFootage', 'totalBldgSqFt'],
      ['CDXFNumberOfStories', 'floorCt'],
      ['CDXFProtectionClassList value', 'protectionClass'],
      ['CDXFRoofType', 'roofType', {key: 'id', lbl: 'value', type: 'select'}],
      ['CDXFAutomaticSprinklers', 'hasSprink', {key: 'id', lbl: 'value', type: 'yn'}],
      ['CDXFYearBuilt', 'yearBuilt'],
      //['CDXFTotalSquareFootage', 'totalBldgArea'], ugh, is this a mistake?
      ['CDXFYearWiringUpdated', 'elecUpdated'],
      ['CDXFAluminumWiringIndicator', 'alumWiring', {type: 'yn'}],
      ['CDXFYearPlumbingUpdated', 'plumbingUpdated'],
      ['CDXFPolybutylenePipingIndicator', 'polyPiping', {type: 'yn'}],
      ['CDXFYearUpdatedRoofing', 'roofUpdated']
    ]
  },
  {
    key: 'buildings.cov', //simplified ingestion
    type: 'list',
    selector: 'PolicyCFLocation PolicyCFBuildingCoverage',
    map: [
      ['@os_st_id::parent', 'cfLocationId'],
      ['@os_st_id', 'cfBuildingCoverageId'],
      ['CoveredBuildingRef', 'buildingId', '_'],
      ['ContentsBlanketRef', 'bppBlanketId', '_'],
      ['BuildingBlanketRef', 'buildingBlanketId', '_'],
      ['PolicyCFBuildingSubjectofInsurance @os_st_id', 'buildingSubjectId'],
      ['PolicyCFBuildingPersonalPropertySubjectofInsurance @os_st_id', 'propertySubjectId'],
      ['BuildingClass', 'bClass', {type: 'select'}],
      ['BuildingDeductible', 'perilsDeduct', {type: 'select'}],
      ['BusinessPersonalPropertyPremierLimit', 'bppLimit', {type: 'select'}],
      ['BuildingInflationGuardPercentage', 'inflationGuard', {type: 'select'}],
      ['BuildingAmountofInsurance', 'buildingLimit'],
      ['BuildingCoinsurancePercentage', 'coinsurPct', {type: 'select'}],
      ['BuildingAgreedValueIndicator', 'agreedVal', {type: 'yn'}],
      ['BuildingValuationType', 'valuation', {type: 'select'}],
      ['BusinessIncomeExtraExpenseIndicator', 'bizCvgForm', {type: 'yn'}], //dataVersion:1
      ['BusinessIncomewithExtraExpenseLimit', 'bizIncPremLimit'], //dataVersion:1
      ['BusinessIncomeExtraExpenseMonthlyLimitofIndemnity', 'moLmtIndem', {type: 'select'}], //dataVersion:1
      ['BusinessIncomewithExtraExpenseAgreedValue', 'bizIncAgreedValue', {type: 'yn'}], //dataVersion:1
      ['BusinessIncomeExtraExpenseExtendedPeriodNumberofDays', 'extPeriodDays', {type: 'select'}], //dataVersion:1
      ['BusinessIncomeExtraExpenseMaximumNumberofDays', 'bizIncMaxDays', {type: 'yn'}], //dataVersion:1
      ['OrdinanceorLawCoverageAIndicator', 'ordinanceCoverage', {type: 'yn'}],
      ['OrdinanceorLawCoverageBLimit', 'demolitionLimit', {type: 'select'}],
      ['OrdinanceorLawCoverageCLimit', 'incrConstCost', {type: 'select'}],
      ['SinkholeLossCoverageSelectedIndicator', 'sinkholeCoverage', {type: 'yn'}],
      ['Hastheinsuredpreviouslyincurredasinkholeloss', 'prevSinkholeLoss', {type: 'yn'}],
      // ['UtilityServicesDDIndicator', 'directDmg', {type: 'yn'}],
      // ['UtilityServicesDirectDamageLimit', 'directDmgLimit'],
      // ['UtilityServicesTimeElementSelectedIndicator', 'utilTimeEl', {type: 'yn'}],
      // ['UtilityServicesTimeElementUtilityServicesLimit', 'utilTimeElLimit'],
      ['AdditionalCoveredPropertyCoverageIndicator', 'addlCovProp', {type: 'yn'}],
      ['DischargeWaterBackupAndSumpOverflowCoverageIndicator', 'sewerDischarge', {type: 'yn'}],
      ['DischargeWaterBackupPropertyDamageLimit', 'dischargeLmtProp'],
      ['DischargeWaterBackupBusinessInterruption', 'dischargeLmtBizInt'],
      ['DischargeWaterBackupAnnualAggregateLimitApplies', 'annAggLim', {type: 'yn'}],
      ['MineSubsidenceResidentialBuildingCoverageSelectedIndicator', 'mineSubsidence', {type: 'yn'}],
      ['MineSubsidenceBuildingLimit', 'mineSubsidenceBldLimit'],
      ['MineSubsidenceDeductible', 'mineSubsidenceDeductible'],
      ['MultipleDeductibleOptionDeductible', 'multipleDeductible', {type: 'select'}],
      ['MultipleDeductibleCoveredCausesofLoss', 'multipleDeductibleCause', {type: 'select'}],
      ['MultipleDeductibleCoveredDescribeOther', 'multipleDeductibleDescribeOther'],
      ['MineSubsidenceAdditionalLivingExpenseCoverage', 'mineSubsidenceLivingExp', {type: 'yn'}],
      ['WindstormMitigationSelectedIndicator', 'wsLossMitigation', {type: 'yn'}],
      ['WindstormHailExclusionIndicator', 'wsHailExclusion', {type: 'yn'}]
    ]
  },
  {
    key: 'quote.blanketCoverages',
    selector: 'PolicyCFBlanket',
    type: 'list', hydrateList: true,
    map: [
      ['@os_st_id', 'blanketId'],
      ['PropertyBlanketType', 'type', {key: 'id', lbl: 'value', type: 'select'}],
      ['PropertyBlanketDescription', 'description'],
      ['PropertyBlanketCoinsurance', 'coinsurance', {key: 'id', lbl: 'value', type: 'select'}],
      ['PropertyBlanketLimit', 'limit']
    ]
  },
  { key: 'baseline.occLimit', type: 'select', selector: 'GeneralLiabilityEachOccurrenceLimitList' },
  { key: 'baseline.aggLimit', type: 'select', selector: 'GeneralLiabilityGeneralAggregateLimitList' },
  { key: 'baseline.medLimit', type: 'select', selector: 'GeneralLiabilityMedicalExpenseLimitList' },
  { key: 'baseline.autoLiab', type: 'yn', selector: 'AutoHiredNonownedandGaragekeepersLegalLiabilityIndicator' },

  { key: 'baseline.premierPropertyEnhancement', type: 'bool', selector: 'PremierPropertyEnhancementIndicator' },
  { key: 'baseline.empLiability', type: 'yn', selector: 'EmployeeBenefitsCoverageIndicator' },
  { key: 'baseline.eachEmpAmt', type: 'select', selector: 'EmployeeBenefitsAmountofInsuranceEachEmployee' },
  { key: 'baseline.empCt', val: 'EmployeeBenefitsNumberofEmployees' },
  { key: 'baseline.empAggLimit', type: 'select', selector: 'EmployeeBenefitsAggregateLimit' },
  { key: 'baseline.empDeductiblePerClaim', type: 'select', selector: 'EmployeeBenefitsLiabilityDeductiblePerClaim' },
  { key: 'baseline.retroDateApplies', type: 'yn', selector: 'EmployeeBenefitsRetroactiveDateApplies' },
  { key: 'baseline.retroDate', type: 'date', val: 'EmployeeBenefitsRetroactiveDate' },
  { key: 'baseline.premOper', type: 'bool', selector: 'AmendmentLiquorLiabilityExclusionExceptionforPremisesOperationsIndicator' },
  { key: 'baseline.hasStopGap', type: 'bool', selector: 'StopGapEmployersLiabilityCoverageIndicator' },
  { key: 'baseline.stopGapBodilyInjuryByAccidentEachAccident', type: 'select', selector: 'StopGapBodilyInjuryByEachAccidentLimit' },
  { key: 'baseline.stopGapBodilyInjuryByDiseaseAggregateLimit', type: 'select', selector: 'StopGapBodilyInjuryByDiseaseAggregateLimit' },
  { key: 'baseline.stopGapBodilyInjuryByDiseaseEachEmployee', type: 'select', selector: 'StopGapBodilyInjuryByDiseaseEachEmployeeLimit' },
  {//inferred yes from next field?
    key: 'underwriting.secGuards', type: 'yn',
    selector: 'DoyouhaveSecurityGuards'
  },
  { key: 'propertyCoverages.iceDamming', selector: 'PropertyIceDammingDeductibleperunit', type: 'select'},
  { key: 'propertyCoverages.waterDamageDeductible', type: 'select', selector: 'PropertyWaterDamagePerUnitDeductible' },
  { key: 'propertyCoverages.aopPerUnit', selector: 'PerUnitDeductible', type: 'yn'},
  { key: 'propertyCoverages.bizIncPremLimit', val: 'BusinessIncomewithExtraExpenseLimit' },
  { key: 'propertyCoverages.moLmtIndem', val: 'BusinessIncomeExtraExpenseMonthlyLimitofIndemnity id'},
  { key: 'propertyCoverages.bizIncAgreedValue', selector: 'BusinessIncomewithExtraExpenseAgreedValue', type: 'yn'},
  { key: 'propertyCoverages.extPeriodDays', val: 'BusinessIncomeExtraExpenseExtendedPeriodNumberofDays id'},
  { key: 'propertyCoverages.bizIncMaxDays', selector: 'BusinessIncomeExtraExpenseMaximumNumberofDays', type: 'yn'},
  { key: 'propertyCoverages.assocByLawsCov', type: 'select', selector: 'AssociationByLawsCoverage' },
  {
    key: 'underwriting.secGuardsIndependent', type: 'yn',
    selector: 'Aresecurityguardsindependentcontractors'
  },
  {
    key: 'underwriting.contractorsCarryGL', type: 'yn',
    selector: 'AreindependentcontractorsrequiredtocarryGeneralLiabilitycoverage'
  },
  {
    key: 'underwriting.ho6', type: 'yn',
    selector: 'CondominiumOwnerareRequiredtoMaintainHO6Policy'
  },
  {
    key: 'underwriting.unoccupiedUnitHeatRequired', type: 'yn',
    selector: 'UnoccupiedUnitsRequiredtoMaintainHeat'
  }, {
    key: 'underwriting.buildingsPrior78', type: 'yn',
    selector: 'WereAnyBuildingsConstructedPriorNineteenSeventyEight'
  },
  {
    key: 'underwriting.lifeguards', type: 'yn',
    selector: 'BeachesLifeguardsPresent'
  },
  {
    key: 'underwriting.marked', type: 'yn',
    selector: 'BeachesMarkedSwimmingArea'
  },
  {
    key: 'underwriting.rules', type: 'yn',
    selector: 'BeachesPostedRules'
  },
  { key: 'underwriting.parkCt', val: 'ParksorPlaygroundsNumberofParksincludingdogparks'},
  {
    key: 'underwriting.surfaceType', type: 'select',
    selector: 'PlaygroundSurface'
  },
  {
    key: 'underwriting.playgroundAgeOfEquipment', val: 'PlaygroundAgeofEquipment'
  },
  {
    key: 'underwriting.surfaceTypeDesc',
    val: 'PlaygroundSurfaceDescribeOther'
  },
  {
    key: 'underwriting.inspections', type: 'yn',
    selector: 'PlaygroundRegularInspections'
  },
  {
    key: 'underwriting.poolType', type: 'select',
    selector: 'PolicyUnderwriting PoolType'
  },
  {
    key: 'underwriting.poolDescAddl',
    val: 'PoolsDescribeAdditionalEquipment'
  },

  // TODO: This _should_ be here, but is currently in exposures per OneShield
  // {
  //   key: 'underwriting.poolsCompliant', type: 'yn',
  //   val: 'SwimmingPoolsCompliance'
  // },
  {
    key: 'underwriting.hasBoatSlips', type: 'yn',
    selector: 'BoatDocksandSlipsIndicator'
  },
  {
    key: 'underwriting.boatSlipsNumber',
    val: 'BoatDocksandSlipsNumber'
  },
  {
    key: 'underwriting.boatSlipsCovered', type: 'yn',
    selector: 'BoatDocksandSlipsCovered'
  },
  {
    key: 'underwriting.boatSlipsInspected', type: 'yn',
    selector: 'BoatDocksandSlipsInspectedAnnually'
  },
  {
    key: 'underwriting.boatSlipsNonSlipSurface', type: 'yn',
    selector: 'BoatDocksandSlipsNonslipSurface'
  },
  {
    key: 'underwriting.boatSlipsRulesPosted', type: 'yn',
    selector: 'BoatDocksandSlipsPostedRules'
  },
  {
    key: 'underwriting.buildingDetailsUpdated', type: 'yn',
    selector: 'HavebuildingdetailsbeenupdatedpertheStatementofValues'
  },
  {
    key: 'underwriting.miscExposures', type: 'select',
    selector: 'MiscellaneousExpsoures'
  },
  {
    key: 'locations.liabilityCoverages',
    selector: 'PolicyGLLocationLiabilityCoverage',
    type: 'list',
    map: [
      ['@os_st_id', 'liabilityCoverageId'],
      ['CoveredLocationRef', 'locationId', '_'],
      ['PremisesOperationsTerritory', 'premOpsTerritory', {type: 'select'}],
      ['RateTier id', 'rateTier']
    ]
  },
  {
    key: 'locations.liabilities',
    selector: 'PolicyGLLocationLiabilityCoverage PolicyGLLocationAdditionalClassCode',
    type: 'list',
    map: [
      ['@os_st_id::parent', 'liabilityCoverageId'],
      ['@os_st_id', 'liabilityAdditionalClassId'],
      ['CoveredLocationRef::parent', 'locationId', '_'],
      ['liabiityClassCode', 'liabilityClassCode'],
      ['GeneralLiabilityClass', 'liabilityClass', {type: 'select'}],
      ['BeachesNumberofBeaches', 'beachCount'],
      ['ClubsSquareFootage', 'clubSqft'],
      ['LakesorReservoirsNumberofLakesorReservoirs', 'waterCt'],
      ['BoatStorageandMoorageGrossSales', 'sales'],
      ['ParkingParkingSquareFootage', 'parkSqft'],
      ['ParksorPlaygroundsNumberofParksincludingdogparks', 'parkCt'],
      ['StreetsRoadsHighwaysorBridgesMiles', 'miles'],
      ['SwimmingPoolsNumberofPools', 'poolCt'],
      ['SwimmingPoolsCompliance', 'poolsCompliant', { type: 'yn'}],
      ['VacantLandOtherthanNotForProfitNumberofAcres', 'vacantLandOtherThanNfp'],
      ['VacantLandNotForProfitOnlyNumberofAcres', 'vacantLandNfpOnly'],
      ['BuildingsorPremisesofficepremisesoccupiedbyemployeesoftheinsuredNotForProfitonlySquareFootage', 'bldgPremisesSqft'],
      ['BuildingorPremisesLessorsRiskSquareFootage', 'bldgPremisesSqftLessors'],
      ['BuildingorPremisesLessorsRiskOtherThanRetailExposure', 'bldgPremisesOtherExposure', {type: 'yn'}],
      ['BuildingorPremisesLessorsRiskDescribeRetailExposure', 'bldgPremisesRetailExposure'],
      ['CondominiumsCommercialBankorMercantileSquareFootage', 'condoCommercialBankOrMercantileSqFoot'],
      ['CondominiumsCommercialShoppingCentersSquareFootage', 'condoCommercialShoppingSqFoot'],
      ['CondominiumsCommercialWarehousesSquareFootage', 'condoCommercialWarehousesSqFoot'],
      ['CondominiumsAssociationRiskOnlyNumberofUnits', 'unitCt'],
      ['HomeownerAssociationsAssociationRiskOnlyNumberofUnits', 'unitCtHOA'],
      ['ClubsNoBuildingsOwnedorLeasedNumberofMembers', 'unitCtHOA'],
      ['WarehousesSquareFootage', 'wareHouseSqft']
    ]
  },
  {
    key: 'locations.outdoorProperties',
    selector: 'PolicyCFLocationOutdoorProperty',
    type: 'list', hydrateList: true,
    map: [
      ['@os_st_id', 'propertyId'],
      ['@os_st_id::parent', 'cfLocationId'],
      ['CoveredLocationRef::parent', 'locationId', '_'],
      ['GenericInstanceNumber', 'num'],
      ['MiscellaneousOutdoorPropertyType', 'propertyType', {type: 'select'}],
      ['MiscellaneousOutdoorPropertyAmountofInsurance', 'amtOfInsurance'], //remove?
      ['MiscellaneousOutdoorPropertyDescribe', 'description'],
      ['MiscellaneousOutdoorPropertyClassification', 'propertyClass', {type: 'select'}]
    ]
  },
  {
    type: 'list',
    key: 'exposures.multi',
    selector: 'PolicyGLLocationAdditionalClassCode',
    map: [
      ['LiabilityClassCode', 'code']
    ]
  },
  {
    key: 'info.incomplete',
    type: 'list',
    selector: `informationList information`,
    map: [
      ['id', 'key'],
      ['message', 'message']
    ]
  },
  {
    key: 'bindQuote.pmtOption',
    type: 'select',
    selector: 'CDXFPaymentPlan',
    map: { key: 'PaymentPlanID', lbl: 'PaymentPlanName'}
  }, {
    key: 'bindQuote.pmtOption',
    val: 'ReferenceChosenPaymentPlanPaymentPlanID'
  }



];
const documentFieldMap = [
  ['dmsId', 'dmsId'],
  ['externalDmsId', 'externalId'],
  ['fileName', 'fileName'],
  ['documentContentType id', 'contentType'],
  ['documentContent', 'fileData'],
  ['documentDescription', 'desc'],
  ['documentStatus', 'status'],
  ['createdDate', 'created'],
  ['contextObjectId', 'contextId'],
  ['documentGenerationType', 'genType'],
  ['contextObject', 'contextObject'],
  ['documentId', 'documentId'],
  ['contentSize', 'size'],
  ['renderingStatus', 'renderStatus'],
  ['source value', 'source']
];

export const apiMap = {
  // API SERVICES
  createSession: {
    api: `create-session`,
    inputParams: {
      OS_TOKEN: ''
    },
    verb: 'creating a user session'
  },
  evalLocations: {
    api: `location/evaluate-address-hazards`,
    method: 'POST',
    inputParams: {
      IDsAndLocations: {}
    },
    verb: 'verifying location information',
    gate: true
  },

  getScoredLocations: {
    api: `location/address-hazards`,
    method: 'POST',
    inputParams: {
      addresses: []
    },
    verb: 'obtaining address information'
  },
  // ONE SHIELD PROXY
  tearDownSession: {
    proxyPath: 'services/SessionService'
  },
  agencyList: {
    inputParams: {},
    proxyPath: 'services/ObjectAccessService',
    ns: 'objectaccess',
    fields: [{
      selector: 'Partner',
      chain: 'scope.agency',
      type: 'data',
      map: [
        ['PartnerId', 'agencyId'],
        ['PartnerAgencyCode', 'agencyCode'],
        ['PartnerName', 'agencyName']
      ]
    }],
    verb: 'obtaining the list of agencies'
  },
  agentList: {
    inputParams: { agencyId: null },
    proxyPath: 'services/ObjectAccessService',
    ns: 'objectaccess',
    fields: [{
      selector: 'User',
      key: 'scope.agent',
      type: 'data',
      map: [
        ['UserId', 'id'],
        ['UserFirstName', 'firstName'],
        ['UserLastName', 'lastName'],
        ['UserName', 'username'],
        ['ActorTypeId', 'typeId']
      ]
    }],
    verb: 'retrieving the agent list'
  },
  jurisdictionList: {
    //testParallel: 'getJurisdictions',
    inputParams: { agentUsername: null, agencyCode: null },
    proxyPath: 'services/ProductMetaDataService',
    fields: [{
      selector: 'LookupListValue',
      chain: 'scope.jurisdiction',
      lbl: '',
      type: 'select',
      placeholder: 'Choose a State',
      map: {key: 'EnumID', lbl: 'Value'}
    }],
    component: 'scope',
    verb: 'retrieving the list of states'
  },
  productList: {
    //testParallel: 'getProducts',
    inputParams: {
      jurisdiction: 5
    },
    proxyPath: 'services/ProductMetaDataService',
    fields: [{
      selector: 'LookupListValue',
      chain: 'scope.product',
      lbl: '',
      type: 'select',
      placeholder: 'Choose a Product',
      map: {key: 'EnumID', lbl: 'Value'}
    }],
    verb: 'retrieving the product list'
  },
  packageLinesList: {
    //testParallel: 'getPackages',
    inputParams: {jurisdiction: 5, product: 87946},
    proxyPath: 'services/ProductMetaDataService',
    fields: [{
      selector: 'LookupListValue',
      chain: 'scope.program',
      lbl: '',
      type: 'select',
      placeholder: 'Choose a Package',
      map: {key: 'Code', lbl: 'Value'}
    }],
    verb: 'retrieving the policy options list'
  },
  payerList: {
    proxyPath: `services/ObjectAccessService`,
    ns: 'objectaccess',
    inputParams: {quoteId: null},
    fields: [{
      chain: 'bindQuote.payerList',
      selector: 'PolicyPayer',
      type: 'select',
      map: {key: 'PayerId', lbl: 'PayerName'}
    }],
    gate: 'quoteId',
    verb: 'retrieving the payer list'
  },
  setPayer: {
    proxyPath: 'services/WorkflowService',
    fields: [{
      chain: 'bindQuote.pmtOption',
      selector: 'PaymentPlan',
      type: 'select',
      map: {key: 'PaymentPlanID', lbl: 'PaymentPlanName'}
    }],
    verb: 'setting policy payer'
  },
  eligibilityQuestionList: {
    //testParallel: 'getQuestions',
    inputParams: {jurisdiction: 5, product: 2956},
    proxyPath: 'services/CustomerService',
    soap1_2: false,
    fields: [{
      type: 'yn',
      chain: 'scope.hasExposures',
      lbl: 'Do you have any of the below exposures'
    }, {
      selector: 'CustomerQuestion',
      chain: 'eligibility.questions',
      lbl: 'Questions',
      type: 'list',
      map: [
        ['QuestionText', 'lbl'],
        ['QuestionId', 'key']
      ]
    }],
    verb: 'retrieving eligibility questions',
    gate: true
  },
  evaluateCustomerEligibility: {
    inputParams: {
      jurisdiction: 5,
      questions: []
    },
    proxyPath: 'services/CustomerService',
    fields: [{ type: 'data', key: 'submissionId', selector: 'Submission', val: '@os_st_id' }],
    verb: 'sending the eligibility response'
  },
  getObjectLock: {
    inputParams: {contextId: ''},
    proxyPath: 'services/ObjectLockService',
    fields: [{type: 'select', selector: 'lockstatus', chain: 'quote.locked'}],
    verb: 'locking the object',
    gate: 'contextId'
  },
  releaseObjectLock: {
    inputParams: {contextId: ''},
    proxyPath: 'services/ObjectLockService',
    fields: [{type: 'data', val: 'lockstatus value', chain: 'quote.locked'}],
    verb: 'unlocking the object',
    gate: 'contextId'
  },
  getCustomerSetupLookupList: {
    inputParams: {extId: ''},
    proxyPath: 'services/ProductMetaDataService',
    parentKey: 'scope',
    fields: [{
      type: 'select',
      lbl: 'Customer Type',
      key: 'customerType',
      selector: 'LookupListValue',
      map: {key: 'Code', lbl: 'Value'}
    }],
    verb: 'retrieving customer information'
  },
  setPolicyUnderwritingFlag: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyNeedsInformation: true
    },
    fields: [
      { key: 'quote.policyNeedsUnderwritingInformation', type: 'yn', val: 'PolicyNeedsUnderwritingInformation'},
      { key: 'quote.status', val: 'contextObjectStatus value'}
    ],
    verb: 'updating quote status',
    gate: 'quoteId'
  },
  getQuoteList: {
    inputParams: {statusString: 'Pending Bind', page: 1, guid: null, oper: '=', userOnly: true, modifiedAfter: null},
    proxyPath: 'services/ObjectAccessService',
    ns: 'objectaccess',
    fields: [{
      key: 'page.current',
      val: 'pageIndex'
    }, {
      key: 'page.total',
      val: 'totalCount'
    }, {
      key: 'page.key',
      val: 'key'
    }, {
      chain: 'quotelist.items',
      type: 'list',
      selector: 'Quote',
      map: [
        ['@os_st_id', 'contextId'],
        ['AccountName', 'accountName'],
        ['QuoteID', 'id'],
        ['QuoteID', 'quoteId'],
        ['QuoteNumber', 'quoteNum'],
        ['PolicyTranType value', 'quoteType'],
        ['PolicyAcquisitionType value', 'source'],
        ['Underwriter', 'underwriter'],
        ['PolicyTranPremiumCurrentTotal', 'totalPremium'],
        ['ProgramType value', 'package'],
        ['PolicyProduct value', 'product'],
        ['QuoteEffectiveDate', 'effective'],
        ['QuoteStatus value', 'status'],
        ['QuoteAddress', 'address'],
        ['Creator', 'createdBy'],
        ['PolicyUnderwritingTriggerDetails UnderwritingTriggerAgentDescription', 'reasonForReferral'],
        ['PolicyUnderwritingTriggerDetails UnderwritingTriggerType value', 'uwTrigger'],
        ['AgencyPartnercode', 'agencyId'],
        ['QuoteProducerName', 'producer'],
        ['QuoteProducerUsername', 'producerEmail'],
        ['QuoteModifiedDate', 'modified']
      ]
    }]
  },
  getPolicyList: {
    inputParams: {
      modified: null,
      page: 1,
      key: null
    },
    proxyPath: 'services/ObjectAccessService',
    ns: 'objectaccess',
    fields: [{
      key: 'page.current',
      val: 'pageIndex'
    }, {
      key: 'page.total',
      val: 'totalCount'
    }, {
      key: 'page.key',
      val: 'key'
    }, {
      chain: 'policyList.items',
      type: 'list',
      selector: 'Policy',
      map: [
        ['PolicyId', 'policyId'],
        ['PolicyId', 'id'],
        ['PolicyNumber', 'policyNum'],
        ['PolicyStatus value', 'status'],
        ['CommercialName', 'commercialName'],
        ['AccountName', 'accountName'],
        ['AccountNumber', 'accountNumber'],
        ['PolicyType value', 'policyType'],
        ['CreatedDate', 'created'],
        ['ModifiedDate', 'modified'],
        ['Premium', 'premium'],
        ['PartnerId', 'partnerId'],
        ['ProductDefinitionId value', 'product'],
        ['PolicyTermEffectiveDate', 'effective'],
        ['PolicyTermExpiryDate', 'expiry']
      ]
    }],
    verb: ['retrieving the policy list']
  },
  initiateQuote: {
    //testParallel: 'initQuote',
    inputParams: {
      program: null, product: null,
      agencyCode: null, agentUsername: null,
      effective: mmddyyyy(),
      jurisdiction: null,
      accountName: null,
      careOf: '',
      email: null,
      address: {street1: null, city: null, zipcode: null},
      addressLine2: '',
      questionIds: ['12156', '12256', '12356', '12456', '12556', '12656', '12756', '12856', '12956'],
      submissionId: '2339821109'
    },
    storeMap: {
      pkg: 'enum::scope.program',
      productId: 'enum::scope.product',
      jurisdiction: 'enum::scope.jurisdiction',
      questionIds: 'map:key::eligibility.questions',
      submissionId: 'quote.submission',
      email: 'customer.email',
      accountName: 'customer.accountName',
      address: 'customer.address'
    },
    proxyPath: 'services/PolicyWorkflowService',
    fields: quoteFields,
    verb: 'initiating the quote'
  },
  updatePolicyDetails: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      expiry: null,
      effective: null,
      hasLosses: null,
      totalLosses: null,
      priorExpiry: null,
      hasLapse: null,
      hasExisting: null,
      expiringPremium: null,
      terrorismTerritory: null,
      terrorism: null
    },
    fields: quoteFields,
    verb: 'updating policy details',
    gate: 'quoteId'
  },
  updateUnderwritingDetails: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null, policyId: null,
      underwritingId: null,
      contractorsCarryGL: null,
      secGuards: null,
      secGuardsIndependent: null,
      unoccupiedUnitHeatRequired: null,
      ho6: null,
      lifeguards: null,
      marked: null,
      rules: null,
      surfaceType: null,
      surfaceTypeDesc: null,
      playgroundAgeOfEquipment: null,
      parkCt: null,
      inspections: null,
      poolType: null,
      poolDescAddl: null,
      boatSlipsNumber: null,
      boatSlipsCovered: null,
      boatSlipsInspected: null,
      boatSlipsNonSlipSurface: null,
      hasBoatSlips: null,
      boatSlipsRulesPosted: null,
      buildingDetailsUpdated: null,
      miscExposures: null //array?
    },
    fields: quoteFields,
    verb: 'updating underwriting details',
    gate: 'quoteId'
  },
  updateHurricaneEligibility: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      customerId: null,
      jurisdictionId: null,
      locations: []
    },
    fields: quoteFields,
    verb: 'updating hurricane risk details',
    gate: 'quoteId'
  },
  updateBuildings: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCFId: null,
      policyCommercialCFId: null,
      jurisdictionCFId: null,
      buildings: []
    },
    fields: quoteFields,
    verb: 'updating building data',
    gate: 'quoteId'
  },
  updateLiabilities: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null, jurisdiction: null, policyId: null, policyCommercialGLId: null,
      policyGLId: null, jurisdictionGLId: null, locations: [],
      cfLocationStubs: null, policyCFId: null, policyCommercialCFId: null,
      jurisdictionCFId: null
    },
    fields: quoteFields,
    verb: 'updating liability detail',
    gate: 'quoteId'
  },
  updateBlanketCoverages: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCommercialCFId: null,
      policyCFId: null,
      jurisdictionCFId: null,
      blankets: [],
      blanketedBuildings: []
    },
    fields: quoteFields,
    verb: 'updating blanket coverages',
    gate: 'quoteId'
  },
  updateOutdoorProperties: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCommercialCFId: null,
      policyCFId: null,
      jurisdictionCFId: null,
      locations: []
    },
    fields: quoteFields,
    verb: 'updating outdoor property details',
    gate: 'quoteId'
  },
  updatePropertyCoverages: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCommercialGLId: null,
      bizIncPremLimit: null,
      moLmtIndem: null,
      bizIncAgreedValue: null,
      extPeriodDays: null,
      bizIncMaxDays: null,
      bizPersonalProp: null,
      assocByLawsCov: null,
      waterDamageDeductible: null
    },
    fields: quoteFields,
    verb: 'updating property coverages',
    gate: 'quoteId'
  },
  updateBaselineCoverages: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCommercialGLId: null,
      policyGLId: null,
      occLimit: null,
      medLimit: null,
      aggLimit: null,
      empLiability: null,
      eachEmpAmt: null,
      empCt: null,
      empAggLimit: null,
      retroDateApplies: null,
      retroDate: null,
      empDeductiblePerClaim: null,
      autoLiab: null,
      premierPropertyEnhancement: null,
      premOper: null,
      cmplProd: null,
      hasStopGap: null,
      stopGapBodilyInjuryByAccidentEachAccident: null,
      stopGapBodilyInjuryByDiseaseAggregateLimit: null,
      stopGapBodilyInjuryByDiseaseEachEmployee: null
    },
    fields: quoteFields,
    verb: 'updating baseline coverages',
    gate: 'quoteId'
  },
  rateQuote: {
    proxyPath: 'services/PolicyWorkflowService',
    inputParams: {
      quoteId: null
    },
    fields: [
      {
        key: 'jobId', val: 'jobId'
      }
    ],
    verb: 'rating this quote',
    gate: 'quoteId'
  },
  getJobStatus: {
    proxyPath: 'services/JobService',
    inputParams: {
      quoteId: null,
      jobId: null
    },
    fields: [{
      key: 'jobStatus', selector: 'jobStatus',
      map: [
        ['id', 'id'],
        ['name', 'label']
      ]
    }],
    verb: 'communicating with the server'
  },
  getProductUnderwritingQuestionsWithContext: {
    inputParams: {id: '277065409'},
    proxyPath: 'services/ProductMetaDataService',
    fields: [ ...quoteFields,
      { key: 'buildingFields.buildingClass', val: 'BuildingClass'},
      {
        key: 'exposureList',
        type: 'list',
        lbl: 'Building Exposures',
        selector: 'CoverageAttribute',
        map: [
          ['AttributeID', 'key'],
          ['AttributeName', 'lbl'],
          ['IsChildAttributeExist value', 'children'],
          ['AttributeCustomText', 'custom'],
          ['AttributeCode', 'code']
        ]
      }
    ],
    verb: 'retrieving quote detail',
    gate: 'id'
  },
  getQuoteDetails: {
    inputParams: {id: '2089527909'},
    ns: 'objectaccess',
    proxyPath: 'services/ObjectAccessService',
    fields: quoteFields,
    verb: 'retrieving quote details'
  },
  getPolicyDetails: {
    inputParams: {policyId: null, transDate: null},
    //ns: 'objectaccess',
    proxyPath: 'services/WorkflowService',
    fields: quoteFields,
    verb: 'retrieving policy details'
  },

  getPreBindDocuments: {
    proxyPath: 'services/WorkflowService',
    inputParams: {quoteId: null},
    fields: [
      {
        key: 'bindQuote.docTypes',
        type: 'data',
        selector: 'RequiredForBindDocument',
        map: [['ContentType id', 'key'], ['ContentType value', 'lbl']]
      }
    ],
    verb: 'retrieving the list of document types needed to bind this quote',
    gate: 'quoteId'
  },
  getDocumentList: {
    proxyPath: 'services/DocumentServices',
    inputParams: {quoteId: null},
    fields: [{
      key: 'bindQuote.documentList',
      type: 'list',
      selector: 'responseDocumentResult',
      map: documentFieldMap
    }],
    verb: 'retrieving quote documents',
    gate: 'quoteId'
  },
  getDocument: {
    proxyPath: 'services/DocumentServices',
    inputParams: {quoteId: null, documentId: null},
    fields: [{
      key: 'bindQuote.documentContent',
      type: 'file',
      selector: 'documentContent',
      map: documentFieldMap
    }],
    verb: 'downloading this document',
    gate: 'quoteId'
  },
  deleteDocument: {
    proxyPath: 'services/DocumentServices',
    inputParams: {quoteId: null, documentId: null},
    fields: [{
      key: 'bindQuote.deleteResult',
      type: 'data',
      val: 'documentContent documentStatus'
    }],
    verb: 'deleting the document',
    gate: 'quoteId'
  },
  uploadDocument: {
    proxyPath: 'services/DocumentServices',
    inputParams: {
      quoteId: null, fileName: 'statement of values', description: 'desc', contentType: 'stmVals', fileData: 'base64'},
    fields: [{
      key: 'bindQuote.documentContent',
      type: 'file',
      selector: 'documentContent',
      map: documentFieldMap
    }],
    verb: 'uploading the document',
    gate: 'quoteId'
  },
  generateProposal: {
    proxyPath: 'services/WorkflowService',
    inputParams: {quoteId: null},
    fields: [{ key: 'quote.proposalId', val: 'DocumentId'}],
    verb: 'generating the quote proposal',
    gate: 'quoteId'
  },
  cancelQuote: {
    proxyPath: 'services/WorkflowService',
    inputParams: { quoteId: null, reason: 'Unspecified' },
    fields: [],
    verb: 'canceling the quote'
  },
  reviveQuote: {
    proxyPath: 'services/WorkflowService',
    inputParams: { quoteId: null },
    fields: [{ key: 'quote.revivedStatus', val: 'contextObjectStatus value'}],
    verb: 're-activating the quote'
  },
  bindPolicy: {
    proxyPath: 'services/PolicyWorkflowService',
    inputParams: {quoteId: null, policyId: null, paymentId: null, name: null, payerId: null},
    fields: quoteFields,
    verb: 'binding the policy'
  },
  sendUnderwriterMessage: {
    proxyPath: 'services/TaskService',
    inputParams: { quoteId: null, message: 'Unspecified' },
    fields: [],
    verb: 'sending a message to the underwriter'
  },
  updateEligibilityQuestions: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      customerId: null,
      customerEligibilityId: null,
      hasExposure: true
    },
    fields: quoteFields,
    verb: 'evaluating quote eligibility'
  },
  updateRateTier: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      policyCommercialCFId: null,
      policyCFId: null,
      jurisdictionCFId: null,
      locations: []
    },
    fields: quoteFields,
    verb: 'updating rate tier',
    gate: 'quoteId'
  },
  getRateTier: {
    api: 'quote/rate-tier',
    inputParams: {
      quoteId: null,
      //userId: null,
      productType: null,
      riskTransferForContractors: null,
      propertyManager: null,
      priorLosses: null,
      expiringPremium: null,
      locations: []
    }
  },
  createPropertyManager: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      companyName: null,
      address: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        zipcode: null
      }
    },
    fields: [
      { key: 'customer.propertyManagerId', val: 'PartnerNumber' }
    ],
    verb: 'creating the property manager',
    gate: 'quoteId'
  },
  updatePropertyManager: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      id: null,
      locationId: null
    },
    fields: quoteFields,
    verb: 'associating the property manager',
    gate: 'quoteId'
  },
  getPropertyManagerList: {
    proxyPath: 'services/ProductMetaDataService',
    inputParams: {},
    fields: [{
      chain: 'customer.propertyManagerList',
      selector: 'LookupListValue',
      map: [
        ['Code', 'id'],
        ['EnumId', 'partnerId'],
        ['value', 'nameAddress']
      ]
    }],
    verb: 'retrieving the list of property managers'
  },
  updateCustomerAddress: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      mainAddress: null,
      billingAddress: null
    },
    fields: quoteFields,
    verb: 'updating the billing address',
    gate: 'quoteId'
  },
  addGLLocations: {
    proxyPath: 'services/WorkflowService',
    inputParams: {
      quoteId: null,
      policyId: null,
      customerId: null,
      jurisdictionId: null,
      locations: null,
      addresses: null
    },
    fields: quoteFields,
    verb: 'adding locations to the quote',
    gate: 'quoteId'
  },
  underwritingStatusOptions: {
    api: `quote/underwriting-status-options`,
    method: 'GET',
    queryParams: {
      status: null,
      agency: null,
      producer: null
    },
    verb: 'retrieving dropdown options'
  },
  listUnderwritingStatus: {
    api: `quote/underwriting-status`,
    method: 'GET',
    queryParams: {
      status: null,
      agency: null,
      producer: null
    },
    verb: 'obtaining quote status list'
  },
  getUnderwritingStatus: {
    api: `quote/:quoteId/underwriting-status`,
    method: 'GET',
    inputParams: {
      quoteId: null
    },
    verb: 'obtaining quote status'
  },
  insertUnderwritingStatus: {
    api: `quote/underwriting-status`,
    method: 'POST',
    inputParams: {
      quoteId: null,
      quoteNumber: null,
      uwEmail: null,
      uwStatus: null,
      uwStatusId: null,
      uwStatusDescription: null,
      producerEmail: null,
      producerName: null,
      agencyId: null
    },
    verb: 'updating quote status'
  },
  updateUnderwritingStatus: {
    api: `quote/:quoteId/underwriting-status`,
    method: 'POST',
    inputParams: {
      quoteId: null,
      quoteNumber: null,
      uwEmail: null,
      uwStatus: null,
      uwStatusId: null,
      uwStatusDescription: null,
      producerEmail: null,
      producerName: null,
      agencyId: null
    },
    verb: 'updating quote status'
  },
  deleteUnderwritingStatus: {
    api: `quote/:quoteId/underwriting-status`,
    method: 'DELETE',
    inputParams: {
      quoteId: null
    },
    verb: 'deleting quote status'
  },
  getRateTierReport: {
    api: 'quote/:quoteId/rate-tier-report',
    method: 'GET',
    verb: 'obtaining rate tier report'
  },
  listSystemAlerts: {
    api: `system/alert`,
    method: 'GET',
    verb: 'obtaining system alerts'
  },
  getSystemAlerts: {
    api: `system/alert/:id`,
    method: 'GET',
    inputParams: {
      id: null
    },
    verb: 'obtaining system alert'
  },
  addressSuggestions: {
    api: 'location/verify-address',
    method: 'post',
    inputParams: {
      street1: null,
      city: null,
      state: null,
      zipcode: null
    },
    verb: 'fetching address suggestions'
  },
  findBroker: {
    api: '/party/customer-broker/:name',
    method: 'GET',
    inputParams: {
      name: null
    },
    verb: 'looking up customers'
  },
  fsbLookup: {
    api: `/party/customer-broker/fsib-broker/:partnerId`,
    method: 'GET',
    inputParams: {
      partnerId: null
    },
    verb: 'querying the property manager service'
  },
  createCustomerBroker: {
    api: `/party/customer-broker/`,
    method: 'POST',
    inputParams: {
      partnerId: null,
      name: null,
      city: null,
      state: null
    },
    verb: 'creating the customer-broker record'
  },
  updateCustomerBroker: {
    api: `/party/customer-broker/:customerRegistryId`,
    method: 'POST',
    inputParams: {
      //customerRegistryId:null,
      quoteId: null
    },
    verb: 'updating the quote associated with the customer-broker record'
  }
};
