<template>
  <b-form-datepicker
      boundary="viewport" :autofocus="autofocus"
      :popper-opts="{ positionFixed:true }" v-model="def.dpVal"
      locale="en"  :state="fieldValid" :id="def.guid"  :chain="def.chain"
      :date-format-options="{day: '2-digit'}" @input="dateInput"
      :min="def.disabled ? val: def.min ? def.min : undefined"
      :max="def.disabled ? val: def.max ? def.max : undefined"
      :disabled="disable"
  />
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  mixins: [fieldmix],
  name: 'fieldtype_date',
  methods: {
    dateInput(){
      this.touch(true);
      this.delayFn(() => this.touch(false));
    }
  }
};
</script>

<style scoped>

</style>
