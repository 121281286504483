export const scope = [
  {
    key: 'agency', type: 'select',
    apiVals: {
      api: 'agencyList',
      params: {}
    },
    size: 'lg', width: '100%', animateEmpty: true,
    placeholder: 'Choose an Agency', emptyPlaceholder: 'Loading Agencies...',
    active: {
      $conditional: true,
      test: {admin: true}
    }
  }, {
    key: 'agent', type: 'select',
    apiVals: {
      api: 'agentList',
      params: {
        agentId: 'scope.agency'
      }
    },
    size: 'lg', width: '100%', animateEmpty: true,
    placeholder: 'Choose an Agent', emptyPlaceholder: 'Loading Agents...',
    active: {
      $conditional: true,
      $and: [
        {admin: true},
        {chain: 'scope.agency', active: true},
        {chain: 'scope.agency', ne: null}
      ]
    }
  }, {
    key: 'jurisdiction', type: 'select',
    size: 'lg', width: '100%', autoFocus: true, animateEmpty: true,
    placeholder: 'Choose a State', emptyPlaceholder: 'Loading States...',
    active: {
      $conditional: true,
      $or: [
        {admin: false},
        {
          $and: [
            {chain: 'scope.agency', ne: null},
            {chain: 'scope.agent', ne: null}
          ]
        }
      ]
    }
  }, {
    key: 'product', type: 'select',
    size: 'lg', width: '100%', autoFocus: false, animateEmpty: true,
    placeholder: 'Choose a Product', emptyPlaceholder: 'Loading Products...',
    active: {
      $conditional: true,
      test: {chain: 'scope.jurisdiction', notNull: true}
    }
  }, {
    key: 'program', type: 'select',
    size: 'lg', width: '100%', autoFocus: false, animateEmpty: true,
    placeholder: 'Choose Policy Options', emptyPlaceholder: 'Loading Policy Options...',
    active: {
      $conditional: true,
      test: {chain: 'scope.product', notNull: true}
    }
  }, {
    type: 'data', key: 'gl', val: {
      $conditional: true,
      $compute: {
        chain: 'scope.program',
        prop: 'selectedItem.lbl',
        eq: 'General Liability'
      }
    }
  }, {
    type: 'data', key: 'partnerId',
    val: {
      $conditional: true,
      $switch: [
        {
          $and: [
            {admin: true},
            {chain: 'scope.agency', notNull: true}
          ],
          result: {
            $compute: {
              chain: 'scope.agency', prop: 'selectedItem.partnerId'
            }
          }
        },
        {
          test: {chain: 'sibling::partnerId', eq: null},
          result: {
            $compute: { vm: 'rootPartnerId'}
          }
        }
      ],
      defaultVal: {
        $compute: { vm: 'rootPartnerId'}
      }
    }
  }
].map(f => {
  f.noAutoSelect = true;
  return f;
});



export const eligibility = [
  {type: 'yn', key: 'hasExposures'},
  { key: 'questions', lbl: 'Questions', type: 'list' }
];
