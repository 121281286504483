import {stateIdByCode, underwritingLiabilities} from '@/lib/fields/field-constants';

const uwLiabilities = underwritingLiabilities.map(f => {
  f.active = f.active || { $conditional: true, chain: `quote.aggLiabilityClasses`, has: f.lc};
  return f;
});
export const underwriting = [
  {
    key: 'contractorsCarryGL', lbl: 'Do you require independent contractors you hire to carry GL coverage?',
    type: 'yn', title: 'INDEPENDENT CONTRACTORS', classList: 'w-100',
    labelTip: `If you currently don’t employ independent contractors, please select Yes`, tags: 'col1,impactsRateTier' },
  {
    key: 'secGuards', lbl: 'Do you have armed security guards that are employees?',
    type: 'yn', title: 'SECURITY GUARDS', classList: 'w-100', tags: 'col1'},
  {
    key: 'unoccupiedUnitHeatRequired', lbl: 'Unoccupied Units Required to Maintain Heat?', classList: 'w-100', //HOA default to yes
    type: 'yn', title: 'CONDOMINIUMS', active: { $conditional: true, chain: 'quote.programCode', eq: 'COA'}, tags: 'col1'
  },
  {
    key: 'ho6', lbl: 'Owners are required to maintain an HO-6 Policy?', classList: 'w-100',
    type: 'yn', active: { $conditional: true, chain: 'quote.programCode', eq: 'COA'}, tags: 'col1'
  },
  {
    key: 'buildingsPrior78', title: 'Lead Liability Hazard', lbl: 'Were any buildings constructed prior to 1978 for this risk?',
    type: 'yn', classList: 'w-100', tags: 'col1',
    active: {
      $conditional: true,
      test: { chain: 'scope.jurisdiction', eq: stateIdByCode.NJ }
    }
  },
  { key: 'buildingDetailsUpdated', type: 'data'},
  ...uwLiabilities
];
